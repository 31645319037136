import React, { useEffect } from 'react';

import './shared.css';

export const ProgressBar = (props) => {
  const [color, setColor] = React.useState('');
  const [pourcentage, setPourcentage] = React.useState(0);

  useEffect(() => {
    setPourcentage(props.total === 0 ? 0 : (props.amount * 100) / props.total);
  }, [props]);

  useEffect(() => {
    switch (true) {
      case pourcentage >= 100:
        setColor('green');
        break;
      case pourcentage >= 10:
        setColor('orange');
        break;
      default:
        setColor('darkorange');
        break;
    }
  }, [pourcentage]);

  return (
    <div>
      <div className={`text-progress text-${color}`}>
        {props.amount}/{props.total}
      </div>
      <div className="progress progress-custom">
        <div
          className={`progress-bar bar-${color}`}
          role="progressbar"
          style={{ width: pourcentage }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
};
