import { useEffect, useState } from 'react';

import axiosPixLink from 'config/axiosPixLink';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ConsultantHistoricalBacklinkingsTableControl from 'components/Netlinking/Table/Consultant/ConsultantHistoricalBacklinkingsTableControl';
import ConsultantSearchSpotTableControl from 'components/Netlinking/Table/Consultant/ConsultantSearchSpotTableControl';
import ConsultantSelectedSpotTableControl from 'components/Netlinking/Table/Consultant/ConsultantSelectedSpotTableControl';
import { MyCustomHighchartsSmall } from 'components/shared/Charts/MyCustomHighChartsSmall';
import { MyCustomHighcharts } from 'components/shared/Charts/MyCustomHighcharts';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  COLOR_JAUGE,
  PIXLINK_CAMPAIGN_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import {
  calculateClientMinimumLinks,
  calculateMinimumLinks,
} from 'utils/netlinking/linksNumber';
import './order.css';

import {
  calculateConsultantRefusedPixalione,
  calculateConsultantRefusedClient,
  calculateConsultantToBeCommanded,
  calculateConsultantToBeFilteredConsultant,
  calculateConsultantValidateClient,
  calculateConsultantValidateSuperAdmin,
  calculateConsultantValidateSuperConsultant,
} from 'utils/Jauge/CalculateConsultant';
import { MyCustomReverseHighChats } from 'components/shared/Charts/MyCustomReverseHighChats';
const API = {
  getAvailableThematics: '/netlinking/all-thematics',
  getAvailableLanguages: '/language/all',
  getExistingAccounts: 'website/account/all',
  getAllWriters: '/user/all',
  getAllWebsiteOfAccount: '/website',
  getAllDevisOfAccount: '/user/getDevise',
  updateCampaign: '/campaign/update',
  createSmartOrder: '/commands/addSmartCommand',
  getAllConsultants: '/user/consultants',
  getAllSuperConsultants: 'user/superconsultants',
  getCampaignDetail: '/campaign/campaignDetail/',
};

export default function ConsultantCampaignSourcing({
  campaignType,
  campaignStatus,
}) {
  const dispatch = useDispatch();
  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [wantedNetlinkings, setWantedNetlinkings] = useState([]);
  const [historicBackLinkings, setHistoricBackLinkings] = useState([]);
  const [netlinkings, setNetlinkings] = useState([]);
  const [netlinkingsTmp, setNetlinkingsTmp] = useState([]);
  const [nbrOfLinksDemanded, setNbrOfLinksDemanded] = useState(0);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [campaignFilter, setCampaignFilter] = useState(null);
  const [isSearchForNetlinkinngOpen, setIsSearchForNetlinkinngOpen] =
    useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [confirmationSaveForce, setConfirmationSaveForce] = useState(false);
  const [confirmationMsgSaveForce, setConfirmationMsgSaveForce] = useState('');
  const [confirmationNotfiy, setConfirmationNotify] = useState(false);
  const [confirmationMsgNotfiy, setConfirmationMsgNotfiy] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [active, setActive] = useState(1);
  const [renotifySuperConsultantTmp, setRenotifySuperConsultantTmp] =
    useState(false);

  const navItems = [
    { text: t('Spots proposés'), active: 1 },
    { text: t('Spots sélectionnés'), active: 2 },
    { text: t('Historique Backlinks'), active: 3 },
  ];
  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('PixLink - Detail campaign');
  }, [t]);

  useEffect(() => {
    axiosPixLink
      .get(
        API.getCampaignDetail +
        `${window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ]
        }`
      )
      .then((res) => {
        setCampaignFilter(res.data);
        setWantedNetlinkings(res.data.wantedNetlinkings);
        setHistoricBackLinkings(res.data.historicBackLinkings);
        setNetlinkingsTmp(res.data.netlinkingSelecteds);
        setNetlinkings(res.data.netlinkingSelecteds);
        setNbrOfLinksDemanded(res.data.linksNumbers);
      });
  }, []);

  function sendNetlinkingChoose(data) {
    const newData = data.map((item) => {
      if (!item.hasOwnProperty('netlinking')) {
        const newSchemaItem = {
          id: null,
          netlinking: {
            id: item?.id,
            domain: item?.domain,
            kwPositioned: item?.kwPositioned,
            trafic: item?.trafic,
            tF: item?.tF,
            cF: item?.cF,
            tarifHr: item?.tarifHr,
            conditions: item?.conditions,
            themesList: item?.themesList,
            topicsList: item?.topicsList,
            platformName: item?.platformName,
            platformPrice: item?.platformPrice,
            platformRC: item?.platformRC,
            platformHR: item?.platformHR,
            contactPhoneNumber: item?.contactPhoneNumber,
            languageId: item?.languageId,
            contact: item?.contact,
            tarifRc: item?.tarifRc,
            contactedTime: item?.contactedTime,
            type: item?.type
          },
          responsibleRedactio: null,

          responsiblePublication: null,
          programmationDate: null,
          publicationDate: null,
          contactDate: null,
          articleLink: null,
          spotLink: null,
          ancreLink: null,
          insertionLink: null,
          publicationStatus: null,
          redactionStatus: null,
          spotStatus: null,
        };
        return newSchemaItem;
      }

      return item;
    });

    setNetlinkingsTmp(newData);
    SaveNetlinkingToCampaignsSilent(newData);
  }

  const SaveNetlinkingToCampaignsSilent = (netlinkingsTmp) => {
    setNetlinkings(netlinkingsTmp);
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingsTmp.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setNetlinkings(netlinkingsTmp);
      })
      .catch((e) => { });
  };

  function removeNetlinkingId(items) {
    const updatedNetlinkings = netlinkings.filter(
      (netlinking) =>
        !items.some((item) => item.netlinking.id === netlinking.netlinking.id)
    );
    setNetlinkingsTmp(updatedNetlinkings);
    setNetlinkings(updatedNetlinkings);
    RemoveNetlinkingToCampaigns(updatedNetlinkings);
  }

  const RemoveNetlinkingToCampaigns = (netlinkingswithoutSpot) => {
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingswithoutSpot.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        setNetlinkings(netlinkingswithoutSpot);
        setErrorSuccessMsg(
          `${t('Les spots')} ${t('ont été supprimés avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const sendUpdateSaveSelectedNetlinking = (updatedData) => {
    setNetlinkingsTmp(updatedData);
    setNetlinkings(updatedData);
  };

  const SaveNetlinkingToCampaigns = () => {
    setConfirmationSaveForce(false);
    setNetlinkings(netlinkingsTmp);
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingsTmp.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        setNetlinkings(netlinkingsTmp);
        setErrorSuccessMsg(
          `${t('Les spots')} ${t('ont été ajoutés avec succes')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  function haveSharedDomain(array1, array2) {
    // Extract domains from array1
    const domains1 = array1.map((obj) => obj?.netlinking?.domain);

    // Iterate over array2 and check if any domain matches
    for (let i = 0; i < array2.length; i++) {
      if (domains1.includes(array2[i]?.domain)) {
        return true; // If match found, return true
      }
    }

    return false; // If no match found, return false
  }

  const GlobalSaveNetlinkingToCampaigns = () => {
    if (haveSharedDomain(netlinkings, historicBackLinkings)) {
      setConfirmationMsgSaveForce(
        "Attention, vous allez ajouter un domaine qui existe dans la liste d'historique backlinks. Êtes-vous sûre de vouloir continuer ?"
      );
      setConfirmationSaveForce(true);
    } else {
      setConfirmationMsgSaveForce(
        t('Étes-vous sûre de vouloir valider la sélection des spots ? ')
      );
      setConfirmationSaveForce(true);
    }
  };

  const NotifierPixalioneNetlinkingToCampaigns = () => {
    axiosPixLink
      .post(
        `/campaign/NotifySuperAdminSuperConsultantSelectedNetlinking/${id}`,
        !campaignType
          ? netlinkings.map((object) => ({
            ...object,
            publicationStatus:
              object.publicationStatus === ''
                ? null
                : object.publicationStatus,
            redactionStatus:
              object.redactionStatus === '' ? null : object.redactionStatus,
            spotStatus: object.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ? PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT : PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT,
          }))
          : netlinkings.map((object) => ({
            ...object,
            publicationStatus:
              object.publicationStatus === ''
                ? null
                : object.publicationStatus,
            redactionStatus:
              object.redactionStatus === '' ? null : object.redactionStatus,
            spotStatus: object.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ? PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT : PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN,
          }))
      )
      .then((res) => {
        setIsSuccess(true);

        if (!campaignType) {
          setRenotifySuperConsultantTmp(true);
          setNetlinkings(
            netlinkings.map((item) => {
              if (item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.REFUSED_PIXALIONE,
                };
              } else if (item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
                };
              } else {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT,
                };
              }
            })
          );
        } else {
          setNetlinkings(
            netlinkings.map((item) => {
              if (item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.REFUSED_PIXALIONE,
                };
              } else if (item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
                };
              } else {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN,
                };
              }
            })
          );
        }
        setErrorSuccessMsg(
          campaignType
            ? `${t('Super Admin')} ${t('a été notifié avec succès')} !`
            : `${t('Super Consultant')} ${t('a été notifié avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const NotifierSuperAdminFilteringNetlinkingToCampaigns = () => {
    axiosPixLink
      .post(
        `/campaign/NotifySuperAdminFilteredSelectedNetlinking/${id}`,
        netlinkings
          .map((object) => ({
            ...object,
            publicationStatus:
              object.publicationStatus === '' ? null : object.publicationStatus,
            redactionStatus:
              object.redactionStatus === '' ? null : object.redactionStatus,
          }))
          .filter(
            (item) =>
              item.spotTmpStatus !== PIXLINK_STATUS.NOT_FILTERED_CONSULTANT &&
              item.spotStatus !== PIXLINK_STATUS.REFUSED_CLIENT &&
              item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE
          )
      )
      .then((res) => {
        setIsSuccess(true);
        if (!campaignType) {
          setNetlinkings(
            netlinkings
              .filter(
                (item) =>
                  item.spotTmpStatus !==
                  PIXLINK_STATUS.NOT_FILTERED_CONSULTANT &&
                  item.spotStatus !== PIXLINK_STATUS.REFUSED_CLIENT &&
                  item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE
              )
              .map((item) => ({
                ...item,
                spotStatus: PIXLINK_STATUS.TO_BE_COMMANDED,
              }))
          );
        } else {
          setNetlinkings(
            netlinkings
              .filter(
                (item) =>
                  item.spotTmpStatus !==
                  PIXLINK_STATUS.NOT_FILTERED_CONSULTANT &&
                  item.spotStatus !== PIXLINK_STATUS.REFUSED_CLIENT &&
                  item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE
              )
              .map((item) => ({
                ...item,
                spotStatus: PIXLINK_STATUS.TO_BE_COMMANDED,
              }))
          );
        }
        setErrorSuccessMsg(
          campaignType
            ? `${t('Super Admin')} ${t('a été notifié avec succès')} !`
            : `${t('Super Admin')} ${t('a été notifié avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const NotifierSuperAdminNotEnoughNetlinkingToCampaigns = () => {
    axiosPixLink
      .post(
        `/campaign/NotifySuperAdminFilteredSelectedNetlinkingNotEnough/${id}`,
        netlinkings
          .map((object) => ({
            ...object,
            publicationStatus:
              object.publicationStatus === '' ? null : object.publicationStatus,
            redactionStatus:
              object.redactionStatus === '' ? null : object.redactionStatus,
          }))
          .filter(
            (item) =>
              item.spotTmpStatus !== PIXLINK_STATUS.NOT_FILTERED_CONSULTANT
          )
      )
      .then((res) => {
        setIsSuccess(true);
        setErrorSuccessMsg(
          campaignType
            ? `${t('Super Admin')} ${t('a été notifié avec succès')} !`
            : `${t('Super Admin')} ${t('a été notifié avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const NotifierSuperConsultntReinform = () => {
    axiosPixLink
      .post(
        `/campaign/NotifySuperConsultantSelectedNetlinkingReinform/${id}`,
        netlinkings
          .map((object) => ({
            ...object,
            publicationStatus:
              object.publicationStatus === '' ? null : object.publicationStatus,
            redactionStatus:
              object.redactionStatus === '' ? null : object.redactionStatus,
          }))
          .filter(
            (item) =>
              item.spotTmpStatus !== PIXLINK_STATUS.NOT_FILTERED_CONSULTANT
          )
      )
      .then((res) => {
        setIsSuccess(true);
        setErrorSuccessMsg(
          campaignType
            ? `${t('Super Consultant')} ${t('a été renotifié avec succès')} !`
            : `${t('Super Consultant')} ${t('a été renotifié avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };
  return (
    <div>
      <div className="content-center">
        <div className="row-homePage">
          <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
            <div className="box-wrapper" style={{ height: '289px' }}>
              <div className="header-title mb-0">
                {t('Nombre de liens proposé')}
              </div>
              <div className="sub-header-title">
                {nbrOfLinksDemanded < 16
                  ? t('(Nombre de liens commandés * 2)')
                  : nbrOfLinksDemanded < 70
                    ? t('(Nombre de liens commandés + 15)')
                    : t('(Nombre de liens commandés + 30)')}
              </div>
              <div className="box-graph">
                <div className="d-flex justify-content-center">
                  {nbrOfLinksDemanded > 0 ? (
                    <MyCustomHighcharts
                      value={
                        netlinkingsTmp.length > 0
                          ? netlinkingsTmp
                            .filter(
                              (item) =>
                                item.spotTmpStatus !==
                                'NOT_FILTERED_CONSULTANT'
                            )
                            .filter(
                              (item) =>
                                item.spotStatus !==
                                PIXLINK_STATUS.REFUSED_PIXALIONE
                            )?.length
                          : netlinkings
                            .filter(
                              (item) =>
                                item.spotTmpStatus !==
                                'NOT_FILTERED_CONSULTANT'
                            )
                            .filter(
                              (item) =>
                                item.spotStatus !==
                                PIXLINK_STATUS.REFUSED_PIXALIONE
                            )?.length
                      }
                      maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                    ></MyCustomHighcharts>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {netlinkings.filter(
            (item) =>
              item.spotStatus !== 'TO_DEFINE' &&
              item.spotStatus !== null &&
              item.spotStatus !== undefined
          ).length > 0 && (
              <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
                <div className="box-wrapper" style={{ height: '289px' }}>
                  <div className="header-title mb-0">
                    {t('Nombre de liens par Status')}
                  </div>
                  <div className="box-graph">
                    <div className="d-flex justify-content-center">
                      {nbrOfLinksDemanded > 0 ? (
                        <span>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                            }}
                          >
                            {calculateConsultantValidateSuperAdmin(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  defaultColor={COLOR_JAUGE.GREY}
                                  label={t('A valider Super admin')}
                                  value={calculateConsultantValidateSuperAdmin(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateConsultantValidateSuperConsultant(
                              netlinkings
                            ) > 0 && (
                                <MyCustomHighchartsSmall
                                  defaultColor={COLOR_JAUGE.BLACK}
                                  label={t('A valider Super consultant')}
                                  value={calculateConsultantValidateSuperConsultant(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateConsultantValidateClient(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  defaultColor={COLOR_JAUGE.ORANGE}
                                  label={t('A valider client')}
                                  value={calculateConsultantValidateClient(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateConsultantToBeFilteredConsultant(
                              netlinkings
                            ) > 0 && (
                                <MyCustomHighchartsSmall
                                  defaultColor={COLOR_JAUGE.BLUE}
                                  label={t('Validé client')}
                                  value={calculateConsultantToBeFilteredConsultant(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateConsultantToBeCommanded(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  label={t('A commander')}
                                  defaultColor={COLOR_JAUGE.GREEN}
                                  value={calculateConsultantToBeCommanded(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateConsultantRefusedClient(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  label={t('Refusé client')}
                                  defaultColor={COLOR_JAUGE.RED}
                                  value={calculateConsultantRefusedClient(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateConsultantRefusedPixalione(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  label={t('Refusé Pixalione')}
                                  defaultColor={COLOR_JAUGE.VIOLET}
                                  value={calculateConsultantRefusedPixalione(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                          </div>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
            <div className="box-wrapper" style={{ height: '289px' }}>
              <div className="header-title mb-0">
                {t('Quota de refus disponible')}
              </div>
              <div className="sub-header-title">
                {t('((Nombre de liens proposé-Nombre de liens demandés)/2)')} :
              </div>
              <div className="box-graph">
                <div className="d-flex justify-content-center">
                  {nbrOfLinksDemanded > 0 ? (
                    <span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MyCustomReverseHighChats
                          defaultColor="#FF8D4B"
                          value={
                            netlinkings
                              .filter(
                                (item) =>
                                  item.spotStatus ===
                                  PIXLINK_STATUS.REFUSED_CLIENT ||
                                  item.spotTmpStatus ===
                                  PIXLINK_STATUS.REFUSED_CLIENT
                              )
                              .filter(
                                (item) =>
                                  item.spotTmpStatus !==
                                  'NOT_FILTERED_CONSULTANT'
                              ).length
                          }
                          maxValue={Math.floor(
                            calculateMinimumLinks(nbrOfLinksDemanded) -
                            calculateClientMinimumLinks(nbrOfLinksDemanded)
                          )}
                        ></MyCustomReverseHighChats>
                      </div>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 mb-2">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => {
                    setActive(item.active);
                  }}
                  className={`nav-link ${item.active === active ? 'active' : ''
                    }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="row-homePage">
          {active === 1 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                {campaignFilter && (
                  <ConsultantSearchSpotTableControl
                    sendNetlinkingChoose={sendNetlinkingChoose}
                    netlinikingToChoseData={netlinkingsTmp}
                    netlinkingChosenData={netlinkings}
                    campaignStatus={campaignStatus}
                    campaignFilter={campaignFilter}
                    wantedNetlinkings={wantedNetlinkings}
                    historicBackLinkings={historicBackLinkings}
                  ></ConsultantSearchSpotTableControl>
                )}
                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
          {active === 2 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                <div className="box-list-header"></div>
                <ConsultantSelectedSpotTableControl
                  netlinkings={netlinkings.filter(
                    (item) =>
                      item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE
                  )}
                  campaignFilter={null}
                  wantedNetlinkings={wantedNetlinkings}
                  removeNetlinkingId={removeNetlinkingId}
                  SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
                  sendUpdateSaveSelectedNetlinking={
                    sendUpdateSaveSelectedNetlinking
                  }
                  campaignStatus={campaignStatus}
                  historicBackLinkings={historicBackLinkings}
                  campaignType={campaignType}
                ></ConsultantSelectedSpotTableControl>

                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
          {active === 3 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                <div className="box-list-header"></div>
                <ConsultantHistoricalBacklinkingsTableControl
                  historicBackLinkings={historicBackLinkings}
                  campaignFilter={null}
                  wantedNetlinkings={wantedNetlinkings}
                  removeNetlinkingId={removeNetlinkingId}
                  SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
                ></ConsultantHistoricalBacklinkingsTableControl>
                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
        </div>
        {active === 1 && (
          <button
            className="bt-submit-y"
            style={{
              margin: '0 auto',
              marginTop: '5px',
              marginLeft: '5px',
              alignSelf: 'right',
              float: 'right',
            }}
            onClick={GlobalSaveNetlinkingToCampaigns}
          >
            {t('Valider')}
          </button>
        )}
        {active === 2 && (
          <span>
            {netlinkings.length < calculateMinimumLinks(nbrOfLinksDemanded) ? (
              <>
                <button
                  className="bt-submit-disabled-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    alignSelf: 'right',
                    float: 'right',
                  }}
                >
                  {t('Informer')}{' '}
                  {campaignStatus ===
                    'CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED'
                    ? 'Super admin'
                    : campaignType
                      ? 'Super admin'
                      : 'Super consultant'}
                </button>
                <button
                  className="bt-submit-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    alignSelf: 'right',
                    float: 'right',
                  }}
                  onClick={NotifierSuperAdminNotEnoughNetlinkingToCampaigns}
                >
                  {t('Alerte spots insuffisants')}
                </button>
              </>
            ) : (!campaignStatus ||
              campaignStatus ===
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_STARTED_TO_BE_SELECTED_BY_CONSULTANT ||
              campaignStatus ===
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_TO_BE_APPROVED ||
              campaignStatus ===
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED ||
              campaignStatus ===
              'CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED') &&
              !renotifySuperConsultantTmp &&
              campaignStatus !==
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_SUPER_CONSULTANT_TO_BE_APPROVED ? (
              <button
                className="bt-submit-y"
                style={{
                  margin: '0 auto',
                  marginTop: '5px',
                  marginLeft: '5px',
                  alignSelf: 'right',
                  float: 'right',
                }}
                onClick={
                  campaignStatus ===
                    'CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED'
                    ? NotifierSuperAdminFilteringNetlinkingToCampaigns
                    : NotifierPixalioneNetlinkingToCampaigns
                }
              >

                {campaignStatus ===
                  'CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED'
                  ? t('Prêt à lancer')
                  : campaignType
                    ? t('Informer') + ' ' + 'Super admin'
                    : t('Informer') + ' ' + 'Super consultant'}
              </button>
            ) : (
              <button
                className="bt-submit-y"
                style={{
                  margin: '0 auto',
                  marginTop: '5px',
                  marginLeft: '5px',
                  alignSelf: 'right',
                  float: 'right',
                }}
                onClick={NotifierSuperConsultntReinform}
              >
                {t('Reinformer')}{' '}
                {campaignStatus ===
                  'CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED'
                  ? 'Super consultant'
                  : campaignType
                    ? 'Super consultant'
                    : 'Super consultant'}
              </button>
            )}
          </span>
        )}
      </div>

      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
          //       history.goBack();
        }}
        success={isSuccess}
      />
      <ConfirmationModal
        open={confirmationSaveForce}
        message={confirmationMsgSaveForce}
        handleClose={() => setConfirmationSaveForce(false)}
        executeAction={SaveNetlinkingToCampaigns}
      />
      <ErrorSuccesModal
        open={confirmationMsgNotfiy}
        message={confirmationMsgNotfiy}
        handleClose={() => {
          setErrorSuccess(false);
          //       history.goBack();
        }}
        success={isSuccess}
      />
    </div>
  );
}
