export function detectBrowser() {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('Edge') > -1) {
    return 'edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    return 'chrome';
  } else if (userAgent.indexOf('Firefox') > -1) {
    return 'firefox';
  }
  // Add more browser detection if needed
}

export const browserList = {
  FIREFOX: 'firefox',
  EDGE: 'edge',
  CHROME: 'chrome',
};
