// Calendar.js
import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Calendar = ({ onValueChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toISOString();
    onValueChange(formattedDate);
  };

  const datePickerRef = useRef(null);
  const handleIconClick = () => {
    // Open the date picker when the icon is clicked
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <div className="calendar-container-deadline form-control form-ct">
      {/* Use react-datepicker instead of input type="date" */}
      <DatePicker
        selected={selectedDate}
        className="calendar-borderless"
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        popperPlacement="top"
        ref={datePickerRef}
      />
      <div className="calendar-icon-deadline" onClick={handleIconClick}>
        <img src="/Images/icon-calendar.png" alt="Calendar" />
      </div>
      {/* Uncomment the line below if you want to show the selected date */}
      {/* {selectedDate && <p>Selected Date: {selectedDate.toISOString()}</p>} */}
    </div>
  );
};

export default Calendar;
