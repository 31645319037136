import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/shared/CheckBox';
import { useSelector } from 'react-redux';
import { UserSessionSelector } from 'store/UserSession';
import { PIXLINK_ROLES } from 'utils/Constants';
import { Tooltip } from '@mui/material';
import axiosPixLink from 'config/axiosPixLink';

export function NetlinkingTable({
  shownNetlinkingFilteredList,
  nameSearch,
  onNetlinkingContactFilterChange,
  contactFilter,
  sendNetlinkingExport,
  ordersPerPage,
  netlinkingFilterCache,
}) {
  const { t } = useTranslation();
  let { user } = useSelector(UserSessionSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = ordersPerPage || 20;
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedItems, setSortedItems] = useState([]);
  const [tableExport, setTableExport] = useState([]);

  const [editableCase, setEditableCase] = useState(null);
  const [editedCase, setEditedCase] = useState('');
  const [typeCaseChange, setTypeCaseChange] = useState('');

  useEffect(() => {
    const sorted = [...shownNetlinkingFilteredList].sort((a, b) => {
      if (sortColumn === 'domain') {
        return sortDirection === 'asc'
          ? a.domain.localeCompare(b.domain)
          : b.domain.localeCompare(a.domain);
      } else if (sortColumn === 'languageId') {
        return sortDirection === 'asc'
          ? a.languageId - b.languageId
          : b.languageId - a.languageId;
      } else if (sortColumn === 'themesList') {
        return sortDirection === 'asc'
          ? (a.themesList || '').localeCompare(b.themesList || '')
          : (b.themesList || '').localeCompare(a.themesList || '');
      } else if (sortColumn === 'topicsList') {
        return sortDirection === 'asc'
          ? (a.topicsList || '').localeCompare(b.topicsList || '')
          : (b.topicsList || '').localeCompare(a.topicsList || '');
      } else if (sortColumn === 'contact') {
        return sortDirection === 'asc'
          ? (getEmailPart(a.contact) || '').localeCompare(
              getEmailPart(b.contact) || ''
            )
          : (getEmailPart(b.contact) || '').localeCompare(
              getEmailPart(a.contact) || ''
            );
      } else if (sortColumn === 'contactName') {
        return sortDirection === 'asc'
          ? (getNamePart(a.contact) || '').localeCompare(
              getNamePart(b.contact) || ''
            )
          : (getNamePart(b.contact) || '').localeCompare(
              getNamePart(a.contact) || ''
            );
      } else if (sortColumn === 'contactedAt') {
        return sortDirection === 'asc'
          ? (a.contactedTime || '').localeCompare(b.contactedTime || '')
          : (b.contactedTime || '').localeCompare(a.contactedTime || '');
      } else if (sortColumn === 'kwPosition') {
        return sortDirection === 'asc'
          ? (a.kwPositioned || 0) - (b.kwPositioned || 0)
          : (b.kwPositioned || 0) - (a.kwPositioned || 0);
      } else if (sortColumn === 'Trafic') {
        return sortDirection === 'asc'
          ? (a.trafic || 0) - (b.trafic || 0)
          : (b.trafic || 0) - (a.trafic || 0);
      } else if (sortColumn === 'tF') {
        return sortDirection === 'asc'
          ? (a.tF || 0) - (b.tF || 0)
          : (b.tF || 0) - (a.tF || 0);
      } else if (sortColumn === 'cF') {
        return sortDirection === 'asc'
          ? (a.cF || 0) - (b.cF || 0)
          : (b.cF || 0) - (a.cF || 0);
      } else if (sortColumn === 'tarifHr') {
        return sortDirection === 'asc'
          ? (a.tarifHr || 0) - (b.tarifHr || 0)
          : (b.tarifHr || 0) - (a.tarifHr || 0);
      } else if (sortColumn === 'tarifRc') {
        return sortDirection === 'asc'
          ? (a.tarifRc || 0) - (b.tarifRc || 0)
          : (b.tarifRc || 0) - (a.tarifRc || 0);
      } else if (sortColumn === 'platformRC') {
        return sortDirection === 'asc'
          ? (a.platformRC || 0) - (b.platformRC || 0)
          : (b.platformRC || 0) - (a.platformRC || 0);
      } else if (sortColumn === 'platformHR') {
        return sortDirection === 'asc'
          ? (a.platformHR || 0) - (b.platformHR || 0)
          : (b.platformHR || 0) - (a.platformHR || 0);
      }
      return 0;
    });

    setSortedItems(sorted);
  }, [shownNetlinkingFilteredList, sortColumn, sortDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [nameSearch]);

  const handleSortToggle = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleThemesListHover = (event, themes) => {
    event.target.textContent = themes;
  };

  const handleThemesListLeave = (event, themes) => {
    if (themes !== null && themes !== undefined && themes.includes(',')) {
      event.target.textContent = themes.split(',')[0] + '...';
    } else {
      event.target.textContent = themes;
    }
  };

  const transformDate = (dateString) => {
    const parsedDate = new Date(dateString);
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + parsedDate.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  function getEmailPart(inputString) {
    try {
      // Split the inputString using '<' and '>' as delimiters
      const parts = inputString.split('<');

      // Check if the split produced at least two parts
      if (parts.length >= 2) {
        // Get the second part and remove the trailing '>'
        const emailPart = parts[1].replace('>', '').trim();
        return emailPart;
      } else {
        // If the inputString is not in the expected format, return null or an appropriate value
        return inputString;
      }
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  function getNamePart(inputString) {
    try {
      // Split the inputString using '@' as a delimiter
      const emailRegex = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
      let stringWithoutEmail = inputString.replace(emailRegex, '');
      stringWithoutEmail = stringWithoutEmail.replace('>', '');
      stringWithoutEmail = stringWithoutEmail.replace('<', '');
      stringWithoutEmail = stringWithoutEmail.replace('/', '');
      if (stringWithoutEmail.trim().length === 0) {
        return '-';
      }
      return stringWithoutEmail.trim();
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  const handleNetlinkingContactFilterChange = (value) => {
    // Call the callback function from the Father component to pass the value to the parent
    onNetlinkingContactFilterChange(value);
  };

  const history = useHistory();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems
    .filter((item) =>
      item.domain.toLowerCase().includes(nameSearch.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const handleCellClick = (id, value, type) => {
    if (id === editableCase && type === typeCaseChange) {
      setEditableCase(null);
      setTypeCaseChange(null);
      saveEditedCase();
    } else {
      setEditableCase(id);
      setTypeCaseChange(type);
      // Set the initial value of the input field
      setEditedCase(value);
    }
  };

  // Function to handle TF value change
  const handleCellValueChange = (event) => {
    // Update the edited TF value
    setEditedCase(event.target.value);
  };

  // Function to save the edited TF value
  const saveEditedCase = () => {
    let newData = [];
    switch (typeCaseChange) {
      case 'tF':
        newData = sortedItems.map((obj) =>
          obj.id === editableCase ? { ...obj, tF: editedCase } : obj
        );
        break;
      case 'cF':
        newData = sortedItems.map((obj) =>
          obj.id === editableCase ? { ...obj, cF: editedCase } : obj
        );
        break;
      case 'kwPositioned':
        newData = sortedItems.map((obj) =>
          obj.id === editableCase ? { ...obj, kwPositioned: editedCase } : obj
        );
        break;
      case 'tarifHr':
        newData = sortedItems.map((obj) =>
          obj.id === editableCase ? { ...obj, tarifHr: editedCase } : obj
        );
        break;
      case 'tarifRc':
        newData = sortedItems.map((obj) =>
          obj.id === editableCase ? { ...obj, tarifRc: editedCase } : obj
        );
        break;
      case 'trafic':
        newData = sortedItems.map((obj) =>
          obj.id === editableCase ? { ...obj, trafic: editedCase } : obj
        );
        break;
      default:
    }

    setSortedItems(newData);
    // Update the TF value in the data or send it to the server
    // Reset the editable state
    const desiredObject = newData.find((item) => item.id === editableCase);
    updateNetlinking(desiredObject);
    setEditableCase(null);
    setTypeCaseChange(null);
  };
  const handleKeyPressEnter = (event) => {
    if (event.key === 'Enter') {
      saveEditedCase();
    }
  };

  const updateNetlinking = async (netlinking) => {
    try {
      const response = await axiosPixLink
        .put(`/netlinking/${netlinking.id}`, {
          id: netlinking.id,
          domain: netlinking.domain,
          kwPositioned: netlinking.kwPositioned,
          trafic: netlinking.trafic,
          tF: netlinking.tF,
          cF: netlinking.cF,
          tarifHr: netlinking.tarifHr,
          tarifRc: netlinking.tarifRc,
          languageId: netlinking.languageId,
          conditions: netlinking.conditions,
          contactedTime: netlinking.contactedTime,
          contact: netlinking.contact,
          themesList: netlinking.themesList,
          topicsList: netlinking.topicsList,
        })
        .then((res) => {
          return res.data;
        });
    } catch (error) {
      if (error.response) {
        // Request was made, but the server responded with an error status
        console.error('Error:', error.response.data);
      } else {
        console.error('Error:', error.message);
      }
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  return (
    <>
      <div className="table-responsive">
        <div className="box-table-netlinking">
          <table className="table-lists">
            <thead>
              <tr>
                <th
                  className="cell-fixed-column"
                  onClick={() => handleSortToggle('domain')}
                >
                  <span className={sortColumn === 'domain' ? 'active' : ''}>
                    {t('Domaine')}
                  </span>
                  {sortColumn === 'domain' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px', marginTop: '5px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px', marginTop: '5px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('themesList')}
                  className={sortColumn === 'themesList' ? 'active' : ''}
                >
                  {t('Thématique')}
                  {sortColumn === 'themesList' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('topicsList')}
                  className={sortColumn === 'topicsList' ? 'active' : ''}
                >
                  {t('Topic Majestic')}
                  {sortColumn === 'topicsList' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('kwPosition')}
                  className={sortColumn === 'kwPosition' ? 'active' : ''}
                >
                  {t('Kw positionés')}
                  {sortColumn === 'kwPosition' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('Trafic')}
                  className={sortColumn === 'Trafic' ? 'active' : ''}
                >
                  {t('Trafic')}{' '}
                  {sortColumn === 'Trafic' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('tF')}
                  className={sortColumn === 'tF' ? 'active' : ''}
                >
                  {t('TF')}{' '}
                  {sortColumn === 'tF' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('cF')}
                  className={sortColumn === 'cF' ? 'active' : ''}
                >
                  {t('CF')}{' '}
                  {sortColumn === 'cF' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('tarifHr')}
                  className={sortColumn === 'tarifHr' ? 'active' : ''}
                >
                  {t('Tarif')} HR{' '}
                  {sortColumn === 'tarifHr' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('tarifRc')}
                  className={sortColumn === 'tarifRc' ? 'active' : ''}
                >
                  {t('Tarif')} RC{' '}
                  {sortColumn === 'tarifRc' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('platformRC')}
                  className={sortColumn === 'platformRC' ? 'active' : ''}
                >
                  {t('Tarif plateforme') + ' RC'}
                  {sortColumn === 'platformRC' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('platformHR')}
                  className={sortColumn === 'platformHR' ? 'active' : ''}
                >
                  {t('Tarif plateforme') + ' HR'}
                  {sortColumn === 'platformHR' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('contactedAt')}
                  className={sortColumn === 'contact' ? 'active' : ''}
                >
                  {t('Contacté le')}
                  {sortColumn === 'contactedAt' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('contact')}
                  className={sortColumn === 'contact' ? 'active' : ''}
                >
                  {t('Contact')}
                  {sortColumn === 'contact' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => handleSortToggle('contactName')}
                  className={sortColumn === 'contactName' ? 'active' : ''}
                >
                  {t('Nom contact')}
                  {sortColumn === 'contactName' ? (
                    sortDirection === 'asc' ? (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-up"
                      />
                    ) : (
                      <i
                        style={{ marginLeft: '3px' }}
                        className="bi bi-chevron-down"
                      />
                    )
                  ) : null}
                </th>
                <th>
                  <CheckBox
                    name="thischeckbox"
                    tick={
                      tableExport.length === shownNetlinkingFilteredList.length
                    }
                    onCheck={() => {
                      if (
                        tableExport.length < shownNetlinkingFilteredList.length
                      ) {
                        setTableExport([...shownNetlinkingFilteredList]);
                        sendNetlinkingExport([...shownNetlinkingFilteredList]);
                      } else {
                        setTableExport([]);
                        sendNetlinkingExport([]);
                      }
                    }}
                  ></CheckBox>{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr
                  key={item.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (user.role === PIXLINK_ROLES.CONSULTANT) {
                      localStorage.setItem(
                        'netlinkingFilterCache',
                        JSON.stringify({
                          netlinkingFilterCache: netlinkingFilterCache,
                        })
                      );
                      history.push(`/Consultant/SpotDetail/${item.id}`);
                    } else if (user.role === PIXLINK_ROLES.SUPER_ADMIN) {
                      localStorage.setItem(
                        'netlinkingFilterCache',
                        JSON.stringify({
                          netlinkingFilterCache: netlinkingFilterCache,
                        })
                      );
                      history.push(`/SuperAdmin/SpotDetail/${item.id}`);
                    } else if (user.role === PIXLINK_ROLES.ADMIN) {
                      localStorage.setItem(
                        'netlinkingFilterCache',
                        JSON.stringify({
                          netlinkingFilterCache: netlinkingFilterCache,
                        })
                      );
                      history.push(`/Admin/SpotDetail/${item.id}`);
                    }
                  }}
                >
                  <td className="cell-fixed-column">
                    <div style={{ cursor: 'pointer' }}>
                      <a
                        href={
                          !item.domain.startsWith('http://') &&
                          !item.domain.startsWith('https://')
                            ? 'https://' + item.domain
                            : item.domain
                        }
                        target="_blank"
                      >
                        {' '}
                        {item.domain}
                      </a>
                    </div>
                  </td>
                  <td onClick={() => {}}>
                    <span
                      onMouseOver={(event) =>
                        handleThemesListHover(event, item.themesList)
                      }
                      onMouseLeave={(event) =>
                        handleThemesListLeave(event, item.themesList)
                      }
                    >
                      {item.themesList && item.themesList.includes(',')
                        ? item.themesList.split(',')[0] + '...'
                        : item.themesList}
                    </span>
                  </td>
                  <td onClick={() => {}}>
                    <span>{item.topicsList ? item.topicsList : '-'}</span>
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(
                        item.id,
                        item.kwPositioned,
                        'kwPositioned'
                      );
                    }}
                  >
                    {editableCase === item.id &&
                    typeCaseChange === 'kwPositioned' ? (
                      <input
                        className="input-Little-array"
                        type="text"
                        value={editedCase}
                        onChange={handleCellValueChange}
                        onBlur={saveEditedCase}
                        autoFocus
                        onKeyDown={handleKeyPressEnter}
                      />
                    ) : (
                      <span>{item.kwPositioned ? item.kwPositioned : '-'}</span>
                    )}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(item.id, item.trafic, 'trafic');
                    }}
                  >
                    {editableCase === item.id && typeCaseChange === 'trafic' ? (
                      <input
                        className="input-Little-array"
                        type="text"
                        value={editedCase}
                        onChange={handleCellValueChange}
                        onBlur={saveEditedCase}
                        autoFocus
                        onKeyDown={handleKeyPressEnter}
                      />
                    ) : (
                      <span>{item.trafic ? item.trafic : '-'}</span>
                    )}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(item.id, item.tF, 'tF');
                    }}
                  >
                    {editableCase === item.id && typeCaseChange === 'tF' ? (
                      <input
                        className="input-Little-array"
                        type="text"
                        value={editedCase}
                        onChange={handleCellValueChange}
                        onBlur={saveEditedCase}
                        autoFocus
                        onKeyDown={handleKeyPressEnter}
                      />
                    ) : (
                      <span>{item.tF ? item.tF : '-'}</span>
                    )}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(item.id, item.cF, 'cF');
                    }}
                  >
                    {editableCase === item.id && typeCaseChange === 'cF' ? (
                      <input
                        className="input-Little-array"
                        type="text"
                        value={editedCase}
                        onChange={handleCellValueChange}
                        onBlur={saveEditedCase}
                        autoFocus
                        onKeyDown={handleKeyPressEnter}
                      />
                    ) : (
                      <span>{item.cF ? item.cF : '-'}</span>
                    )}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(item.id, item.tarifHr, 'tarifHr');
                    }}
                  >
                    {editableCase === item.id &&
                    typeCaseChange === 'tarifHr' ? (
                      <input
                        className="input-Little-array"
                        type="text"
                        value={editedCase}
                        onChange={handleCellValueChange}
                        onBlur={saveEditedCase}
                        autoFocus
                        onKeyDown={handleKeyPressEnter}
                      />
                    ) : (
                      <span
                        className={
                          (item.tarifHr && !item.tarifRc) ||
                          (item.tarifHr &&
                            item.tarifRc &&
                            item.tarifHr < item.tarifRc)
                            ? 'green-text'
                            : 'red-text'
                        }
                      >
                        {item.tarifHr ? item.tarifHr : '-'}
                      </span>
                    )}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(item.id, item.tarifRc, 'tarifRc');
                    }}
                  >
                    {editableCase === item.id &&
                    typeCaseChange === 'tarifRc' ? (
                      <input
                        className="input-Little-array"
                        type="text"
                        value={editedCase}
                        onChange={handleCellValueChange}
                        onBlur={saveEditedCase}
                        autoFocus
                        onKeyDown={handleKeyPressEnter}
                      />
                    ) : (
                      <span
                        className={
                          (!item.tarifHr && item.tarifRc) ||
                          (item.tarifHr &&
                            item.tarifRc &&
                            item.tarifHr >= item.tarifRc)
                            ? 'green-text'
                            : 'red-text'
                        }
                      >
                        {item.tarifRc ? item.tarifRc : '-'}
                      </span>
                    )}
                  </td>
                  <td onClick={() => {}}>
                    {item.platformRC ? (
                      <Tooltip title={item.platformName}>
                        <span>{item.platformRC}</span>
                      </Tooltip>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td onClick={() => {}}>
                    {item.platformHR ? (
                      <Tooltip title={item.platformName}>
                        <span>{item.platformHR}</span>
                      </Tooltip>
                    ) : (
                      <span>-</span>
                    )}
                  </td>

                  <td onClick={() => {}}>
                    <span>
                      {item.contactedTime
                        ? transformDate(item.contactedTime)
                        : '-'}
                    </span>
                  </td>
                  <td>
                    <span
                      onClick={(e) => {
                        handleNetlinkingContactFilterChange(
                          getEmailPart(item.contact)
                        );
                        e.stopPropagation();
                      }}
                      className={
                        contactFilter != '' &&
                        item.contact != null &&
                        item.contact != undefined &&
                        item.contact.includes(contactFilter)
                          ? 'yellow-text'
                          : ''
                      }
                    >
                      {item.contact ? getEmailPart(item.contact) : '-'}
                    </span>
                  </td>
                  <td onClick={() => {}}>
                    <span>
                      {item.contact ? getNamePart(item.contact) : '-'}
                    </span>
                  </td>
                  <td>
                    <span
                      className="container-table-export"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CheckBox
                        name="thischeckbox"
                        tick={tableExport.some(
                          (netlinking) => netlinking.id === item.id
                        )}
                        onCheck={() => {
                          if (
                            !tableExport.some(
                              (netlinking) => netlinking.id === item.id
                            )
                          ) {
                            setTableExport([...tableExport, item]);
                            sendNetlinkingExport([...tableExport, item]);
                          } else {
                            setTableExport((tableExport) =>
                              tableExport.filter(
                                (netlinking) => netlinking.id !== item.id
                              )
                            );
                            sendNetlinkingExport(
                              tableExport.filter(
                                (netlinking) => netlinking.id !== item.id
                              )
                            );
                          }
                        }}
                      ></CheckBox>{' '}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="box-pagination">
        {sortedItems.length === 0 || currentPage === 1 ? (
          <div style={{ cursor: 'not-allowed' }} className="text-page">
            {t('Précédent')}
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {t('Précédent')}
          </div>
        )}
        <div className="list-page-number">
          {sortedItems.length === 0 ||
          currentPage === Math.ceil(sortedItems.length / itemsPerPage) ? (
            <span style={{ cursor: 'not-allowed' }} className="text-page">
              {t('Suivant')}
            </span>
          ) : (
            <span onClick={() => setCurrentPage(currentPage + 1)}>
              {t('Suivant')}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
