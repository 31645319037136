import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/styles';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'reactstrap';
import { updateFlag } from 'store/UserSession';

import { ROLES } from 'utils/Constants';

export const VideoInstructionsModal = ({
  showInstruction,
  setShowInstruction,
  showInfoBanner,
  user,
  handleOnchange,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState(0);

  const value = tabIndex.toString();
  return (
    <Modal
      style={{ minWidth: 800 }}
      isOpen={showInstruction}
      toggle={() => {
        setShowInstruction();
        dispatch(updateFlag(false));
      }}
    >
      {user && user.role === ROLES.WRITER ? (
        <Box>
          <TabContext value={value}>
            <Tabs
              variant="fullWidth"
              components={{
                Root: TabsRoot,
                FlexContainer: FlexContainer,
                Scroller: Scroller,
                Indicator: Indicator,
              }}
              value={tabIndex}
              onChange={(e, index) => setTabIndex(index)}
            >
              <Tab
                components={{
                  Root: TabItemRoot,
                  TabItemWrapper: TabItemWrapper,
                }}
                disableRipple
                label={t('Pour ajouter un nouveau texte')}
                value={0}
              />
              <Tab
                components={{
                  Root: TabItemRoot,
                  TabItemWrapper,
                }}
                disableRipple
                label={t(
                  "Pour ajouter un texte (optimisation d'un contenu existant)"
                )}
                value={1}
              />
            </Tabs>
            <TabPanel value="0" style={{ padding: 0 }}>
              <video
                style={{
                  high: '900px',
                  width: '800px',
                }}
                controls
              >
                <source src="/Tutorial/Redactor/one.mp4" type="video/mp4" />
              </video>
            </TabPanel>
            <TabPanel value="1" style={{ padding: 0 }}>
              <video
                style={{
                  high: '900px',
                  width: '800px',
                }}
                controls
              >
                <source src="/Tutorial/Redactor/two.mp4" type="video/mp4" />
              </video>
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <video
          style={{
            high: '900px',
            width: '800px',
          }}
          controls
        >
          <source src="/Tutorial/Client/one.mp4" type="video/mp4" />
        </video>
      )}
      {showInfoBanner ? (
        <div className="ms-3">
          <input type="checkbox" onChange={handleOnchange} />{' '}
          {t('Ne plus afficher ce message')}
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
};

export const TabsRoot = styled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.type === 'light' ? '#eee' : theme.palette.divider,
  borderRadius: 10,
  minHeight: 44,
}));

export const FlexContainer = styled('div')({
  display: 'inline-flex',
  position: 'relative',
  zIndex: 1,
});

export const Scroller = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0 8px',
  },
}));

export const Indicator = styled('div')(({ theme }) => ({
  top: 3,
  bottom: 3,
  right: 3,
  height: 'auto',
  background: 'none',
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 4,
    right: 4,
    bottom: 0,
    borderRadius: 8,
    backgroundColor:
      theme.palette.type === 'light' ? '#fff' : theme.palette.action.selected,
    boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
  },
}));

export const TabItemRoot = styled('div')(({ theme }) => ({
  '&:hover': {
    opacity: 1,
  },
  minHeight: 44,
  minWidth: 96,
  [theme.breakpoints.up('md')]: {
    minWidth: 120,
  },
}));

export const TabItemWrapper = styled('div')(({ theme }) => ({
  // zIndex: 2,
  // marginTop: theme.spacing(0.5),
  color: theme.palette.text.primary,
  textTransform: 'initial',
}));
