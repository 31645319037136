import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#E0E0E0',
          '& .MuiSlider-track': {
            backgroundColor: '#E0E0E0',
          },
          '& .MuiSlider-thumb': {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px rgba(255, 228, 38, 0.16)`,
            },
          },
        },
      },
    },
  },
});

const SliderComponentDisabled = ({
  SliderName,
  values,
  handleSliderChange,
  minValue,
  maxValue,
  handleMinInputChange,
  handleMaxInputChange,
  SliderLabel,
}) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <div className="col-12 col-md-3 margin-Slider-filter">
        <div className="label-title margin-Slider-filter">{SliderName}</div>
        <div className="text-field-container">
          <TextField
            disabled={true}
            label={'Minimum ' + SliderLabel}
            type="number"
            value={minValue}
            onChange={handleMinInputChange}
            inputProps={{
              step: 1,
            }}
          />
          <TextField
            disabled={true}
            label={'Maximum ' + SliderLabel}
            type="number"
            value={maxValue}
            onChange={handleMaxInputChange}
            inputProps={{
              step: 1,
            }}
          />
        </div>
        <Slider
          disabled={true}
          value={values}
          onChange={handleSliderChange}
          min={0}
          max={10000}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
        <Typography id="range-slider" gutterBottom>
          Range: {values[0]} - {values[1]}
        </Typography>
      </div>
    </ThemeProvider>
  );
};

export default SliderComponentDisabled;
