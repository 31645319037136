import { Autocomplete, InputBase, createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { customStyle } from '../../Netlinking/AutoComplete/CustomAutocompleteStyle';

const useStyles = makeStyles(() => createStyles(customStyle));

export const CustomAutocomplete = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Autocomplete
      options={props.list}
      disabled={props.disabled}
      defaultValue={props.value}
      /*getOptionSelected={(option) => props.getOptionSelected(option)}*/
      value={props.value}
      noOptionsText={t("Pas d'options")}
      getOptionLabel={(option) => props.getOptionLabel(option)}
      fullWidth
      classes={{
        option: classes.option,
        clearIndicatorDirty: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
      }}
      className={`${props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
      onChange={(event, value) => props.setValue(value)}
      getOptionDisabled={(option) => props.getOptionDisabled(option)}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return (
          <InputBase
            classes={{ root: classes.root }}
            variant="outlined"
            {...params.InputProps}
            {...rest}
          />
        );
      }}
    />
  );
};

export const CustomAutocompleteFreeSolo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Autocomplete
      freeSolo
      options={props.list}
      getOptionLabel={(option) => props.getOptionLabel(option)}
      noOptionsText={t("Pas d'options")}
      value={props.value}
      onInputChange={props.onInputChange}
      onChange={(event, value) => props.setValue(value)}
      fullWidth
      classes={{
        option: classes.option,
        clearIndicatorDirty: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
      }}
      className={`${props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return (
          <InputBase
            classes={{ root: classes.root }}
            variant="outlined"
            {...params.InputProps}
            {...rest}
          />
        );
      }}
    />
  );
};

export const CustomAutocompleteDisabled = (props) => {
  const classes = useStyles();

  return (
    <Autocomplete
      readOnly
      disableClearable
      options={props.list}
      getOptionLabel={(option) => props.getOptionLabel(option)}
      value={props.value}
      onChange={(event, value) => props.setValue(value)}
      fullWidth
      classes={{
        option: classes.option,
        clearIndicatorDirty: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
      }}
      className={`${props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return (
          <InputBase
            readOnly
            classes={{ root: classes.root }}
            variant="outlined"
            {...params.InputProps}
            {...rest}
          />
        );
      }}
    />
  );
};
