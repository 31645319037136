import React, { useState, useEffect } from 'react';
import axiosPixLink from 'config/axiosPixLink';
import { Link, useHistory } from 'react-router-dom';
import LanguageCheckBoxGroup from 'components/Netlinking/CheckBox/LanguageCheckBox';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import { ThemeSearchField } from 'components/Netlinking/AutoComplete/themeAutoComplete';
import { Loader } from 'components/shared/Loader';
import { NetlinkingTable } from 'components/Netlinking/Table/Table';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import CheckBox from 'components/shared/CheckBox';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import { PAGE_NB_OPTIONS } from 'utils/Constants';
import { getMaxAttributeValue } from 'utils/tables/tables';
import {
  getLanguageIdByName,
  getLanguageNameById,
} from 'utils/netlinking/languages';
export default function SuperAdminSearchNetlinking() {
  const pageNbOptions = PAGE_NB_OPTIONS;
  const { t } = useTranslation();
  const history = useHistory();
  const [netlinkingType, setNetlinkingType] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [filteringLoading, setFilteringLoading] = useState(false);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dataExcelToExport, setDataExcelToExport] = useState([]);
  function handleDataExcelToExport(data) {
    setDataExcelToExport(data);
  }

  function handleTypeCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox0
    ) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
    } else if (newStates.checkbox0) {
      setNetlinkingType('ultraPremium');
    }
  }

  function handleThemeChange(newTheme) {
    if (
      newTheme !== null &&
      newTheme !== undefined &&
      !netlinkingTheme.includes(newTheme)
    ) {
      setNetlinkingTheme([...netlinkingTheme, newTheme]);
    }
  }

  function handleActiveAdvancedFilter() {
    setActiveAdvancedFilter(!activeAdvancedFilter);
  }

  function removeTheme(theme) {
    const updatedThemes = netlinkingTheme.filter((t) => t !== theme);
    setNetlinkingTheme(updatedThemes);
  }
  function handleLanguageCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox4 &&
      !newStates.checkbox5 &&
      !newStates.checkbox6 &&
      !newStates.checkboxExternal
    ) {
      setNetlinkingLanguage('');
    }
    if (newStates.checkbox1) {
      setNetlinkingLanguage(6);
    } else if (newStates.checkbox2) {
      setNetlinkingLanguage(1);
    } else if (newStates.checkbox3) {
      setNetlinkingLanguage(3);
    } else if (newStates.checkbox4) {
      setNetlinkingLanguage(2);
    } else if (newStates.checkbox5) {
      setNetlinkingLanguage(4);
    } else if (newStates.checkbox6) {
      setNetlinkingLanguage(5);
    } else if (newStates.checkboxExternal) {
      setNetlinkingLanguage(getLanguageIdByName(newStates.checkboxExternal));
    }
  }
  async function removeLanguageFilter() {
    setNetlinkingLanguage('');
  }

  async function removeNetlinkingContactFilter() {
    setNetlinkingContactFilter('');
  }
  function runSearchFunction() {
    setFilteringLoading(true);
    searchFunction();
    setShowTable(true);
    setPopupOpen(false);
    setFilteringLoading(false);
  }
  function searchFunction() {
    let tmpnetlinkingTypeBrut = netlinkingList;
    if (netlinkingType === 'redType') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) =>
          item.type === 'redList' ||
          item.type === 'liste rouge' ||
          item.type === 'Liste Rouge' ||
          item.type === 'Liste rouge'
      );
    } else if (netlinkingType === 'standard') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'standard'
      );
    } else if (netlinkingType === 'premium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'premium'
      );
    } else if (netlinkingType === 'ultraPremium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'ultraPremium'
      );
    }
    let tmpNetlinkingLanguage = tmpnetlinkingTypeBrut;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = tmpNetlinkingLanguage.filter(
        (item) => item.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) =>
            row.themesList !== null &&
            row.themesList.toLowerCase().includes(value.toLowerCase())
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.tF >= TFValues[0] && item.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.trafic >= TraficValues[0] && item.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.tarifHr >= TarifHRValues[0] &&
          item.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.tarifRc >= TarifRCValues[0] &&
          item.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.kwPositioned >= Kwvalues[0] &&
          item.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.contact != null &&
          item.contact != undefined &&
          item.contact.includes(netlinkingContactFilter)
      );
    }

    setNetlinkingFilteredList(tmpNetlinkingContactFilter);
  }

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);
    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  useEffect(() => {
    if (netlinkingList.length > 0) {
      const data = JSON.parse(localStorage.getItem('netlinkingFilterCache'));
      if (JSON.parse(localStorage.getItem('netlinkingFilterCache')) !== null) {
        if (
          data.netlinkingFilterCache.netlinkingType !== undefined ||
          data.netlinkingFilterCache.netlinkingType !== null
        ) {
          setNetlinkingType(data.netlinkingFilterCache.netlinkingType);
        }
        if (
          data.netlinkingFilterCache.netlinkingContactFilter !== undefined ||
          data.netlinkingFilterCache.netlinkingContactFilter !== null
        ) {
          setNetlinkingContactFilter(
            data.netlinkingFilterCache.netlinkingContactFilter
          );
        }
        if (
          data.netlinkingFilterCache.netlinkingLanguage !== undefined ||
          data.netlinkingFilterCache.netlinkingLanguage !== null
        ) {
          setNetlinkingLanguage(data.netlinkingFilterCache.netlinkingLanguage);
        }
        if (
          data.netlinkingFilterCache.netlinkingTheme !== undefined ||
          data.netlinkingFilterCache.netlinkingTheme !== null
        ) {
          setNetlinkingTheme(data.netlinkingFilterCache.netlinkingTheme);
        }
        if (
          data.netlinkingFilterCache.minTFValue !== undefined &&
          data.netlinkingFilterCache.maxTFValue !== undefined
        ) {
          const tfMinValue = data.netlinkingFilterCache.minTFValue
            ? data.netlinkingFilterCache.minTFValue
            : 0;

          const tfMaxValue = data.netlinkingFilterCache.maxTFValue
            ? data.netlinkingFilterCache.maxTFValue
            : Infinity;

          setTFValues([
            tfMinValue,
            tfMaxValue === Infinity && tfMinValue === 0 ? 0 : tfMaxValue,
          ]);
          setMinTFValue(tfMinValue);
          setMaxTFValue(
            tfMaxValue === Infinity && tfMinValue === 0 ? 0 : tfMaxValue
          );
        }

        if (
          data.netlinkingFilterCache.minKwValue !== undefined &&
          data.netlinkingFilterCache.maxKwValue !== undefined
        ) {
          const kwMinValue = data.netlinkingFilterCache.minKwValue
            ? data.netlinkingFilterCache.minKwValue
            : 0;

          const kwMaxValue = data.netlinkingFilterCache.maxKwValue
            ? data.netlinkingFilterCache.maxKwValue
            : Infinity;
          setKwvalues([
            kwMinValue,
            kwMaxValue === Infinity && kwMinValue === 0 ? 0 : kwMaxValue,
          ]);
          setMinKwValue(kwMinValue);
          setMaxKwValue(
            kwMaxValue === Infinity && kwMinValue === 0 ? 0 : kwMaxValue
          );
        }

        if (
          data.netlinkingFilterCache.minTraficValue !== undefined &&
          data.netlinkingFilterCache.maxTraficValue !== undefined
        ) {
          const traficMinValue = data.netlinkingFilterCache.minTraficValue
            ? data.netlinkingFilterCache.minTraficValue
            : 0;

          const traficMaxValue = data.netlinkingFilterCache.maxTraficValue
            ? data.netlinkingFilterCache.maxTraficValue
            : Infinity;
          setTraficValues([
            traficMinValue,
            traficMaxValue === Infinity && traficMinValue === 0
              ? 0
              : traficMaxValue,
          ]);
          setMinTraficValue(traficMinValue);
          setMaxTraficValue(
            traficMaxValue === Infinity && traficMinValue === 0
              ? 0
              : traficMaxValue
          );
        }

        if (
          data.netlinkingFilterCache.minTarifHRValue !== undefined &&
          data.netlinkingFilterCache.maxTarifHRValue !== undefined
        ) {
          const tarifHrMinValue = data.netlinkingFilterCache.minTarifHRValue
            ? data.netlinkingFilterCache.minTarifHRValue
            : 0;

          const tarifHrMaxValue = data.netlinkingFilterCache.maxTarifHRValue
            ? data.netlinkingFilterCache.maxTarifHRValue
            : Infinity;
          setTarifHRValues([
            tarifHrMinValue,
            tarifHrMaxValue === Infinity && tarifHrMinValue === 0
              ? 0
              : tarifHrMaxValue,
          ]);
          setMinTarifHRValue(tarifHrMinValue);
          setMaxTarifHRValue(
            tarifHrMaxValue === Infinity && tarifHrMinValue === 0
              ? 0
              : tarifHrMaxValue
          );
        }

        if (
          data.netlinkingFilterCache.minTarifRCValue !== undefined &&
          data.netlinkingFilterCache.maxTarifRCValue !== undefined
        ) {
          const tarifRcMinValue = data.netlinkingFilterCache.minTarifRCValue
            ? data.netlinkingFilterCache.minTarifRCValue
            : 0;

          const tarifRcMaxValue = data.netlinkingFilterCache.maxTarifRCValue
            ? data.netlinkingFilterCache.maxTarifRCValue
            : Infinity;
          setTarifRCValues([
            tarifRcMinValue,
            tarifRcMaxValue === Infinity && tarifRcMinValue === 0
              ? 0
              : tarifRcMaxValue,
          ]);
          setMinTarifRCValue(tarifRcMinValue);
          setMaxTarifRCValue(
            tarifRcMaxValue === Infinity && tarifRcMinValue === 0
              ? 0
              : tarifRcMaxValue
          );
        }
        if (
          data.netlinkingFilterCache.nameSearch !== undefined ||
          data.netlinkingFilterCache.nameSearch !== null
        ) {
          setNameSearch(data.netlinkingFilterCache.nameSearch);
        }
      }
      localStorage.setItem('netlinkingFilterCache', null);
      localStorage.removeItem('netlinkingFilterCache');
    }
  }, [netlinkingList]);

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Français';
        break;
      case 1:
        result = 'English';
        break;
      case 2:
        result = 'Deutsch';
        break;
      case 3:
        result = 'Español';
        break;
      case 4:
        result = 'Italiano';
        break;
      case 5:
        result = 'Português';
        break;
      default:
        result = 'Français';
        break;
    }

    return result;
  }

  const handleValueNetlinkingContactFilter = (value) => {
    setNetlinkingContactFilter(value);
  };
  useEffect(() => {
    searchFunction();
  }, [
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    netlinkingContactFilter,
  ]);
  useEffect(() => {
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setFilteringLoading(true);
    }
    setFilteringLoading(true);

    window.dispatchEvent(new Event('locationchange'));
    axiosPixLink.get('netlinking/all-netlinkings').then((res) => {
      setNetlinkingList(res.data);
      setNetlinkingFilteredList(res.data);
    });
    /* setTimeout(() => {*/
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setShowTable(true);
    }
    setTimeout(() => {
      setFilteringLoading(false);
    }, '1000');
    /*  }, '100');*/
  }, []);
  return (
    <div>
      <div className="content-center">
        <div className="box-header-top">
          <span
            onClick={() => {
              setShowTable(false);
            }}
            className="bt-circle me-3"
          />
          {showTable &&
            (filteringLoading ? (
              <h1>{'XXX' + ' ' + t('Spot(s) trouvé(s)')}</h1>
            ) : (
              <h1>
                {netlinkingFilteredList.length === 0
                  ? '0' + ' ' + t('Spot(s) trouvé(s)')
                  : netlinkingFilteredList.length +
                  ' ' +
                  t('Spot(s) trouvé(s)')}
              </h1>
            ))}
          {!showTable && <h1>{t('Moteur de recherche Netlinking')}</h1>}
        </div>
        {showTable && filteringLoading && <Loader />}
        {!showTable && (
          <div className="row">
            <div className="box-img-bg bg-2" />
            <div className="box-img-bg bg-1" />
            <div className="col-12 col-md-12 box-screen">
              <div className="box-img-bg bg-3" />
              <div className="box-wrapper">
                <div className="header-title">
                  {t('Paramétres de recherche')}
                </div>
                <div className="row row-mb">
                  <div className="label-title">{t('Type de spots')} :</div>
                  <TypeCheckBoxGroup
                    onCheckboxChange={handleTypeCheckboxChange}
                    initialStateString={netlinkingType}
                  />
                </div>

                <div className="row row-mb">
                  <div className="col-12 col-md-6">
                    <div className="label-title">{t('Thématique')} :</div>
                    <ThemeSearchField
                      onThemeChange={handleThemeChange}
                      highlightedOptions={netlinkingTheme}
                    />
                  </div>
                </div>
                <div className="theme-list-container-filter">
                  {netlinkingTheme.map((theme, index) => (
                    <span key={index} className="theme-container">
                      <span>{theme}</span>
                      <button onClick={() => removeTheme(theme)}>x</button>
                    </span>
                  ))}
                </div>
                <div className="row row-mb">
                  <div className="label-title">{t('Langue')} :</div>
                  <LanguageCheckBoxGroup
                    onCheckboxChange={handleLanguageCheckboxChange}
                    initialStateString={netlinkingLanguage}
                  />
                </div>
                <div class="row row-mb">
                  <div class="label-title">
                    <span class="label-title-filtre">
                      {t('Filtre avancé')} :
                    </span>

                    <CheckBox
                      name="Checkbox 1"
                      tick={activeAdvancedFilter}
                      onCheck={handleActiveAdvancedFilter}
                    />
                  </div>
                </div>

                {activeAdvancedFilter && (
                  <div>
                    <div className="row row-mb">
                      {/* First Slider */}
                      <SliderComponent
                        SliderName={'TF'}
                        values={TFValues}
                        handleSliderChange={handleSliderTFChange}
                        minValue={minTFValue}
                        maxValue={maxTFValue}
                        handleMinInputChange={handleMinTFInputChange}
                        handleMaxInputChange={handleMaxTFInputChange}
                        SliderLabel={'TF'}
                        SliderMaximusGlobal={getMaxAttributeValue(
                          netlinkingList,
                          'tF'
                        )}
                      />

                      {/* Second Slider */}

                      <SliderComponent
                        SliderName={t('Trafic')}
                        values={TraficValues}
                        handleSliderChange={handleSliderTraficChange}
                        minValue={minTraficValue}
                        maxValue={maxTraficValue}
                        handleMinInputChange={handleMinTraficInputChange}
                        handleMaxInputChange={handleMaxTraficInputChange}
                        SliderLabel={'Trafic'}
                        SliderMaximusGlobal={getMaxAttributeValue(
                          netlinkingList,
                          'trafic'
                        )}
                      />

                      {/* Third Slider */}
                      <SliderComponent
                        SliderName={t('Mots clés positionnés')}
                        values={Kwvalues}
                        handleSliderChange={handleSliderChange}
                        minValue={minKwValue}
                        maxValue={maxKwValue}
                        handleMinInputChange={handleMinInputChange}
                        handleMaxInputChange={handleMaxInputChange}
                        SliderLabel={'keywords positioned'}
                        SliderMaximusGlobal={getMaxAttributeValue(
                          netlinkingList,
                          'kwPositioned'
                        )}
                      />
                    </div>
                    <div className="row row-mb">
                      <SliderComponent
                        SliderName={t('Tarif') + ' RC'}
                        values={TarifRCValues}
                        handleSliderChange={handleSliderTarifRCChange}
                        minValue={minTarifRCValue}
                        maxValue={maxTarifRCValue}
                        handleMinInputChange={handleMinTarifRCInputChange}
                        handleMaxInputChange={handleMaxTarifRCInputChange}
                        SliderLabel={'Tarif RC'}
                        SliderMaximusGlobal={getMaxAttributeValue(
                          netlinkingList,
                          'tarifRc'
                        )}
                      />{' '}
                      <SliderComponent
                        SliderName={t('Tarif') + ' HR'}
                        values={TarifHRValues}
                        handleSliderChange={handleSliderTarifHRChange}
                        minValue={minTarifHRValue}
                        maxValue={maxTarifHRValue}
                        handleMinInputChange={handleMinTarifHRInputChange}
                        handleMaxInputChange={handleMaxTarifHRInputChange}
                        SliderLabel={'Tarif HR'}
                        SliderMaximusGlobal={getMaxAttributeValue(
                          netlinkingList,
                          'tarifHr'
                        )}
                      />
                      <div className="box-button-bt">
                        <div>
                          <button
                            className="bt-submit-y"
                            onClick={resetFunction}
                          >
                            {t('Réinitialiser')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="box-button-bt">
                <div>
                  <button className="bt-submit-y" onClick={runSearchFunction}>
                    {t('Chercher')}
                  </button>
                </div>
              </div>
              {true && (
                <div className="box-button-bt-add">
                  <div>
                    <button
                      className="bt-submit-y"
                      onClick={() => history.push(`/SuperAdmin/CreerSpot`)}
                    >
                      {t('Ajouter')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showTable && (
          <div>
            <div className="input-container-excel">
              <div className="input-group input-cs w-80">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Rechercher par nom de domaine')}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center item-per-table">
                <span className="me-2">{t('Afficher les résultats')}</span>
                <div className="dropdown" style={{ width: '70px' }}>
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {ordersPerPage}

                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {pageNbOptions
                      .filter((nb) => nb !== ordersPerPage)
                      .map((item, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => setOrdersPerPage(item)}
                          >
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div
                className={
                  netlinkingLanguage !== '' ||
                    netlinkingType !== '' ||
                    netlinkingTheme.length > 0 ||
                    TFValues[0] !== 0 ||
                    TFValues[1] !== 0 ||
                    TraficValues[0] !== 0 ||
                    TraficValues[1] !== 0 ||
                    Kwvalues[0] !== 0 ||
                    Kwvalues[1] !== 0 ||
                    TarifRCValues[0] !== 0 ||
                    TarifRCValues[1] !== 0 ||
                    TarifHRValues[0] !== 0 ||
                    TarifHRValues[1] !== 0
                    ? 'bt-filter-circle-selected vertical-col-filter'
                    : 'bt-filter-circle vertical-col-filter-not-selected'
                }
                onClick={() => {
                  if (
                    TFValues[0] !== 0 ||
                    TFValues[1] !== 0 ||
                    TraficValues[0] !== 0 ||
                    TraficValues[1] !== 0 ||
                    Kwvalues[0] !== 0 ||
                    Kwvalues[1] !== 0 ||
                    TarifRCValues[0] !== 0 ||
                    TarifRCValues[1] !== 0 ||
                    TarifHRValues[0] !== 0 ||
                    TarifHRValues[1] !== 0
                  ) {
                    setActiveAdvancedFilter(true);
                  } else {
                    setActiveAdvancedFilter(false);
                  }

                  setPopupOpen(!popupOpen);
                }}
              ></div>

              {showTable && netlinkingList && netlinkingList.length > 0 && (
                <ExcelExportButton
                  netlinkingData={
                    nameSearch?.length > 0
                      ? netlinkingFilteredList.filter((item) =>
                        item.domain.includes(nameSearch)
                      )
                      : netlinkingFilteredList
                  }
                  netlinkingFilteredListData={
                    nameSearch?.length > 0
                      ? netlinkingFilteredList.filter((item) =>
                        item.domain.includes(nameSearch)
                      )
                      : netlinkingFilteredList
                  }
                  netlinkingExportList={
                    dataExcelToExport.length > 0
                      ? dataExcelToExport
                      : nameSearch?.length > 0
                        ? netlinkingFilteredList.filter((item) =>
                          item.domain.includes(nameSearch)
                        )
                        : netlinkingFilteredList
                  }
                ></ExcelExportButton>
              )}
            </div>
            {popupOpen && (
              <Modal
                isOpen={popupOpen}
                toggle={() => setPopupOpen(!popupOpen)}
                backdrop={true}
              >
                <div>
                  <div className="col-12 col-md-12 box-screen">
                    <div className="box-img-bg bg-3" />
                    <div className="box-wrapper">
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            TFValues[0] !== 0 ||
                            TFValues[1] !== 0 ||
                            TraficValues[0] !== 0 ||
                            TraficValues[1] !== 0 ||
                            Kwvalues[0] !== 0 ||
                            Kwvalues[1] !== 0 ||
                            TarifRCValues[0] !== 0 ||
                            TarifRCValues[1] !== 0 ||
                            TarifHRValues[0] !== 0 ||
                            TarifHRValues[1] !== 0
                          ) {
                            setActiveAdvancedFilter(true);
                          } else {
                            setActiveAdvancedFilter(false);
                          }
                          setPopupOpen(false);
                        }}
                        className="btn-close btnClose"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="header-title">
                        {t('Paramétres de recherche')}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">
                          {t('Type de spots')} :
                        </div>
                        <TypeCheckBoxGroup
                          onCheckboxChange={handleTypeCheckboxChange}
                          initialStateString={netlinkingType}
                        />
                      </div>

                      <div className="row row-mb">
                        <div className="col-12 col-md-6">
                          <div className="label-title">{t('Thématique')} :</div>
                          <ThemeSearchField
                            onThemeChange={handleThemeChange}
                            highlightedOptions={netlinkingTheme}
                          />
                        </div>
                      </div>
                      <div className="theme-list-container-filter">
                        {netlinkingTheme.map((theme, index) => (
                          <span key={index} className="theme-container">
                            <span>{theme}</span>
                            <button onClick={() => removeTheme(theme)}>
                              x
                            </button>
                          </span>
                        ))}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">{t('Langue')} :</div>
                        <LanguageCheckBoxGroup
                          onCheckboxChange={handleLanguageCheckboxChange}
                          initialStateString={netlinkingLanguage}
                        />
                      </div>
                      <div class="row row-mb">
                        <div class="label-title">
                          <span class="label-title-filtre">
                            {t('Filtre avancé')} :
                          </span>

                          <CheckBox
                            name="Checkbox 1"
                            tick={activeAdvancedFilter}
                            onCheck={handleActiveAdvancedFilter}
                          />
                        </div>
                      </div>

                      {activeAdvancedFilter && (
                        <div>
                          <div className="row row-mb">
                            {/* First Slider */}
                            <SliderComponent
                              SliderName={'TF'}
                              values={TFValues}
                              handleSliderChange={handleSliderTFChange}
                              minValue={minTFValue}
                              maxValue={maxTFValue}
                              handleMinInputChange={handleMinTFInputChange}
                              handleMaxInputChange={handleMaxTFInputChange}
                              SliderLabel={'TF'}
                              SliderMaximusGlobal={getMaxAttributeValue(
                                netlinkingList,
                                'tF'
                              )}
                            />

                            {/* Second Slider */}

                            <SliderComponent
                              SliderName={t('Trafic')}
                              values={TraficValues}
                              handleSliderChange={handleSliderTraficChange}
                              minValue={minTraficValue}
                              maxValue={maxTraficValue}
                              handleMinInputChange={handleMinTraficInputChange}
                              handleMaxInputChange={handleMaxTraficInputChange}
                              SliderLabel={'Trafic'}
                              SliderMaximusGlobal={getMaxAttributeValue(
                                netlinkingList,
                                'trafic'
                              )}
                            />

                            {/* Third Slider */}
                            <SliderComponent
                              SliderName={t('Mots clés positionnés')}
                              values={Kwvalues}
                              handleSliderChange={handleSliderChange}
                              minValue={minKwValue}
                              maxValue={maxKwValue}
                              handleMinInputChange={handleMinInputChange}
                              handleMaxInputChange={handleMaxInputChange}
                              SliderLabel={'keywords positioned'}
                              SliderMaximusGlobal={getMaxAttributeValue(
                                netlinkingList,
                                'kwPositioned'
                              )}
                            />
                          </div>
                          <div className="row row-mb">
                            <SliderComponent
                              SliderName={t('Tarif') + ' RC'}
                              values={TarifRCValues}
                              handleSliderChange={handleSliderTarifRCChange}
                              minValue={minTarifRCValue}
                              maxValue={maxTarifRCValue}
                              handleMinInputChange={handleMinTarifRCInputChange}
                              handleMaxInputChange={handleMaxTarifRCInputChange}
                              SliderLabel={'Tarif RC'}
                              SliderMaximusGlobal={getMaxAttributeValue(
                                netlinkingList,
                                'tarifRc'
                              )}
                            />{' '}
                            <SliderComponent
                              SliderName={t('Tarif') + ' HR'}
                              values={TarifHRValues}
                              handleSliderChange={handleSliderTarifHRChange}
                              minValue={minTarifHRValue}
                              maxValue={maxTarifHRValue}
                              handleMinInputChange={handleMinTarifHRInputChange}
                              handleMaxInputChange={handleMaxTarifHRInputChange}
                              SliderLabel={'Tarif HR'}
                              SliderMaximusGlobal={getMaxAttributeValue(
                                netlinkingList,
                                'tarifHr'
                              )}
                            />
                          </div>
                        </div>
                      )}
                      <div className="box-button-bt">
                        <div style={{ margin: '20px' }}>
                          <button
                            className="bt-submit-y"
                            onClick={resetFunction}
                          >
                            {t('Réinitialiser')}
                          </button>
                        </div>
                        <div>
                          <button
                            className="bt-submit-y"
                            onClick={runSearchFunction}
                          >
                            {t('Chercher')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            {(netlinkingLanguage !== '' ||
              netlinkingType !== '' ||
              netlinkingTheme.length > 0 ||
              TFValues[0] !== 0 ||
              TFValues[1] !== 0 ||
              TraficValues[0] !== 0 ||
              TraficValues[1] !== 0 ||
              Kwvalues[0] !== 0 ||
              Kwvalues[1] !== 0 ||
              TarifRCValues[0] !== 0 ||
              TarifRCValues[1] !== 0 ||
              TarifHRValues[0] !== 0 ||
              TarifHRValues[1] !== 0 ||
              netlinkingContactFilter != '') && (
                <div class="bright-yellow-div">
                  {netlinkingType !== '' && (
                    <>
                      <b>{t('Type de spots')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {t(netlinkingType)}
                        </span>
                        <button
                          onClick={() => {
                            setNetlinkingType('');
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingLanguage !== '' && (
                    <>
                      <b>{t('Langue')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {t(getLanguageNameById(netlinkingLanguage))}
                        </span>
                        <button
                          onClick={() => {
                            removeLanguageFilter();
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingTheme.length > 0 && (
                    <>
                      <b>{t('Thématique')}:</b>{' '}
                      {netlinkingTheme.map((theme, index) => (
                        <span
                          key={index}
                          className="theme-container click-pointer"
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          <span>{theme}</span>
                          <button
                            onClick={(e) => {
                              removeTheme(theme);
                              e.stopPropagation();
                            }}
                          >
                            x
                          </button>
                        </span>
                      ))}
                    </>
                  )}
                  {(TFValues[0] !== 0 || TFValues[1] !== 0) && (
                    <>
                      <b>TF:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TFValues[0]}-{TFValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTFValues([0, 0]);
                            setMinTFValue(0);
                            setMaxTFValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TraficValues[0] !== 0 || TraficValues[1] !== 0) && (
                    <>
                      <b>{t('Trafic')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TraficValues[0]}-{TraficValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTraficValues([0, 0]);
                            setMinTraficValue(0);
                            setMaxTraficValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(Kwvalues[0] !== 0 || Kwvalues[1] !== 0) && (
                    <>
                      <b>{t('Mots clés positionnés')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {Kwvalues[0]}-{Kwvalues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setKwvalues([0, 0]);
                            setMinKwValue(0);
                            setMaxKwValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TarifRCValues[0] !== 0 || TarifRCValues[1] !== 0) && (
                    <>
                      <b>{t('Tarif')} RC:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TarifRCValues[0]}-{TarifRCValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTarifRCValues([0, 0]);
                            setMinTarifRCValue(0);
                            setMaxTarifRCValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TarifHRValues[0] !== 0 || TarifHRValues[1] !== 0) && (
                    <>
                      <b>{t('Tarif')} RH:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TarifHRValues[0]}-{TarifHRValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTarifHRValues([0, 0]);
                            setMinTarifHRValue(0);
                            setMaxTarifHRValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingContactFilter !== '' && (
                    <>
                      <b>{t('Contact')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            if (
                              TFValues[0] !== 0 ||
                              TFValues[1] !== 0 ||
                              TraficValues[0] !== 0 ||
                              TraficValues[1] !== 0 ||
                              Kwvalues[0] !== 0 ||
                              Kwvalues[1] !== 0 ||
                              TarifRCValues[0] !== 0 ||
                              TarifRCValues[1] !== 0 ||
                              TarifHRValues[0] !== 0 ||
                              TarifHRValues[1] !== 0
                            ) {
                              setActiveAdvancedFilter(true);
                            } else {
                              setActiveAdvancedFilter(false);
                            }
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {netlinkingContactFilter}
                        </span>
                        <button
                          onClick={() => {
                            removeNetlinkingContactFilter();
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                </div>
              )}

            <NetlinkingTable
              shownNetlinkingFilteredList={netlinkingFilteredList}
              nameSearch={nameSearch}
              onNetlinkingContactFilterChange={
                handleValueNetlinkingContactFilter
              }
              contactFilter={netlinkingContactFilter}
              sendNetlinkingExport={handleDataExcelToExport}
              ordersPerPage={ordersPerPage}
              netlinkingFilterCache={{
                netlinkingType: netlinkingType,
                netlinkingLanguage: netlinkingLanguage,
                nameSearch: nameSearch,
                netlinkingTheme: netlinkingTheme,
                netlinkingContactFilter: netlinkingContactFilter,
                minKwValue: minKwValue,
                maxKwValue: maxKwValue,
                minTFValue: minTFValue,
                maxTFValue: maxTFValue,
                minTraficValue: minTraficValue,
                maxTraficValue: maxTraficValue,
                minTarifHRValue: minTarifHRValue,
                maxTarifHRValue: maxTarifHRValue,
                minTarifRCValue: minTarifRCValue,
                maxTarifRCValue: maxTarifRCValue,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
