export const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%+=*§µ&]).{8,20}$/;

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const PHONENUMBER_REGEX = /^[0-9.,+-]*$/;

export const FLOAT_REGEX = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;

export const INT_REGEX = /^[0-9]+[0-9]*$/;
