import { createSlice } from '@reduxjs/toolkit';
import { fetchClientList } from './features';

export const consultantClientList = createSlice({
  name: 'consultantClientList',
  initialState: {
    clients: null,
    isLoadingClients: true,
    isError: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientList.fulfilled, (state, { payload }) => {
        state.clients = payload;
        state.isLoadingClients = false;
        state.isError = false;
      })
      .addCase(fetchClientList.pending, (state) => {
        state.isLoadingClients = true;
        state.isError = false;
      })
      .addCase(fetchClientList.rejected, (state) => {
        state.isLoadingClients = false;
        state.isError = true;
      });
  },
});

export default consultantClientList.reducer;

export const consultantClientListSelector = (state) =>
  state.consultantClientList;
