import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPixLink from 'config/axiosPixLink';
import axiosPrivate from 'config/axiosPrivate';

export const fetchClientList = createAsyncThunk(
  'adminClientList/fetchClientList',
  async (arg, thunkAPI) => {
    try {
      const response = await axiosPixLink.get('/website/all');
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
