import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const ErrorComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div style={{ padding: '40px 15px', textAlign: 'center' }}>
            <div className="box-header-top">
              <span onClick={history.goBack} className="bt-circle me-3" />
              <h1>Oops</h1>
            </div>
            <div
              style={{
                padding: '15px 15px',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  marginBottom: '15px',
                }}
              >
                <img src="/Images/modal/img-confirm.png" alt="confirm " />
              </div>
              {t("Oups....! Une erreur s'est produite")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
