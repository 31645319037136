import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { addUser, updateUser } from 'store/Admin/UserManagement';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { classes } from 'components/shared/modal/modalClasses';

import { PWD_REGEX, EMAIL_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { PIXLINK_ROLES } from 'utils/Constants';
import axiosPixLink from 'config/axiosPixLink';

const API = {
  createConsultant: '/user/create',
  updateConsultant: '/user/update',
};

export const ConsultantModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mail, setMail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [disabledForm, setDisabledForm] = useState(true);

  useEffect(() => {
    if (props && props.user) {
      setFirstName(props.user.firstName);
      setLastName(props.user.lastName);
      setMail(props.user.email);
      setPhoneNumber(props.user.phoneNumber);
    }
  }, [props]);

  const checkPasswordValid = useCallback(() => {
    return !password || PWD_REGEX.test(password);
  }, [password]);

  const checkValidForm = useCallback(() => {
    if (!mail || !phoneNumber || !firstName || !lastName) {
      setDisabledForm(true);
      return;
    }

    if (!mail.match(EMAIL_REGEX)) {
      setDisabledForm(true);
      return;
    }

    return props.user
      ? setDisabledForm(!checkPasswordValid())
      : setDisabledForm(false);
  }, [checkPasswordValid, firstName, lastName, mail, phoneNumber, props.user]);

  useEffect(() => {
    checkValidForm();
  }, [mail, phoneNumber, password, firstName, lastName, checkValidForm]);

  const updateConsultant = () => {
    let body = {
      password: !password ? null : password,
      firstName,
      lastName,
      phoneNumber,
      email: mail,
      role: PIXLINK_ROLES.CONSULTANT,
    };

    axiosPixLink
      .put(API.updateConsultant, body, {
        params: { mail: props.user.email },
      })
      .then((res) => {
        dispatch(updateUser({ ...res.data }));
        setIsSuccess(true);

        setErrorSuccessMsg(
          `${t('Le Consultant')} ${props.user.email} ${t(
            'a été mis à jour avec succès'
          )} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const createConsultant = () => {
    let body = {
      lastName,
      firstName,
      phoneNumber,
      email: mail,
      role: PIXLINK_ROLES.CONSULTANT,
    };

    axiosPixLink
      .post(API.createConsultant, body)
      .then((res) => {
        dispatch(addUser({ ...res.data, role: PIXLINK_ROLES.CONSULTANT }));
        setIsSuccess(true);

        setErrorSuccessMsg(
          `${t('Le Consultant')} ${mail} ${t('a été créé avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  if (props.user)
    return (
      <div>
        <Modal style={classes.modalCustom} isOpen={props.open}>
          <div style={classes.modalHeader} className="modal-header">
            <h5 style={classes.title} className="modal-title">
              {t('Modifier le Consultant')}
            </h5>
            <button
              type="button"
              onClick={props.handleClose}
              style={classes.btnClose}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div style={classes.modalPad} className="modal-body">
            <div className="row row-mb-lg">
              <div className="col-12 col-md-6">
                <div className="label-title"> {t('Nom')}</div>
                <div>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control form-ct"
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="label-title">{t('Prénom')}</div>
                <div>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-control form-ct"
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-12">
                <div className="label-title">{t('E-mail')}</div>
                <div>
                  <input
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    type="text"
                    className="form-control form-ct"
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-6">
                <div className="label-title">{t('Téléphone')}</div>
                <div>
                  <input
                    value={phoneNumber}
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        PHONENUMBER_REGEX.test(e.target.value)
                      ) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    type="text"
                    className="form-control form-ct"
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-12">
                <div className="label-title">{t('Mot de passe')}*</div>
                <div className="input-group input-password">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="input-group-text"
                  >
                    {showPassword ? (
                      <img
                        src="/Images/icon-eye-black.png"
                        alt="icon-eye-black"
                      />
                    ) : (
                      <img
                        src="/Images/icon-eye-gray.png"
                        alt="icon-eye-gray"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="text-more mt-2">
                *
                {t(
                  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                )}
              </div>
            </div>
            <div className="box-button-bt">
              <div>
                {disabledForm ? (
                  <button className="bt-submit-disabled-y float-end">
                    {' '}
                    {t('Sauvegarder')}{' '}
                  </button>
                ) : (
                  <button
                    className="bt-submit-y float-end"
                    onClick={updateConsultant}
                  >
                    {' '}
                    {t('Sauvegarder')}{' '}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>

        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() => {
            if (isSuccess === true) props.handleClose();
            setErrorSuccess(false);
          }}
          success={isSuccess}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />
      </div>
    );

  return (
    <div>
      <Modal style={classes.modalCustom} isOpen={props.open}>
        <div style={classes.modalHeader} className="modal-header">
          <h5 style={classes.title} className="modal-title">
            {t('Ajouter un Consultant')}
          </h5>
          <button
            type="button"
            onClick={props.handleClose}
            style={classes.btnClose}
            className="btn-close"
          />
        </div>
        <div style={classes.modalPad} className="modal-body">
          <div className="row row-mb-lg">
            <div className="col-12 col-md-6">
              <div className="label-title">{t('Nom')}</div>
              <div>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-control form-ct"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="label-title">{t('Prénom')}</div>
              <div>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-control form-ct"
                />
              </div>
            </div>
          </div>
          <div className="row row-mb-lg">
            <div className="col-12 col-md-12">
              <div className="label-title">{t('E-mail')}</div>
              <div>
                <input
                  type="text"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  className="form-control form-ct"
                />
              </div>
            </div>
          </div>
          <div className="row row-mb-lg">
            <div className="col-12 col-md-6">
              <div className="label-title">{t('Téléphone')}</div>
              <div>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => {
                    if (
                      e.target.value === '' ||
                      PHONENUMBER_REGEX.test(e.target.value)
                    ) {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                  className="form-control form-ct"
                />
              </div>
            </div>
          </div>

          <div className="box-button-bt">
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y float-end">
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              ) : (
                <button
                  className="bt-submit-y float-end"
                  onClick={createConsultant}
                >
                  {' '}
                  {t('Sauvegarder')}{' '}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          if (isSuccess === true) props.handleClose();
          setErrorSuccess(false);
        }}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
};

export default ConsultantModal;
