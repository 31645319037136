import { useState, useEffect } from 'react';
import CheckBox from 'components/shared/CheckBox';
import { useTranslation } from 'react-i18next';
import { LanguageSearchField } from '../AutoComplete/languageAutoComplete';
export default function ResponsableCheckBox({
  onCheckboxChange,
  initialState,
  initialStateString,
}) {
  const { t } = useTranslation();
  //set order type
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  function handleCheckbox1Change() {
    setIsChecked1(!isChecked1);
    setIsChecked2(false);
    onCheckboxChange({
      checkbox1: !isChecked1,
      checkbox2: false,
    });
  }

  function handleCheckbox2Change() {
    setIsChecked1(false);
    setIsChecked2(!isChecked2);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: !isChecked2,
    });
  }

  useEffect(() => {
    setIsChecked1(initialStateString === 'Paris');
    setIsChecked2(initialStateString === 'Thailand');
  }, [initialStateString]);
  useEffect(() => {}, [initialStateString]);

  return (
    <div className="col-12 col-md-12 d-flex justify-content-between justify-content-md-between align-items-center flex-column flex-md-row">
      <div className="col-12 col-md-1 d-flex align-items-center">
        <div className="label-title me-3 label-choice"> {t('Paris')}</div>
        <CheckBox
          name="Checkbox 1"
          tick={initialStateString === 'Paris'}
          onCheck={handleCheckbox1Change}
        />
      </div>
      <div className="col-12 col-md-1 d-flex align-items-center">
        <div className="label-title me-3 label-choice"> {t('Thailand')}</div>

        <CheckBox
          name="Checkbox 2"
          tick={initialStateString === 'Thailand'}
          onCheck={handleCheckbox2Change}
        />
      </div>
    </div>
  );
}
