import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchWebsiteDisplay = createAsyncThunk(
  'consultantWebsiteDetails/fetchWebsiteDisplay',
  async (account, thunkAPI) => {
    try {
      if (!account) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get(
        '/user/myWebsites/byAccount/' + account
      );
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchWebsiteOrdersList = createAsyncThunk(
  'consultantWebsiteDetails/fetchWebsiteOrderList',
  async (account, thunkAPI) => {
    try {
      if (!account) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/commands/byAccount/' + account);
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
