import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminUserManagement } from './features';

export const adminUserManagement = createSlice({
  name: 'adminUserManagement',
  initialState: {
    userList: [],
    isLoading: true,
    isError: true,
  },
  reducers: {
    removeUser: (state, action) => {
      state.userList = state.userList.filter(
        (item) => item.email !== action.payload
      );
    },
    addUser: (state, action) => {
      state.userList = [action.payload, ...state.userList];
    },
    updateUser: (state, action) => {
      const index = state.userList.findIndex(
        (user) => user.email === action.payload.email
      );
      if (index > -1) {
        const newArray = [...state.userList];
        newArray[index] = action.payload;
        state.userList = [...newArray];
      }
    },
    userActivation: (state, { payload }) => {
      const index = state.userList.findIndex(
        (user) => user.email === payload.mail
      );
      if (index > -1) {
        const newArray = [...state.userList];
        let object = { ...newArray[index] };
        object[payload.key] = !payload.value;
        newArray[index] = { ...object };
        state.userList = [...newArray];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminUserManagement.fulfilled, (state, { payload }) => {
        state.userList = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAdminUserManagement.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAdminUserManagement.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default adminUserManagement.reducer;

export const adminUserManagementSelector = (state) => state.adminUserManagement;

export const { removeUser, addUser, updateUser, userActivation } =
  adminUserManagement.actions;
