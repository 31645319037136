import { createSlice } from '@reduxjs/toolkit';
import { fetchWebsiteOrdersList } from './features';

export const writerOrderDisplayByClient = createSlice({
  name: 'writerOrderDisplayByClient',
  initialState: {
    formData: null,
    urlSite: '',
    consultant: null,
    ordersList: [],
    isLoading: true,
    isError: true,
    isListLoading: true,
    isListError: true,
  },
  reducers: {
    setUrlSiteForDisplay: (state, action) => {
      state.urlSite = action.payload;
    },
    setConsultantForDisplay: (state, action) => {
      state.consultant = [action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteOrdersList.fulfilled, (state, { payload }) => {
        state.ordersList = payload;
        state.isListLoading = false;
        state.isListError = false;
      })
      .addCase(fetchWebsiteOrdersList.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
      })
      .addCase(fetchWebsiteOrdersList.rejected, (state) => {
        state.isListLoading = false;
        state.isListError = true;
      });
  },
});

export default writerOrderDisplayByClient.reducer;

export const { setUrlSiteForDisplay, setConsultantForDisplay } =
  writerOrderDisplayByClient.actions;

export const writerOrderDisplayByClientSelector = (state) =>
  state.writerOrderDisplayByClient;
