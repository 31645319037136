export function removeWWW(url) {
  let index = url.indexOf('www.');
  return index > -1 ? url.slice(index + 'www.'.length) : url;
}

export function removeWhitespace(string) {
  return string.replace(/^\s+|\s+$/g, '');
}

export function lower(string) {
  return string.toLowerCase();
}

export function replaceTextInBold(text, array) {
  let content = text;
  array.map((x) =>
    content.replace(
      new RegExp(`(?<![A-Za-zÀ-ÖØ-öø-ÿ])(${x})(?![A-Za-zÀ-ÖØ-öø-ÿ=])`, 'g'),
      '<strong>' + x + '</strong>'
    )
  );
  while (
    content.includes('<strong><strong>') &&
    content.includes('</strong></strong>')
  ) {
    content = content.replaceAll('<strong><strong>', '<strong>');
    content = content.replaceAll('</strong></strong>', '</strong>');
  }
  return content;
}

export function capitalizeFirstLetter(inputString) {
  // Check if the input is a valid string
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return inputString; // Return the input as is if it's not a valid string
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function getEmailPart(inputString) {
  try {
    // Split the inputString using '<' and '>' as delimiters
    const parts = inputString.split('<');

    // Check if the split produced at least two parts
    if (parts.length >= 2) {
      // Get the second part and remove the trailing '>'
      const emailPart = parts[1].replace('>', '').trim();
      return emailPart;
    } else {
      // If the inputString is not in the expected format, return null or an appropriate value
      return inputString;
    }
  } catch (err) {
    console.error('An error occurred:', err.message);
    return '';
  }
}

export function getNamePart(inputString) {
  try {
    // Split the inputString using '@' as a delimiter
    const emailRegex = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
    let stringWithoutEmail = inputString.replace(emailRegex, '');
    stringWithoutEmail = stringWithoutEmail.replace('>', '');
    stringWithoutEmail = stringWithoutEmail.replace('<', '');
    stringWithoutEmail = stringWithoutEmail.replace('/', '');
    if (stringWithoutEmail.trim().length === 0) {
      return '';
    }
    return stringWithoutEmail.trim();
  } catch (err) {
    console.error('An error occurred:', err.message);
    return '';
  }
}
