import { useTranslation } from 'react-i18next';
import { PIXLINK_PUBLISH_STATUS, PIXLINK_REDAC_STATUS } from 'utils/Constants';

export function getRedactStatusText(status, t) {
  const item = filterRedacStatusMenuList.find((item) => item.status === status);
  return item ? t(item.text) : t('à lancer admin ou super admin');
}

export function getPublishStatusText(status, t) {
  const item = filterPublishStatusMenuList.find(
    (item) => item.status === status
  );
  return item ? t(item.text) : t('à commander');
}

const filterPublishStatusMenuList = [
  {
    text: 'à commander',
    status: PIXLINK_PUBLISH_STATUS.TO_BE_COMMANDED,
  },
  {
    text: 'commandé, en attente de retour',
    status: PIXLINK_PUBLISH_STATUS.COMMAND_IN_PROGRESS,
  },
  {
    text: 'à valider super admin',
    status: PIXLINK_PUBLISH_STATUS.TO_BE_VALIDTED_SUPER_ADMIN,
  },
  {
    text: 'à modifier',
    status: PIXLINK_PUBLISH_STATUS.TO_BE_MODIFIED,
  },
  {
    text: 'publié',
    status: PIXLINK_PUBLISH_STATUS.PUBLISHED,
  },
  { text: 'programmé', status: PIXLINK_PUBLISH_STATUS.PROGRAMMED },
  {
    text: 'refusé éditeur',
    status: PIXLINK_PUBLISH_STATUS.REFUSED_EDITOR,
  },
];

const filterRedacStatusMenuList = [
  {
    text: 'à lancer admin ou super admin',
    status: PIXLINK_REDAC_STATUS.TO_BE_LAUNCHED,
  },
  {
    text: 'en cours de rédaction',
    status: PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS,
  },
  {
    text: 'à valider super admin',
    status: PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN,
  },
  {
    text: 'à valider client ',
    status: PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT,
  },
  {
    text: 'validé client, à publier',
    status: PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED,
  },
  { text: 'publié', status: PIXLINK_REDAC_STATUS.PUBLISHED },
  { text: 'à modifier', status: PIXLINK_REDAC_STATUS.TO_BE_MODIFIED },
  {
    text: 'en cours de repasse',
    status: PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS,
  },
];

export const getIconUrl = (option) => {
  switch (option) {
    case 'Paris':
      return 'Images/img_flag/FR_flag.png'; // Path to Paris icon
    case 'Thailand':
      return 'Images/img_flag/TH_flag.png'; // Path to Thailand icon
    default:
      return ''; // Default case, no icon
  }
};
