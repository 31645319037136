import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchProjectList = createAsyncThunk(
  'writerProjectList/fetchProjectList',
  async (arg, thunkAPI) => {
    try {
      const response = await axiosPrivate.get('/user/myProjects');
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
