import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchWebsiteDisplay = createAsyncThunk(
  'adminWebsiteDetails/fetchWebsiteDisplay',
  async (urlSite, thunkAPI) => {
    try {
      if (!urlSite) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/clientWebsite', {
        params: {
          url: urlSite,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchWebsiteOrdersList = createAsyncThunk(
  'adminWebsiteDetails/fetchWebsiteOrderList',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/commands/byWebsiteId/' + id);
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
