import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchWriterOrderDisplay = createAsyncThunk(
  'writerOrderDisplay/fetchWriterOrderDisplay',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/commands/' + id);
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchWriterTextOrderDisplay = createAsyncThunk(
  'writerOrderDisplay/fetchWriterTextOrderDisplay',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get(
        '/commands/' + id + '/texts/narrow-details'
      );
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
