import { createSlice } from '@reduxjs/toolkit';
import {
  fetchWriterOrderDisplay,
  fetchWriterTextOrderDisplay,
} from './features';

export const writerOrderDisplay = createSlice({
  name: 'writerOrderDisplay',
  initialState: {
    order: null,
    status: '',
    textList: [],
    remainingTextAmount: 0,
    minWords: null,
    maxWords: null,
    isLoading: true,
    isError: true,
    isTextLoading: false,
    isTextError: false,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    updateTextOfTextList: (state, { payload }) => {
      let array = [...state.textList];
      let index = array.findIndex((text) => text.id === payload.id);
      if (index > -1) {
        array[index][payload.key] = payload.value;
        state.textList = [...array];
      }
    },
  },
  // updateWriterRemainingTextAmount: (state, { payload }) => {
  //   state.remainingTextAmount = state.remainingTextAmount - 1
  // },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWriterOrderDisplay.fulfilled, (state, { payload }) => {
        state.order = payload;
        state.minWords = payload.minWords;
        state.maxWords = payload.maxWords;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchWriterOrderDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchWriterOrderDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchWriterTextOrderDisplay.fulfilled, (state, { payload }) => {
        state.textList = payload.map((text, index) => ({
          index,
          ...text,
        }));
        if (!state.remainingTextAmount) {
          state.remainingTextAmount = 0;
        } else {
          state.remainingTextAmount =
            state.order.textAmount - state.textList.length;
        }
        state.isTextLoading = false;
        state.isTextError = false;
      })
      .addCase(fetchWriterTextOrderDisplay.pending, (state) => {
        state.isTextLoading = true;
        state.isTextError = false;
      })
      .addCase(fetchWriterTextOrderDisplay.rejected, (state) => {
        state.isTextLoading = false;
        state.isTextError = true;
      });
  },
});

export default writerOrderDisplay.reducer;

export const {
  setStatus,
  updateTextOfTextList,
  updateWriterRemainingTextAmount,
} = writerOrderDisplay.actions;

export const writerOrderDisplaySelector = (state) => state.writerOrderDisplay;
