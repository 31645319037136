import { createSlice } from '@reduxjs/toolkit';

export const adminWriterDisplay = createSlice({
  name: 'adminWriterDisplay',
  initialState: {
    writer: null,
    isLoading: true,
    isError: true,
  },
  reducers: {
    selectWriter: (state, action) => {
      state.writer = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
  },
});

export default adminWriterDisplay.reducer;

export const { selectWriter } = adminWriterDisplay.actions;

export const adminWriterDisplaySelector = (state) => state.adminWriterDisplay;
