export function filterOrders(
  orders,
  selectedVerified,
  selectedType,
  titleSearch
) {
  let arr = [...orders];

  if (
    selectedVerified &&
    selectedVerified.text.length > 0 &&
    selectedVerified.text != 'Tous les types'
  ) {
    arr = arr.filter((x) => {
      return (
        (x.toBeVerified == true && selectedVerified.type == true) ||
        (x.toBeVerified == false && selectedVerified.type == false)
      );
    });
  }
  if (
    selectedType &&
    selectedType.text.length > 0 &&
    selectedType.text != 'Tous les types'
  ) {
    arr = arr.filter((x) => {
      return (
        x.type === selectedType.type ||
        (x.type === null && selectedType.type === 'standard')
      );
    });
  }

  if (titleSearch && titleSearch.length > 0) {
    arr = arr.filter((x) => {
      return (
        `${x.idDevis} - ${x.account} - ${x.taskName} - ${x.linksNumbers}`
          .toLowerCase()
          .includes(titleSearch.toLowerCase()) ||
        (x.consultants &&
          x.consultants.some(
            (w) =>
              w?.firstName?.toLowerCase().includes(titleSearch.toLowerCase()) ||
              w?.lastName?.toLowerCase().includes(titleSearch.toLowerCase()) ||
              w?.email?.toLowerCase().includes(titleSearch.toLowerCase())
          ))
      );
    });
  }
  return arr;
}

export function filterBills(orders, titleSearch) {
  let arr = [...orders];
  if (titleSearch && titleSearch.length > 0) {
    arr = arr.filter((x) => {
      return x.commandTitle.toLowerCase().includes(titleSearch.toLowerCase());
    });
  }

  return arr;
}
