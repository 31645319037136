import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from './features';

export const adminOrderDisplay = createSlice({
  name: 'adminOrderDisplay',
  initialState: {
    order: null,
    status: '',
    textList: [],
    orderId: null,
    orderLineList: [],
    remainingTextAmount: 0,
    affectedTextAmount: 0,
    isLoading: true,
    isError: true,
    isTextLoading: false,
    isTextError: false,
    isOrderLineLoading: true,
    isOrderLineError: true,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setOrderLineList: (state, { payload }) => {
      let array = [
        ...payload.map((orderLine) => ({
          ...orderLine,
          deadline: orderLine.deadline.slice(0, 10),
        })),
      ];
      state.orderLineList = [...array];
    },
    updateOrderDetails: (state, { payload }) => {
      state.order = payload;
      state.isLoading = false;
      state.isError = false;
    },

    updateOrderLine: (state, { payload }) => {
      let array = [...state.orderLineList];
      let index = array.findIndex(
        (orderLine) => orderLine.writerCommandId === payload.id
      );
      if (index > -1) {
        array[index][payload.option] = payload.value;
        state.orderLineList = array;
      }
    },
    removeOrderLine: (state, action) => {
      state.orderLineList = state.orderLineList.filter(
        (item) => item.writerCommandId !== action.payload
      );
    },
    updateRemainingTextAmount: (state, { payload }) => {
      state.remainingTextAmount = payload;
    },
    updateAffectedTextAmount: (state, { payload }) => {
      state.affectedTextAmount = payload.reduce(
        (a, b) => parseInt(a) + (parseInt(b['textAmount']) || 0),
        0
      );
    },
    updateTextOfTextList: (state, { payload }) => {
      let array = [...state.textList];
      let index = array.findIndex((text) => text.id === payload.id);
      if (index > -1) {
        array[index][payload.key] = payload.value;
        state.textList = [...array];
      }
    },
    removeTextOfTextList: (state, action) => {
      state.textList = state.textList.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminOrderDisplay.fulfilled, (state, { payload }) => {
        state.order = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAdminOrderDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAdminOrderDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchAdminTextOrderDisplay.fulfilled, (state, { payload }) => {
        state.textList = payload.map((text, index) => ({
          index,
          ...text,
        }));

        state.isTextLoading = false;
        state.isTextError = false;
      })
      .addCase(fetchAdminTextOrderDisplay.pending, (state) => {
        state.isTextLoading = true;
        state.isTextError = false;
      })
      .addCase(fetchAdminTextOrderDisplay.rejected, (state) => {
        state.isTextLoading = false;
        state.isTextError = true;
      })
      .addCase(fetchAdminOrderLineDisplay.fulfilled, (state, { payload }) => {
        state.orderId = payload.commandId;
        state.remainingTextAmount = payload.commandTextAmount;

        state.orderLineList = payload['commandLineDTOs'].map((orderLine) => ({
          ...orderLine,
          deadline: orderLine.deadline.slice(0, 10),
        }));
        state.affectedTextAmount = payload['commandLineDTOs'].reduce(
          (a, b) => parseInt(a) + (parseInt(b['textAmount']) || 0),
          0
        );
        state.isOrderLineLoading = false;
        state.isOrderLineError = false;
      })
      .addCase(fetchAdminOrderLineDisplay.pending, (state) => {
        state.isOrderLineLoading = true;
        state.isOrderLineError = false;
      })
      .addCase(fetchAdminOrderLineDisplay.rejected, (state) => {
        state.isOrderLineLoading = false;
        state.isOrderLineError = true;
      });
  },
});

export default adminOrderDisplay.reducer;

export const adminOrderDisplaySelector = (state) => state.adminOrderDisplay;

export const {
  setStatus,
  setOrderLineList,
  removeOrderLine,
  updateOrderDetails,
  updateOrderLine,
  updateRemainingTextAmount,
  updateAffectedTextAmount,
  updateTextOfTextList,
  removeTextOfTextList,
} = adminOrderDisplay.actions;
