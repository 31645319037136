import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsReact from 'highcharts-react-official';
import { min } from 'lodash';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const RED = '#EE3900';
const ORANGE = '#FF8D4B';
const GREEN = '#43B929';
const GREY = '#E0E0E0';

export const MyCustomHighcharts = (props) => {
  const [options, setOptions] = useState({});
  const [value, setValue] = useState(0);
  const [color, setColor] = useState(GREY);

  useEffect(() => {
    if (props.value >= 0) {
      setValue(props.value.toFixed(2));

      if (props.value >= props.maxValue) {
        setColor(GREEN);
      } else if (props.value >= props.maxValue / 2) {
        setColor(ORANGE);
      } else {
        setColor(RED);
      }
    }
  }, [props]);

  useEffect(() => {
    setOptions({
      chart: {
        type: 'solidgauge',
        margin: [0, 0, 0, 0],
        height: 116,
        width: 116,
        spacing: [0, 0, 0, 0],
      },

      credits: { enabled: false },
      title: {
        text: '',
        style: {
          fontSize: '10px',
        },
      },

      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 395,
        background: [
          {
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: color + '33',
            borderWidth: 0,
          },
        ],
      },

      yAxis: {
        min: 0,
        max: props.maxValue,
        lineWidth: 0,
        tickPositions: [],
      },

      plotOptions: {
        solidgauge: {
          borderWidth: '12px',
          dataLabels: {
            /* enabled: false, */
            y: -19,
            borderWidth: 0,
            useHTML: true,
            formatter: function () {
              return this.point.y;
            },
          },
          style: {
            color: color,
            fontSize: '14px',
            fontWeight: 'bold',
            textOutline: 'transparent',
            fontFamily: 'Proxima Nova Bold',
          },
          linecap: 'round',
          stickyTracking: false,
        },
      },

      series: [
        {
          name: 'Move',
          borderColor: color,
          dataLabels: {
            format:
              '<div class="datalabels-solidgauge" style="text-align:center !important"><span style="font-size:20px;font-weight400;font-family: Poppins, sans-serif;color:' +
              color +
              `">{y}/${props.maxValue}</span><br/>` +
              '</div>',
          },
          data: [
            {
              color: Highcharts.getOptions().colors[0],
              radius: '100%',
              innerRadius: '100%',
              y: parseFloat(value),
            },
          ],
        },
        {},
        {},
      ],
    });
  }, [color, value]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
