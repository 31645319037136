import { useState, useEffect } from 'react';
import CheckBox from 'components/shared/CheckBox';
import { useTranslation } from 'react-i18next';
import CheckBoxDisabled from 'components/shared/CHeckBoxDiisabled';
export default function TypeCheckBoxGroupCampaign({
    onCheckboxChange,
    initialStateString,
}) {
    const { t } = useTranslation();
    //set order type
    const [isChecked0, setIsChecked0] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);

    function handleCheckbox0Change() {
        setIsChecked0(!isChecked0);
        setIsChecked1(false);
        setIsChecked2(false);
        setIsChecked3(false);

        onCheckboxChange({
            checkbox0: !isChecked0,
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
        });
    }

    function handleCheckbox1Change() {
        setIsChecked0(false);
        setIsChecked1(!isChecked1);
        setIsChecked2(false);
        setIsChecked3(false);

        onCheckboxChange({
            checkbox0: false,
            checkbox1: !isChecked1,
            checkbox2: false,
            checkbox3: false,
        });
    }

    function handleCheckbox2Change() {
        setIsChecked0(false);
        setIsChecked1(false);
        setIsChecked2(!isChecked2);
        setIsChecked3(false);

        onCheckboxChange({
            checkbox0: false,
            checkbox1: false,
            checkbox2: !isChecked2,
            checkbox3: false,
        });
    }

    useEffect(() => {
        setIsChecked0(initialStateString === 'ultraPremium');
        setIsChecked1(initialStateString === 'standard');
        setIsChecked2(initialStateString === 'premium');
    }, [isChecked1, isChecked2, isChecked0]);

    return (
        <div>
            <div className="col-2 col-md-9 d-flex justify-content-between justify-content-md-between align-items-center flex-column flex-md-row">
                <div className="col-12 col-md-3 d-flex align-items-center">
                    <div className="label-title me-3 label-choice"> {t('Standard')}</div>
                    <CheckBoxDisabled
                        name="Checkbox 1"
                        tick={initialStateString === 'standard'}
                        disabled={true}
                        onCheck={handleCheckbox1Change}
                    />
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center">
                    <div className="label-title me-3 label-choice"> {t('Premium')} </div>
                    <CheckBoxDisabled
                        name="Checkbox 2"
                        tick={initialStateString === 'premium'}
                        disabled={true}
                        onCheck={handleCheckbox2Change}
                    />
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center">
                    <div className="label-title me-3 label-choice">
                        {' '}
                        {t('Ultra Premium')}{' '}
                    </div>
                    <CheckBoxDisabled
                        name="Checkbox 0"
                        tick={initialStateString === 'ultraPremium'}
                        disabled={true}
                        onCheck={handleCheckbox0Change}
                    />
                </div>
            </div>
        </div>
    );
}
