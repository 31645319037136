import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { UserSessionSelector } from './store/UserSession';

import Login from './pages/shared/Login';
import ResetPassword from './pages/shared/ResetPassword.js';

import SuperAdminMainMenu from './components/SuperAdmin/SuperAdminMainMenu';

import { PIXLINK_ROLES, ROLES } from './utils/Constants';

import './App.css';
import ClientMainMenu from 'components/Client/ClientMainMenu';
import SuperConsultantMainMenu from 'components/SuperConsultant/SuperConsultantMainMenu';
import ConsultantMainMenu from 'components/Consultant/ConsultantMainMenu';
import AdminMainMenu from 'components/Admin/AdminMainMenu';
import EditorMainMenu from 'components/Editor/EditorMainMenu';

export default function App() {
  let { user } = useSelector(UserSessionSelector);

  if (user && user.role === PIXLINK_ROLES.SUPER_ADMIN)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/SuperAdmin/MainMenu'} />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/SuperAdmin" component={SuperAdminMainMenu} />
        <Redirect to="/SuperAdmin/MainMenu" />
      </Switch>
    );
  else if (user && user.role === PIXLINK_ROLES.ADMIN)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Admin/MainMenu'} />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/Admin" component={AdminMainMenu} />
        <Redirect to="/Admin/MainMenu" />
      </Switch>
    );
  else if (user && user.role === PIXLINK_ROLES.SUPER_CONSULTANT)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/superConsultant/MainMenu'} />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/superConsultant" component={SuperConsultantMainMenu} />
        <Redirect to="/superConsultant/MainMenu" />
      </Switch>
    );
  else if (user && user.role === PIXLINK_ROLES.CONSULTANT)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Consultant/MainMenu'} />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/Consultant" component={ConsultantMainMenu} />
        <Redirect to="/Consultant/MainMenu" />
      </Switch>
    );
  else if (user && user.role === PIXLINK_ROLES.CLIENT)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Client/MainMenu'} />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/Client" component={ClientMainMenu} />
        <Redirect to="/Client/MainMenu" />
      </Switch>
    );
  else if (user && user.role === PIXLINK_ROLES.EDITOR)
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={'/Editor/MainMenu'} />;
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/Editor" component={EditorMainMenu} />
        <Redirect to="/Editor/MainMenu" />
      </Switch>
    );

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        zIndex: '10',
        backgroundImage: `url(${'/Images/mainbackground.jpg'})`,
      }}
    >
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
}
