import AdminModal from '../../SuperAdmin/modal/AdminModal';
import ClientModal from '../../SuperAdmin/modal/ClientModal';
import ConsultantModal from '../../SuperAdmin/modal/ConsultantModal';

import { PIXLINK_ROLES } from 'utils/Constants';
import EditorModal from '../../SuperAdmin/modal/EditorModal';
import SuperAdminModal from '../../SuperAdmin/modal/SuperAdminModal';
import SuperConsultantModal from '../../SuperAdmin/modal/SuperConsultantModal';

export const CreateUpdateUser = (props) => {
  if (props.selected.role === PIXLINK_ROLES.SUPER_ADMIN)
    return (
      <SuperAdminModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === PIXLINK_ROLES.ADMIN)
    return (
      <AdminModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === PIXLINK_ROLES.SUPER_CONSULTANT)
    return (
      <SuperConsultantModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === PIXLINK_ROLES.CONSULTANT)
    return (
      <ConsultantModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === PIXLINK_ROLES.CLIENT)
    return (
      <ClientModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );

  if (props.selected.role === PIXLINK_ROLES.EDITOR)
    return (
      <EditorModal
        handleClose={props.Close}
        open={true}
        user={props.selected.user}
      />
    );
};
