import React, { useEffect, useState } from 'react';
import axiosPrivate from 'config/axiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, UserSessionSelector } from 'store/UserSession';
import { useHistory } from 'react-router-dom';

import { disabledInput } from 'components/shared/disabledClasses';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { PWD_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { lower } from 'utils/StringUtils';

import '../shared/Profile.css';
import { useTranslation } from 'react-i18next';
import axiosPixLink from 'config/axiosPixLink';

const UPDATE_PROFILE_URL = '/user/updateProfile';

export default function SuperConsultantProfile() {
  let { user } = useSelector(UserSessionSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [validFirstName, setValidFirstName] = useState(false);
  const [firstNameFocus, setFirstNameFocus] = useState(false);

  const [lastName, setLastName] = useState('');
  const [validLastName, setValidLastName] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [validPhone, setValidPhone] = useState(false);
  const [phoneNumberFocus, setPhoneFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [touchedPwd, setTouchedPwd] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [touchedMatch, setTouchedMatch] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('PixLink - Mon profil');
  }, [t]);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhoneNumber(user.phoneNumber);
  }, [user]);

  useEffect(() => {
    setValidFirstName(firstName && firstName.length > 0);
  }, [firstName]);

  useEffect(() => {
    setValidLastName(lastName && lastName.length > 0);
  }, [lastName]);

  useEffect(() => {
    setValidPhone(phoneNumber && phoneNumber.length > 0);
  }, [phoneNumber]);

  useEffect(() => {
    let includeName =
      lower(pwd).includes(lower(firstName)) ||
      lower(pwd).includes(lower(lastName));
    setValidPwd(pwd.length === 0 || (!includeName && PWD_REGEX.test(pwd)));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd, firstName, lastName]);

  const handleSubmit = () => {
    axiosPixLink
      .put(UPDATE_PROFILE_URL, {
        firstName,
        lastName,
        email: user.email,
        phoneNumber,
        password: !pwd ? null : pwd,
        role: user.role,
      })
      .then((res) => {
        dispatch(updateUser(res.data));
        setErrorSuccessMsg(t(`Votre profil a été mis à jour avec succès.`));
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setErrorSuccessMsg(
          t(
            `Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.`
          )
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      });
  };

  return (
    <div className="content-center">
      <div className="box-header-top">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{t('Mon profil')}</h1>
      </div>
      <div className="row">
        <div className="box-over" style={{ position: 'relative' }}>
          <div className="box-img-bg bg-4" />
          <div className="box-img-bg bg-5" />
          <div className="box-img-bg bg-6" />
          <div className="col-12 col-md-5 box-screen">
            <div className="box-wrapper">
              <div className="row row-mb">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div
                      className={
                        validFirstName ? 'label-title' : 'label-title-error'
                      }
                    >
                      {t('Prénom')}*
                    </div>
                    <div>
                      <input
                        type="text"
                        className={
                          validFirstName
                            ? 'form-control form-control-valid form-ct'
                            : 'form-control form-control-error form-ct'
                        }
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        onFocus={() => setFirstNameFocus(true)}
                        onBlur={() => setFirstNameFocus(false)}
                      />
                    </div>
                    {firstNameFocus && !validFirstName ? (
                      <p className="error-note">
                        {' '}
                        {t('Ce champs est obligatoire')}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className={
                        validLastName ? 'label-title' : 'label-title-error'
                      }
                    >
                      {t('Nom')}*
                    </div>
                    <div>
                      <input
                        type="text"
                        className={
                          validLastName
                            ? 'form-control form-control-valid form-ct'
                            : 'form-control form-control-error form-ct'
                        }
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onFocus={() => setLastNameFocus(true)}
                        onBlur={() => setLastNameFocus(false)}
                      />
                    </div>
                    {lastNameFocus && !validLastName ? (
                      <p className="error-note">
                        {t('Ce champs est obligatoire')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('E-mail')}</div>
                    <div>
                      <input
                        type="text"
                        value={user.email}
                        disabled
                        className="form-control form-ct"
                        style={disabledInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div
                      className={
                        validPhone ? 'label-title' : 'label-title-error'
                      }
                    >
                      {t('Téléphone')}*
                    </div>
                    <div>
                      <input
                        type="text"
                        className={
                          validPhone
                            ? 'form-control form-control-valid form-ct'
                            : 'form-control form-control-error form-ct'
                        }
                        value={phoneNumber}
                        onChange={(e) => {
                          if (
                            e.target.value === '' ||
                            PHONENUMBER_REGEX.test(e.target.value)
                          ) {
                            setPhoneNumber(e.target.value);
                          }
                        }}
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                      />
                    </div>
                    {phoneNumberFocus && !validPhone ? (
                      <p className="error-note">
                        {t(
                          'Ce champs est obligatoire et ne peut avoir que des nombres'
                        )}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div
                      className={
                        touchedPwd && !validPwd
                          ? 'label-title-error'
                          : 'label-title'
                      }
                    >
                      {t('Mot de passe')}*
                    </div>
                    <div className="input-group input-password">
                      <input
                        className={
                          touchedPwd && !validPwd
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                        type={showPassword ? 'text' : 'password'}
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => {
                          setPwdFocus(false);
                          setTouchedPwd(true);
                        }}
                      />
                      <span
                        className={
                          touchedPwd && !validPwd
                            ? 'input-group-text error'
                            : 'input-group-text'
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <img
                            src="/Images/icon-eye-black.png"
                            alt="black-eye"
                          />
                        ) : (
                          <img src="/Images/icon-eye-gray.png" alt="grey-eye" />
                        )}
                      </span>
                    </div>
                    {touchedPwd && pwdFocus && !validPwd ? (
                      <p className="error-note">
                        {t('doit comprendre au moins 8 charactères')}.
                        <br />{' '}
                        {t(
                          'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre'
                        )}
                        <br />
                        {t('ne doit pas inclure votre nom/prénom')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row row-mb">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div
                      className={
                        touchedMatch && !validMatch
                          ? 'label-title-error'
                          : 'label-title'
                      }
                    >
                      {t('Confirmer le mot de passe')}*
                    </div>
                    <div className="input-group input-password">
                      <input
                        className={
                          touchedMatch && !validMatch
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                        type={showPassword ? 'text' : 'password'}
                        value={matchPwd}
                        onChange={(e) => setMatchPwd(e.target.value)}
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => {
                          setMatchFocus(false);
                          setTouchedMatch(true);
                        }}
                      />
                      <span
                        className={
                          touchedMatch && !validMatch
                            ? 'input-group-text error'
                            : 'input-group-text'
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <img
                            src="/Images/icon-eye-black.png"
                            alt="black-eye"
                          />
                        ) : (
                          <img src="/Images/icon-eye-gray.png" alt="grey-eye" />
                        )}
                      </span>
                    </div>
                    {touchedMatch && matchFocus && !validMatch ? (
                      <p className="error-note">
                        {t('Les deux mots de passe ne sonts pas identiques')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="text-more">
                *
                {t(
                  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                )}
              </div>
            </div>
            <div>
              {!validFirstName ||
              !validLastName ||
              !validPhone ||
              !validPwd ||
              !validMatch ? (
                <button className="bt-submit-disabled-y float-end">
                  {' ' + t('Sauvegarder') + ' '}
                </button>
              ) : (
                <button
                  className="bt-submit-y float-end"
                  onClick={handleSubmit}
                >
                  {' ' + t('Sauvegarder') + ' '}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
      />
    </div>
  );
}
