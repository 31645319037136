import React from 'react';
import { Tooltip } from '@mui/material';
const LinkIconWithTooltip = ({ value }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {value && value.length > 0 ? (
        <div>
          <Tooltip title={value}>
            <img
              style={{ width: '20px', cursor: 'pointer' }}
              src="Images/icon-lien-green.png"
              alt="Link icon"
            />
          </Tooltip>
        </div>
      ) : (
        <img
          style={{ width: '20px', cursor: 'pointer' }}
          src="Images/icon-lien.png"
          alt="Link icon"
        />
      )}
    </div>
  );
};

export default LinkIconWithTooltip;
