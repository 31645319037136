import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';
import { ROLES } from 'utils/Constants';

export const fetchAdminWritersList = createAsyncThunk(
  'adminWriterList/fetchAdminWritersList',
  async (arg, thunkAPI) => {
    try {
      const response = await axiosPrivate.get('/user/all', {
        params: { role: ROLES.WRITER },
      });
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
