import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchAllOrders = createAsyncThunk(
  'WriterOrderList/fetchAllOrders',
  async (input, thunkAPI) => {
    try {
      const response = await axiosPrivate.get('/commands/commandsByStatus');
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
