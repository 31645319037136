import { Autocomplete, InputBase, createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { customStyle } from 'components/Netlinking/AutoComplete/CustomAutocompleteStyle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PHONENUMBER_REGEX } from 'utils/Regex';

const useStyles = makeStyles(() => createStyles(customStyle));
export const AssignClientEditDisabled = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [name, setName] = useState(null);
  const [email, setMail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [initialName, setInitialName] = useState(props.data.initialName || '');

  useEffect(() => {
    setInitialName(props.data.initialName);
    if (name && name.length > 0) {
    }

    setMail(props.data.email);
    setPhoneNumber(props.data.phoneNumber);
  }, [props, props.data]);

  return (
    <div className="row row-mb">
      <div className="col-12 col-md-4">
        <div className="label-title">{t('Prénom / Nom du client')}*</div>
        <Autocomplete
          disabled={true}
          freeSolo
          options={props.list.map(
            (client) => client.firstName + ' ' + client.lastName
          )}
          value={name != null ? name : initialName}
          onInputChange={(value) => {
            if (typeof value?.target?.value === 'string') {
              setName(value.target.value);
              props.updateClientsList(
                'firstName',
                value.target.value?.split(' ')[0]
              );
              props.updateClientsList(
                'lastName',
                value.target.value?.split(' ')[1]
              );
            } else {
              if (value != null) {
                props.updateClientsList('firstName', value);
                setName('-');
              }
            }
          }}
          onChange={(event, value) => {
            if (
              value &&
              props.list.length > 0 &&
              props.list.some(
                (client) => client?.firstName + ' ' + client?.lastName === value
              )
            ) {
              const client = props.list.find(
                (client) => client?.firstName + ' ' + client?.lastName === value
              );
              props.updateClientsList('firstName', client.firstName);
              props.updateClientsList('lastName', client.lastName);
              props.updateClientsList('email', client.email);
              props.updateClientsList('phoneNumber', client.phoneNumber);
              props.updateClientsList('isNew', false);
              setName(client.firstName + ' ' + client.lastName);
            } else {
              props.updateClientsList('email', '');
              props.updateClientsList('phoneNumber', '');
              props.updateClientsList('isNew', true);
            }
          }}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return (
              <InputBase
                classes={{ root: classes.disabled }}
                variant="outlined"
                {...params.InputProps}
                {...rest}
              />
            );
          }}
        />
        {/* <CustomAutocompleteFreeSolo
          list={props.list}
          value={name}
          onInputChange={(value) => {
            if (typeof value?.target?.value === 'string') {
              props.updateClientsList(
                'firstName',
                value.target.value?.split(' ')[0]
              );
              props.updateClientsList(
                'lastName',
                value.target.value?.split(' ')[1]
              );
            } else {
              props.updateClientsList('firstName', value);
            }
          }}
          setValue={(value) => {
            if (
              value &&
              value.id &&
              props.list.length > 0 &&
              props.list.some((x) => x.id === value.id)
            ) {
              const client = props.list.find((x) => x.id === value.id);
              props.updateClientsList('firstName', client.firstName);
              props.updateClientsList('lastName', client.lastName);
              props.updateClientsList('email', client.email);
              props.updateClientsList('phoneNumber', client.phoneNumber);
              props.updateClientsList('isNew', false);
            } else {
              props.updateClientsList('email', '');
              props.updateClientsList('phoneNumber', '');
              props.updateClientsList('isNew', true);
            }
          }}
          getOptionLabel={(option) =>
            option.firstName && option.lastName
              ? option.firstName + ' ' + option.lastName
              : ''
          }
        />*/}
      </div>
      <div className="col-12 col-md-4">
        <div className="label-title">{t('email client')}*</div>
        <input
          disabled={true}
          onChange={(e) => props.updateClientsList('email', e.target.value)}
          value={email}
          type="text"
          className="form-control form-ct"
        />
      </div>
      <div className="col-12 col-md-3">
        <div className="label-title">{t('Téléphone')}*</div>
        <input
          disabled={true}
          onChange={(e) => {
            if (
              e.target.value === '' ||
              PHONENUMBER_REGEX.test(e.target.value)
            ) {
              props.updateClientsList('phoneNumber', e.target.value);
            }
          }}
          value={phoneNumber}
          type="text"
          className="form-control form-ct"
        />
      </div>
    </div>
  );
};
