import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: '275px',
    '&:hover': {
      color: '3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
    '&:focus': {
      color: '3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
  },
}))(MenuItem);

const PopoverSpotMenu = (props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the popover
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    // Attach the event listener when the popover is visible
    if (isVisible) {
      document.addEventListener('click', handleClickOutside);
    }

    // Detach the event listener when the popover is hidden
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isVisible]);

  const handleClick = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div style={{ position: 'relative' }} ref={popoverRef}>
      <div onClick={handleClick}>{props.children}</div>
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '0%',
            transform: 'translateX(0%)',
            backgroundColor: '#fff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            padding: '8px',
            borderRadius: '15px',
            zIndex: 999,
          }}
        >
          {props.list.map((option, index) => (
            <StyledMenuItem
              key={index}
              selected={props.selectedStatus.includes(option.status)}
              onClick={() => props.toggleStatus(option.status)}
              style={
                props.selectedStatus.includes(option.status)
                  ? { backgroundColor: '#F9D53F' }
                  : { backgroundColor: 'transparent' }
              }
            >
              <ListItemText
                style={{
                  textAlign: 'left',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  fontSize: '16px',
                }}
                primary={props.getOptionLabel(option)}
              />
              {props.selectedStatus.includes(option.status) ? (
                <img
                  style={{ float: 'right' }}
                  src="Images/icon-check.png"
                  alt="check"
                />
              ) : null}
            </StyledMenuItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default PopoverSpotMenu;
