export const isIdInArray = (id, arrayOfObjects) => {
  return arrayOfObjects.some((obj) => obj.id === id);
};

export const getMaxAttributeValue = (objects, attributeName) => {
  if (objects.length === 0) {
    return null;
  }

  let max = null;
  for (let i = 0; i < objects.length; i++) {
    const value = objects[i][attributeName];
    if (value !== null && (max === null || value > max)) {
      max = value;
    }
  }
  return max;
};

export function findIntersectionSelected(array1, array2) {
  const map = new Map();
  const result = [];

  // Map netlinking.id to objects in array1
  for (const item of array1) {
    map.set(item?.netlinking?.id, item);
  }

  // Check netlinking.id in array2 and add to result if found
  for (const item of array2) {
    if (map.has(item?.netlinking?.id)) {
      result.push(item);
    }
  }

  return result;
}
