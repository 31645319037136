export function getLanguageNameById(id) {
  const idToLanguage = {
    1: 'english',
    2: 'german',
    3: 'spanish',
    4: 'italian',
    6: 'french',
    34: 'Ukraine',
    35: 'Kazakhstan',
    36: 'Finland',
    37: 'Turkey',
    38: 'Portugal',
    39: 'India',
    40: 'Egypt',
    41: 'Brazil',
    42: 'Pakistan',
    44: 'Azerbaijan',
    46: 'Norway',
    47: 'Poland',
    48: 'Philippines',
    49: 'Russia',
    50: 'Denmark',
    51: 'Combodia',
    52: 'Bangladesh',
    53: 'Vietnam',
    54: 'Nigeria',
    55: 'Romaina',
    56: 'sweden',
    57: 'Nepal',
    58: 'Argentina',
    59: 'Netherlands',
    60: 'Indonesia',
    61: 'onne',
    62: 'UAE',
    63: 'Ireland',
    64: 'South Africa',
    65: 'Greece',
    66: 'Mexico',
    67: 'South Korea',
    68: 'Colombia',
    69: 'Romania',
    70: 'Bulgaria',
  };
  return idToLanguage[id];
}

export function getLanguageIdByName(name) {
  const idToLanguage = {
    1: 'english',
    2: 'german',
    3: 'spanish',
    4: 'italian',
    6: 'french',
    34: 'Ukraine',
    35: 'Kazakhstan',
    36: 'Finland',
    37: 'Turkey',
    38: 'Portugal',
    39: 'India',
    40: 'Egypt',
    41: 'Brazil',
    42: 'Pakistan',
    44: 'Azerbaijan',
    46: 'Norway',
    47: 'Poland',
    48: 'Philippines',
    49: 'Russia',
    50: 'Denmark',
    51: 'Combodia',
    52: 'Bangladesh',
    53: 'Vietnam',
    54: 'Nigeria',
    55: 'Romaina',
    56: 'sweden',
    57: 'Nepal',
    58: 'Argentina',
    59: 'Netherlands',
    60: 'Indonesia',
    61: 'onne',
    62: 'UAE',
    63: 'Ireland',
    64: 'South Africa',
    65: 'Greece',
    66: 'Mexico',
    67: 'South Korea',
    68: 'Colombia',
    69: 'Romania',
    70: 'Bulgaria',
  };
  const languageToId = {};
  for (const id in idToLanguage) {
    const language = idToLanguage[id];
    languageToId[language] = parseInt(id);
  }
  return languageToId[name];
}

export function getLanguageRefById(id) {
  const idToLanguage = {
    1: 'En',
    2: 'De',
    3: 'Es',
    4: 'It',
    5: 'Pt',
    6: 'Fr',
  };
  return idToLanguage[id];
}

export function getLanguageIdByRef(name) {
  const idToLanguage = {
    1: 'En',
    2: 'De',
    3: 'Es',
    4: 'It',
    5: 'Pt',
    6: 'Fr',
    34: 'Ukraine',
    35: 'Kazakhstan',
    36: 'Finland',
    37: 'Turkey',
    38: 'Portugal',
    39: 'India',
    40: 'Egypt',
    41: 'Brazil',
    42: 'Pakistan',
    44: 'Azerbaijan',
    46: 'Norway',
    47: 'Poland',
    48: 'Philippines',
    49: 'Russia',
    50: 'Denmark',
    51: 'Combodia',
    52: 'Bangladesh',
    53: 'Vietnam',
    54: 'Nigeria',
    55: 'Romaina',
    56: 'Sweden',
    57: 'Nepal',
    58: 'Argentina',
    59: 'Netherlands',
    60: 'Indonesia',
    61: 'Onne',
    62: 'UAE',
    63: 'Ireland',
    64: 'South Africa',
    65: 'Greece',
    66: 'Mexico',
    67: 'South Korea',
    68: 'Colombia',
    69: 'Romania',
    70: 'Bulgaria',
  };

  const languageToId = {};
  for (const id in idToLanguage) {
    const language = idToLanguage[id];
    languageToId[language] = parseInt(id);
  }
  return languageToId[name];
}
