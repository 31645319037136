import { ListItemText, Menu, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import { browserList, detectBrowser } from 'utils/BrowserDetection';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
    margin: '0',
    padding: '0',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuArraySpots = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
    margin: '0',
    padding: '0',
    transform:
      detectBrowser() === browserList.FIREFOX
        ? 'translateY(-10%) !important'
        : 'translateY(-10%) !important',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: '275px',
    '&:hover': {
      color: '3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
    '&:focus': {
      color: '3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
  },
}))(MenuItem);

export const CustomDropdownReadOnly = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, key) => (
        <StyledMenuItem key={key}>
          <ListItemText
            style={{
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export const CustomDropdownStatusSpots = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={(option) => props.setValue(index)}
          style={
            props.value.status === option.status
              ? { backgroundColor: '#F9D53F' }
              : { backgroundColor: 'transparent' }
          }
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.value.status === option.status ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};
export const CustomDropdownMultipleStatusSpots = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.selectedStatus.includes(option.status)}
          onClick={() => props.toggleStatus(option.status)}
          style={
            props.selectedStatus.includes(option.status)
              ? { backgroundColor: '#F9D53F' }
              : { backgroundColor: 'transparent' }
          }
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.selectedStatus.includes(option.status) ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export const CustomDropdownSingleRedactionPublicationSpot = (props) => {
  const handleItemClick = (status) => {
    // Deselect previously selected status
    const newSelectedStatus = props.selectedStatus === status ? '' : status;
    props.toggleStatus(newSelectedStatus);
  };

  return (
    <StyledMenuArraySpots
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.selectedStatus === option.status}
          onClick={() => handleItemClick(option.status)}
          style={{
            backgroundColor:
              props.selectedStatus === option.status
                ? 'transparent'
                : 'transparent',
          }}
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.selectedStatus === option.status && false ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenuArraySpots>
  );
};

export const CustomDropdownSingleRedactionPublicationSpotDisabled = (props) => {
  return (
    <StyledMenuArraySpots
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={false}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.selectedStatus === option.status}
          onClick={() => {}}
          style={{
            backgroundColor:
              props.selectedStatus === option.status
                ? 'transparent'
                : 'transparent',
          }}
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.selectedStatus === option.status && false ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenuArraySpots>
  );
};
