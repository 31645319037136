export function calculateMinimumLinks(numberOfLinks) {
  if (numberOfLinks >= 1 && numberOfLinks <= 15) {
    return numberOfLinks * 2;
  } else if (numberOfLinks >= 16 && numberOfLinks <= 69) {
    return numberOfLinks + 15;
  } else {
    return numberOfLinks + 30;
  }
}

export function calculateClientMinimumLinks(numberOfLinks) {
  return (
    Math.ceil(calculateMinimumLinks(numberOfLinks) - numberOfLinks) / 2 +
    numberOfLinks
  );
}
