import { PIXLINK_STATUS } from 'utils/Constants';

export const calculateClientValidateClient = (netlinkings) => {
  return netlinkings.filter((item) =>
    item.spotTmpStatus
      ? item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
      : item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
  ).length;
};

export const calculateClientRefusedClient = (netlinkings) => {
  return netlinkings.filter((item) =>
    item.spotTmpStatus
      ? item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT
      : item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT
  ).length;
};

export const calculateClientToBeFilteredClient = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
  ).length;
};

export const calculateClientToBeCommandedClient = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_COMMANDED
  ).length;
};
