import { PIXLINK_STATUS } from 'utils/Constants';

export const calculateValidateSuperAdmin = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN
  ).length;
};
export const calculateValidateSuperConsultant = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT
  ).length;
};

export const calculateValidateClient = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
  ).length;
};

export const calculateToBeFilteredConsultant = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
  ).length;
};

export const calculateToBeCommanded = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_COMMANDED
  ).length;
};

export const calculateRefusedClient = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
      item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT
  ).length;
};

export const calculateRefusedPixalione = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE ||
      item.spotTmpStatus === PIXLINK_STATUS.REFUSED_PIXALIONE
  ).length;
};
