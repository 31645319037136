import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsReact from 'highcharts-react-official';
import { min } from 'lodash';
import { COLOR_JAUGE } from 'utils/Constants';
import { CustomTooltip, CustomTooltipSmall } from '../CustomTooltip';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export const MyCustomHighchartsSmall = (props) => {
  const [options, setOptions] = useState({});
  const [value, setValue] = useState(props.value);

  const [color, setColor] = useState(props.defaultColor);

  useEffect(() => {
    setValue(props.value);
    setOptions({
      chart: {
        type: 'solidgauge',
        margin: [0, 0, 0, 0],
        height: 66,
        width: 66,
        spacing: [0, 0, 0, 0],
        Animation: false,
      },

      credits: { enabled: false },
      title: {
        text: '',
        style: {
          fontSize: '10px',
        },
      },

      tooltip: {
        enabled: false,
        formatter: function () {
          // Customize the tooltip content here
          return 'A valider pixalione';
        },
        positioner: function (labelWidth, labelHeight, point) {
          // Offset the tooltip position
          return {
            x: point.plotX - labelWidth / 2,
            y: point.plotY - labelHeight - 10, // Adjust the offset here
          };
        },
      },
      pane: {
        startAngle: 0,
        endAngle: 395,
        background: [
          {
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: color + '33',
            borderWidth: 0,
          },
        ],
      },

      yAxis: {
        min: 0,
        max: props.maxValue,
        lineWidth: 0,
        tickPositions: [],
      },

      plotOptions: {
        solidgauge: {
          borderWidth: '8px',
          dataLabels: {
            /* enabled: false, */
            y: -19,
            borderWidth: 0,
            useHTML: true,
            formatter: function () {
              return this.point.y;
            },
          },
          style: {
            color: color,
            fontSize: '14px',
            fontWeight: 'bold',
            textOutline: 'transparent',
            fontFamily: 'Proxima Nova Bold',
          },
          linecap: 'round',
          stickyTracking: false,
        },
      },

      series: [
        {
          name: 'Move',
          borderColor: color,
          dataLabels: {
            format:
              '<div class="datalabels-solidgauge" style="text-align:center !important"><span style="font-size:20px;font-weight400;font-family: Poppins, sans-serif;color:' +
              color +
              `">{y}</span><br/>` +
              '</div>',
          },
          data: [
            {
              color: Highcharts.getOptions().colors[0],
              radius: '100%',
              innerRadius: '100%',
              y: parseFloat(value),
            },
          ],
        },
        {},
        {},
      ],
    });
  }, [color, value, props]);

  return (
    <span style={{ margin: '12px 12px 12px 12px' }}>
      <CustomTooltipSmall
        title={
          <span>
            <p>{props.label}</p>
          </span>
        }
        placement="top"
      >
        <span>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </span>
      </CustomTooltipSmall>
    </span>
  );
};
