import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { removeUser } from 'store/Admin/UserManagement';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import axiosPixLink from 'config/axiosPixLink';
import { useTranslation } from 'react-i18next';
import { PIXLINK_ROLES } from 'utils/Constants';

const DELETE_USER_URL = '/user/delete/';

export const DeleteUser = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  const [role, setRole] = useState('');

  useEffect(() => {
    switch (props.selected?.role) {
      case PIXLINK_ROLES.CLIENT:
        setRole('le client');
        break;
      case PIXLINK_ROLES.CONSULTANT:
        setRole('le consultant');
        break;
      case PIXLINK_ROLES.ADMIN:
        setRole("l'admin");
        break;
      case PIXLINK_ROLES.EDITOR:
        setRole("l'editeur");
        break;
      case PIXLINK_ROLES.SUPER_CONSULTANT:
        setRole('le super consultant');
        break;
      case PIXLINK_ROLES.SUPER_ADMIN:
        setRole('le super admin');
        break;
      default:
        setRole(`L'utilisateur`);
        break;
    }

    setConfirmation(true);
  }, [props.selected]);

  useEffect(() => {
    setConfirmationMsg(
      `${t('Voulez-vous vraiment supprimer')} ${t(role)} ${
        props.selected?.email
      } ?`
    );
  }, [role, props.selected, t]);

  const deleteUser = () =>
    axiosPixLink
      .delete(DELETE_USER_URL + props.selected.email)
      .then(() => {
        setConfirmation(false);
        dispatch(removeUser(props.selected.email));
        setIsSuccess(true);

        setErrorSuccessMsg(
          `${t(role)} ${props.selected.email} ${t(
            'a été supprimé avec succès'
          )} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setConfirmation(false);
        setIsSuccess(false);
        switch (props.selected.role) {
          case PIXLINK_ROLES.CLIENT:
            setErrorSuccessMsg(
              e.response.status === 409
                ? `${t('Le client')} ${props.selected.email} ${t(
                    "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer"
                  )}.`
                : t("Oups....! Une erreur s'est produite")
            );
            break;

          case PIXLINK_ROLES.CONSULTANT:
            setErrorSuccessMsg(
              e.response.status === 409
                ? `${t('Le consultant')} ${props.selected.email} ${t(
                    'ne peut pas être supprimé car il est lié à des sites web'
                  )}.`
                : t("Oups....! Une erreur s'est produite")
            );
            break;

          case PIXLINK_ROLES.EDITOR:
            setErrorSuccessMsg(
              e.response.status === 409
                ? `${t('Vous ne pouvez pas supprimer le rédacteur')} ${
                    props.selected.email
                  } ,${t('car celui-ci a déjà rédigé des textes')}.`
                : t("Oups....! Une erreur s'est produite")
            );
            break;

          default:
            setErrorSuccessMsg(
              `${t(role)} ${props.selected.email} ${t(
                'ne peut pas être supprimé pour le moment'
              )}`
            );
            break;
        }
        setErrorSuccess(true);
      });

  return (
    <div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => props.Close()}
        executeAction={() => {
          deleteUser();
        }}
      />
    </div>
  );
};
