import Axios from 'axios';

// const BASE_URL = process.env.REACT_APP_DEV_API_URL_PREFIX
const BASE_URL = 'https://dev.typixweb.com/typix';

const axios = Axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

export default axios;
