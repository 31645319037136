import { createSlice } from '@reduxjs/toolkit';
import { fetchClientList } from './features';

export const adminClientList = createSlice({
  name: 'adminClientList',
  initialState: {
    clients: null,
    isLoadingClients: true,
    isError: true,
  },
  reducers: {
    removeClient: (state, action) => {
      state.clients = state.clients.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientList.fulfilled, (state, { payload }) => {
        state.clients = payload;
        state.isLoadingClients = false;
        state.isError = false;
      })
      .addCase(fetchClientList.pending, (state) => {
        state.isLoadingClients = true;
        state.isError = false;
      })
      .addCase(fetchClientList.rejected, (state) => {
        state.isLoadingClients = false;
        state.isError = true;
      });
  },
});

export default adminClientList.reducer;

export const { removeClient } = adminClientList.actions;

export const adminClientListSelector = (state) => state.adminClientList;
