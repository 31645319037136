export const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const formatDate = (date) => {
  const [year, month, day] = date.slice(0, 10).split('-');
  const result = [day, month, year].join('/');
  return result;
};

export const getDaysDifferenceFromToday = (date) => {
  const today = new Date();
  const utc2 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utc1 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export function fullDateToFormattedDate(fullTimestamp) {
  if (fullTimestamp == null) {
    return new Date();
  }
  return fullTimestamp.split('T')[0];
}
