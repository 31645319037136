import { createSlice } from '@reduxjs/toolkit';
import { fetchWebsiteDisplay, fetchWebsiteOrdersList } from './features';

export const consultantWebsiteDetails = createSlice({
  name: 'consultantWebsiteDetails',
  initialState: {
    formData: null,
    account: '',
    ordersList: [],
    isLoading: true,
    isError: true,
    isListLoading: true,
    isListError: true,
  },
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteDisplay.fulfilled, (state, { payload }) => {
        state.formData = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchWebsiteDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchWebsiteDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchWebsiteOrdersList.fulfilled, (state, { payload }) => {
        state.ordersList = payload;
        state.isListLoading = false;
        state.isListError = false;
      })
      .addCase(fetchWebsiteOrdersList.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
      })
      .addCase(fetchWebsiteOrdersList.rejected, (state) => {
        state.isListLoading = false;
        state.isListError = true;
      });
  },
});

export default consultantWebsiteDetails.reducer;

export const { setAccount } = consultantWebsiteDetails.actions;

export const consultantWebsiteDetailsSelector = (state) =>
  state.consultantWebsiteDetails;
