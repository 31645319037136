import { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosPixLink from 'config/axiosPixLink';
import { capitalizeFirstLetter } from 'utils/StringUtils';

export const LanguageSearchFieldEdit = ({ onLanguageChange, editvalue }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [languesList, setLanguesList] = useState([]);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    axiosPixLink.get('/netlinking/all-languages').then((res) => {
      setLanguesList(res.data.map((language) => language.language));
    });
  }, []);

  return (
    <Autocomplete
      value={editvalue ? editvalue : value}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          height: '40px',
        },
      }}
      disabled={languesList.length > 0 ? false : true}
      onChange={(event, newValue) => {
        setValue(newValue);
        onLanguageChange(newValue);
      }}
      options={languesList}
      getOptionLabel={(option) => capitalizeFirstLetter(t(`${option}`))} // Adjust 'translationKey' to your actual translation key
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          sx={{ height: '40px' }}
          /*label={t(`${option}`)} // Adjust 'translationKey.label' to your actual translation key for the label*/
        />
      )}
    />
  );
};
