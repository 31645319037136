import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from 'components/shared/Loader';
import axiosPixLink from 'config/axiosPixLink';

import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';

import { ROLES } from 'utils/Constants';

/*import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
*/
import './order.css';
import { useTranslation } from 'react-i18next';
import { animationUrl } from 'config/endpoints';
import { LanguageSearchFieldEdit } from 'components/Netlinking/AutoComplete/languageAutoCompleteEdit';
import {
  getLanguageIdByName,
  getLanguageNameById,
} from 'utils/netlinking/languages';
import { AssignConsultantEdit } from '../../components/shared/ClientConsultantsAssignement/AssignConsultantEdit';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import Calendar from 'components/Netlinking/Calendar/CalendarPicker';
import ExcelUploader from 'components/shared/UploadExcel/UploadExcel';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import ExcelUploaderCampaign from 'components/shared/UploadExcel/UploadExcelCampaign';
import CheckBox from 'components/shared/CheckBox';
import { browserList, detectBrowser } from 'utils/BrowserDetection';
import TypeCheckBoxGroupCampaign from 'components/Netlinking/CheckBox/TypeCheckBoxCampaign';

const API = {
  getAvailableThematics: '/netlinking/all-thematics',
  getAvailableLanguages: '/language/all',
  getExistingAccounts: 'website/account/all',
  getAllWriters: '/user/all',
  getAllWebsiteOfAccount: '/website',
  getAllDevisOfAccount: '/user/getDevise',
  createCampaign: '/campaign/superAdmin/create',
  createSmartOrder: '/commands/addSmartCommand',
  getAllConsultants: '/user/consultants',
  getAllSuperConsultants: 'user/superconsultants',
};

export default function SuperAdminCreateCampaign() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [writersList, setWritersList] = useState([]);

  const [account, setAccount] = useState(null);
  const [numberLinks, setNumberLinks] = useState(0);
  const [orderThematics, setOrderThematics] = useState([]);
  const [orderLanguage, setOrderLanguage] = useState(null);
  const [accountsList, setAccountsList] = useState([]);
  const [consultant, setConsultant] = useState(null);
  const [devis, setDevis] = useState(null);
  const [devisList, setDevisList] = useState([]);
  const [task, setTask] = useState(null);
  const [titled, setTitled] = useState('');
  const [websiteList, setWebsiteList] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [editor, setEditor] = useState('');
  const [contentType, setContentType] = useState(null);
  const [orderType, setOrderType] = useState('');

  const [detailedBrief, setDetailedBrief] = useState('');
  const [wantedLinksFocus, setWantedLinksFocus] = useState(false);

  const [historicBackLinkList, setHistoricBackLinkList] = useState([]);
  const [historicBackLink, setHistoricBackLink] = useState('');
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [wantedLinksList, setWantedLinksList] = useState([]);
  const [wantedLinks, setWantedLinks] = useState('');

  const [consultantsList, setConsultantsList] = useState([]);
  const [superConsultantsList, setSuperConsultantsList] = useState([]);
  const [toBeVerifier, setToBeVerifier] = useState(false);
  const [loader, setLoader] = useState(false);
  const [assignedConsultants, setAssignedConsultants] = useState([
    {
      name: '',
      firstName: '',
      lastName: '',
      isNew: false,
    },
  ]);
  const [assignedSuperConsultants, setAssignedSuperConsultants] = useState([
    {
      name: '',
      firstName: '',
      lastName: '',
      isNew: false,
    },
  ]);

  const [assignedWriters, setAssignedWriters] = useState([
    {
      writer: null,
      price: 0,
      deadline: null,
      numberLinks: 0,
      minWords: 0,
      maxWords: 0,
      actualBilling: false,
      wordsManagedNumber: 0,
    },
  ]);

  const handleTobeVerifiedChange = (event) => {
    setToBeVerifier(event.target.checked);
  };

  const handleWantedLinksChange = (data) => {
    setWantedLinksList(data);
  };

  const handleHistoricBackLinksChange = (data) => {
    setHistoricBackLinkList(data);
  };

  const [maxNumber, setMaxNumber] = useState(0);
  const [disabledForm, setDisabledForm] = useState(true);

  const [isTranslation, setIsTranslation] = useState(false);
  const [isRedaction, setIsRedaction] = useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('PixLink - Nouvelle campaign');

    axiosPixLink.get(API.getAllConsultants).then((res) => {
      setConsultantsList(res.data);
    });
    axiosPixLink.get(API.getAllSuperConsultants).then((res) => {
      setSuperConsultantsList(res.data);
    });
    axiosPixLink
      .get(API.getExistingAccounts)
      .then((res) => setAccountsList(res.data));
    axiosPixLink
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
    axiosPixLink
      .get(API.getAllWriters, { params: { role: ROLES.WRITER } })
      .then((res) => setWritersList(res.data));
  }, [t]);

  useEffect(() => {
    if (account) {
      axiosPixLink
        .get(API.getAllDevisOfAccount, { params: { account: account } })
        .then((res) => setDevisList(res.data))
        .catch((err) => console.log(err));
      axiosPixLink
        .get(`/website/${account}/website`)
        .then((res) => setWebsiteList(res.data));
    }

    setDevis(null);
    setTask(null);
    setTitled('');
    setSelectedWebsite(null);
    setNumberLinks(0);
    setConsultant(null);
    setOrderLanguage(null);
    setOrderThematics([]);
    setDetailedBrief('');
    setHistoricBackLink('');
    setWantedLinks('');
    clearAssignedWritersList();
  }, [account]);

  useEffect(() => {
    if (selectedWebsite?.languageId) {
      setNetlinkingLanguage(getLanguageNameById(selectedWebsite?.languageId));
    }
    if (selectedWebsite?.languageId) {
      setOrderThematics(selectedWebsite?.themes);
    }
    if (selectedWebsite?.consultants) {
      setAssignedConsultants(selectedWebsite?.consultants);
    }
    if (selectedWebsite?.superConsultants) {
      setAssignedSuperConsultants(selectedWebsite?.superConsultants);
    }
  }, [selectedWebsite]);

  useEffect(() => {
    setMaxNumber(
      numberLinks -
      assignedWriters
        .map((x) => x.numberLinks)
        .reduce(
          (previousValue, currentValue) =>
            parseInt(previousValue) + parseInt(currentValue)
        )
    );
  }, [assignedWriters, task, numberLinks]);
  const [netlinkingType, setNetlinkingType] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [netlinkingDeadline, setNetlinkingDeadline] = useState('');

  function handleTypeCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox0
    ) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
    } else if (newStates.checkbox0) {
      setNetlinkingType('ultraPremium');
    }
  }
  const handleContactedTimeChange = (value) => {
    setNetlinkingDeadline(value);
  };

  function searchFunction() {
    let tmpnetlinkingTypeBrut = netlinkingList;
    if (netlinkingType === 'redType') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) =>
          item.type === 'redList' ||
          item.type === 'liste rouge' ||
          item.type === 'Liste Rouge' ||
          item.type === 'Liste rouge'
      );
    } else if (netlinkingType === 'standard') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'standard'
      );
    } else if (netlinkingType === 'premium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'premium'
      );
    } else if (netlinkingType === 'ultraPremium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'ultraPremium'
      );
    }
    let tmpNetlinkingLanguage = tmpnetlinkingTypeBrut;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = tmpNetlinkingLanguage.filter(
        (item) => item.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) =>
            row.themesList !== null &&
            row.themesList.toLowerCase().includes(value.toLowerCase())
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.tF >= TFValues[0] && item.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.trafic >= TraficValues[0] && item.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.tarifHr >= TarifHRValues[0] &&
          item.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.tarifRc >= TarifRCValues[0] &&
          item.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.kwPositioned >= Kwvalues[0] &&
          item.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.contact != null &&
          item.contact != undefined &&
          item.contact.includes(netlinkingContactFilter)
      );
    }
    setNetlinkingFilteredList(tmpNetlinkingContactFilter);
  }

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);
    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };

  useEffect(() => {
    searchFunction();
  }, [
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    toBeVerifier,
    netlinkingContactFilter,
  ]);

  const checkValidForm = useCallback(() => {
    const invalidSuperConsultants = assignedSuperConsultants.some(
      (x) => !x.email
    );
    if (
      !account ||
      !devis ||
      !task ||
      !selectedWebsite ||
      !numberLinks ||
      !netlinkingLanguage ||
      !netlinkingDeadline ||
      invalidSuperConsultants
    ) {
      setDisabledForm(true);
      return;
    }

    setDisabledForm(false);
    return;
  }, [
    account,
    devis,
    task,
    selectedWebsite,
    numberLinks,
    netlinkingLanguage,
    netlinkingDeadline,
    assignedConsultants,
    assignedSuperConsultants,
    toBeVerifier,
  ]);

  useEffect(
    () => checkValidForm(),
    [
      account,
      detailedBrief,
      devis,
      selectedWebsite,
      orderLanguage,
      task,
      wantedLinks,
      numberLinks,
      orderThematics,
      titled,
      assignedWriters,
      maxNumber,
      orderType,
      contentType,
      netlinkingLanguage,
      netlinkingDeadline,
      assignedConsultants,
      toBeVerifier,
      assignedSuperConsultants,
      checkValidForm,
    ]
  );

  function handleLanguageChange(newLanguage) {
    setNetlinkingLanguage(newLanguage);
  }

  const clearAssignedWritersList = () => {
    setAssignedWriters([
      {
        writer: null,
        price: 0,
        deadline: null,
        numberLinks: 0,
        minWords: 0,
        maxWords: 0,
        actualBilling: false,
        wordsManagedNumber: 0,
      },
    ]);
  };

  const callbackFromMultiSelect = (newList) => {
    setOrderThematics(newList);
  };

  const CreateCampaign = async () => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
    const body = {
      account: account,
      devisName: devis.devisName,
      idDevis: devis.devisId,
      task: task.tacheId,
      taskName: task.tacheName,
      taskQuantity: task.tacheQuantity,
      languageId: getLanguageIdByName(netlinkingLanguage),
      websites: [selectedWebsite.urlSite],
      deadLine: netlinkingDeadline,
      toBeVerified: toBeVerifier,
      linksNumbers: numberLinks,
      briefGlobal: detailedBrief,
      themes: orderThematics.map((themes) => themes.theme),
      consultants: assignedConsultants.map((consultant) => consultant.email),
      superConsultants: assignedSuperConsultants.map(
        (superConsultant) => superConsultant.email
      ),
      minTF: minTFValue,
      maxTF: maxTFValue,
      minTraffic: minTraficValue,
      maxTraffic: maxTraficValue,
      minKeywordPositioned: minKwValue,
      maxKeywordPositioned: maxKwValue,
      minTarifRC: minTarifRCValue,
      maxTarifRC: maxTarifRCValue,
      minTarifHR: minTarifHRValue,
      maxTarifHR: maxTarifHRValue,
      wantedNetlinkingsUrl: wantedLinks,
      wantedNetlinkings: wantedLinksList,
      historicBackLinkingsUrl: historicBackLink,
      historicBackLinkings: historicBackLinkList,
      type: netlinkingType.length > 0 ? netlinkingType : 'standard',
    };

    axiosPixLink
      .post(API.createCampaign, body)
      .then((res) => {
        setErrorSuccessMsg(
          `${t('La campagne pour le client')} ${body.account} ${t(
            'a été créé avec succès'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/01_CommandeCrée.gif');
        setIsSuccess(true);
        setErrorSuccess(true);
        setLoader(false);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              t('Le titre de la campaign existe déjà (devisID + TaskID)')
            );
            break;
          case 486:
            setErrorSuccessMsg(
              t('le site web') +
              ' ' +
              e.response.data[0]['domain'] +
              ' ' +
              t(
                'existe dans la liste des spots séléctionnés par client et des backlinks historiques'
              )
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }

        setIsSuccess(false);
        setErrorSuccess(true);
        setLoader(false);
      });
  };

  return (
    <div>
      <div className="content-center">
        <div className="box-header-top">
          <span onClick={history.goBack} className="bt-circle me-3" />
          <h1>{t('Nouvelle Campagne')}</h1>
        </div>
        {loader && <Loader />}
        <div className="row">
          <div className="box-img-bg bg-2" />
          <div className="box-img-bg bg-1" />
          <div className="col-12 col-md-12 box-screen">
            <div className="box-img-bg bg-3" />
            <div className="box-wrapper">
              <div className="header-title">{t('Titre de la commande')}*</div>
              <div className="row row-mb">
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('Compte client')}*</div>
                  <CustomAutocomplete
                    disabled={accountsList?.length <= 0}
                    list={accountsList}
                    value={account || ''}
                    setValue={(value) => setAccount(value)}
                    getOptionSelected={(option) => option === account}
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return option;
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Réf devis')}*</div>
                  <CustomAutocomplete
                    disabled={!devisList || devisList?.length <= 0}
                    list={devisList}
                    value={devis}
                    setValue={(option) => setDevis(option)}
                    getOptionSelected={(option) =>
                      option.devisName === devis.devisName
                    }
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return option.devisName;
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Tâche - id tâche')}*</div>
                  <CustomAutocomplete
                    disabled={!devis}
                    list={
                      devisList &&
                        devis &&
                        devisList.filter((x) => x.devisName === devis.devisName)
                          ?.length > 0
                        ? devisList.find((x) => x.devisName === devis.devisName)
                          .taches
                        : []
                    }
                    value={task}
                    setValue={(option) => {
                      setTask(option);
                      setNumberLinks(option.tacheQuantity);
                    }}
                    getOptionSelected={(option) =>
                      option.tacheId === task.tacheId
                    }
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return (
                        option.tacheName +
                        ' - ' +
                        option.tacheId +
                        ' - ' +
                        option.tacheQuantity
                      );
                    }}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('URL du site')}*</div>
                  <CustomAutocomplete
                    disabled={websiteList?.length <= 0}
                    list={websiteList}
                    value={selectedWebsite}
                    setValue={(option) => {
                      setSelectedWebsite(option);
                      /*setConsultant(option ? option.consultant : null);
                      setOrderThematics(option ? option.thematics : []);*/
                    }}
                    getOptionSelected={(option) =>
                      option.id === selectedWebsite.id
                    }
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                      return option.urlSite;
                    }}
                  />
                </div>
              </div>
              <div className="row row-mb">
                <div className="col-12 col-md-5">
                  <div className="label-title">
                    {t('Thématiques d’expertise')}*
                  </div>
                  <CustomChipsAutocomplete
                    callbackFromMultiSelect={callbackFromMultiSelect}
                    value={orderThematics}
                    disabled={!availableThematicsList}
                    list={availableThematicsList}
                    setValue={(option) => setOrderThematics(option)}
                    getOptionLabel={(option) => {
                      return t(option.theme);
                    }}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('DeadLine')}</div>
                  <Calendar
                    onValueChange={handleContactedTimeChange}
                  ></Calendar>
                </div>
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('Nombre de liens')}*</div>
                  <div className="number-wrapper">
                    <input
                      min="0"
                      type="number"
                      value={numberLinks}
                      onChange={(e) => setNumberLinks(e.target.value)}
                      className="form-control form-ct"
                    />
                    <UpDownInputArrows disabled={false} />
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('Langue')}</div>
                  <div>
                    <LanguageSearchFieldEdit
                      onLanguageChange={handleLanguageChange}
                      editvalue={netlinkingLanguage}
                    ></LanguageSearchFieldEdit>
                  </div>
                </div>
              </div>
              {assignedSuperConsultants.map((consultant, index) => (
                <AssignConsultantEdit
                  entityName={'Super Consultant'}
                  consultantsList={superConsultantsList.filter(
                    (item) =>
                      !assignedSuperConsultants.some(
                        (item2) => item2.email === item.email
                      )
                  )}
                  consultant={consultant}
                  id={index}
                  new={true}
                  data={consultant}
                  account={account}
                  key={index}
                  addConsultant={() =>
                    setAssignedSuperConsultants([
                      ...assignedSuperConsultants,
                      {
                        email: '',
                        firstName: '',
                        lastName: '',
                      },
                    ])
                  }
                  updateConsultantList={(type, object) => {
                    const newValue = consultant;
                    newValue[type] = object;
                    const newList = assignedSuperConsultants;
                    newList[index] = newValue;
                    setAssignedSuperConsultants([...newList]);
                  }}
                  isDeleteable={assignedSuperConsultants.length > 1}
                  deleteConsultant={(index) => {
                    if (assignedSuperConsultants.length > 1) {
                      let newList = assignedSuperConsultants;
                      newList.splice(index, 1);
                      setAssignedSuperConsultants([...newList]);
                    }
                  }}
                />
              ))}

              {assignedConsultants.map((consultant, index) => (
                <AssignConsultantEdit
                  entityName={'Consultant'}
                  consultantsList={consultantsList.filter(
                    (item) =>
                      !assignedConsultants.some(
                        (item2) => item2.email === item.email
                      )
                  )}
                  consultant={consultant}
                  setValue={(option) => setConsultant(option)}
                  id={index}
                  list={consultantsList.filter(
                    (item) =>
                      !consultantsList.some(
                        (item2) => item2.email === item.email
                      )
                  )}
                  new={true}
                  data={consultant}
                  account={account}
                  key={index}
                  addConsultant={() =>
                    setAssignedConsultants([
                      ...assignedConsultants,
                      {
                        email: '',
                        firstName: '',
                        lastName: '',
                      },
                    ])
                  }
                  updateConsultantList={(type, object) => {
                    const newValue = consultant;
                    newValue[type] = object;
                    const newList = assignedConsultants;
                    newList[index] = newValue;
                    setAssignedConsultants([...newList]);
                  }}
                  isDeleteable={assignedConsultants.length > 1}
                  deleteConsultant={(index) => {
                    if (assignedConsultants.length > 1) {
                      let newList = assignedConsultants;
                      newList.splice(index, 1);
                      setAssignedConsultants([...newList]);
                    }
                  }}
                />
              ))}
              <div className="row">
                <div className="col-12 col-md-12 box-screen">
                  <div>
                    <div className="row row-mb">
                      <div className="col-12 col-md-6">
                        <div className="label-title">
                          {t('Type de spots')} :
                        </div>
                        <TypeCheckBoxGroupCampaign
                          onCheckboxChange={handleTypeCheckboxChange}
                          initialStateString={netlinkingType}
                        />
                      </div>
                      <div className="col-12 col-md-2">
                        <div className="label-title">
                          {t('Validation par super admin')} :
                        </div>
                        <CheckBox
                          name="Checkbox 2"
                          tick={toBeVerifier}
                          onCheck={handleTobeVerifiedChange}
                        ></CheckBox>
                      </div>
                    </div>

                    {activeAdvancedFilter && (
                      <div>
                        <div className="row row-mb">
                          {/* First Slider */}
                          <SliderComponent
                            SliderName={'TF'}
                            values={TFValues}
                            handleSliderChange={handleSliderTFChange}
                            minValue={minTFValue}
                            maxValue={maxTFValue}
                            handleMinInputChange={handleMinTFInputChange}
                            handleMaxInputChange={handleMaxTFInputChange}
                            SliderLabel={'TF'}
                          />

                          {/* Second Slider */}

                          <SliderComponent
                            SliderName={t('Trafic')}
                            values={TraficValues}
                            handleSliderChange={handleSliderTraficChange}
                            minValue={minTraficValue}
                            maxValue={maxTraficValue}
                            handleMinInputChange={handleMinTraficInputChange}
                            handleMaxInputChange={handleMaxTraficInputChange}
                            SliderLabel={'Trafic'}
                          />

                          {/* Third Slider */}
                          <SliderComponent
                            SliderName={t('Mots clés positionnés')}
                            values={Kwvalues}
                            handleSliderChange={handleSliderChange}
                            minValue={minKwValue}
                            maxValue={maxKwValue}
                            handleMinInputChange={handleMinInputChange}
                            handleMaxInputChange={handleMaxInputChange}
                            SliderLabel={'keywords positioned'}
                          />
                        </div>
                        <div className="row row-mb">
                          <SliderComponent
                            SliderName={t('Tarif') + ' RC'}
                            values={TarifRCValues}
                            handleSliderChange={handleSliderTarifRCChange}
                            minValue={minTarifRCValue}
                            maxValue={maxTarifRCValue}
                            handleMinInputChange={handleMinTarifRCInputChange}
                            handleMaxInputChange={handleMaxTarifRCInputChange}
                            SliderLabel={'Tarif RC'}
                          />{' '}
                          <SliderComponent
                            SliderName={t('Tarif') + ' HR'}
                            values={TarifHRValues}
                            handleSliderChange={handleSliderTarifHRChange}
                            minValue={minTarifHRValue}
                            maxValue={maxTarifHRValue}
                            handleMinInputChange={handleMinTarifHRInputChange}
                            handleMaxInputChange={handleMaxTarifHRInputChange}
                            SliderLabel={'Tarif HR'}
                          />
                          <div className="box-button-bt">
                            <div>
                              <button
                                className="bt-submit-y"
                                onClick={resetFunction}
                              >
                                {t('Réinitialiser')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row-baseline row row-mb">
                <div className="col-12 col-md-2">
                  <div className="label-title">{t('Brief détaillé')}</div>
                  <div>
                    <input
                      type="text"
                      value={detailedBrief}
                      placeholder={t('Lien drive...')}
                      onChange={(e) => setDetailedBrief(e.target.value)}
                      className="form-control form-control-valid form-ct form-control-c"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className={'label-title'}>
                    {t('Spots séléctionnés par client')}
                  </div>
                  <div className="row-start row row-mb border-input-c">
                    <div className={'col-12 col-md-8'}>
                      <input
                        type="text"
                        placeholder={t('Lien drive...')}
                        value={wantedLinks}
                        onChange={(e) => setWantedLinks(e.target.value)}
                        onFocus={() => setWantedLinksFocus(true)}
                        onBlur={() => setWantedLinksFocus(false)}
                        className={'form-control-b form-control-valid form-ct'}
                      />
                    </div>
                    <div className={'col-12 col-md-4'}>
                      <div>
                        <span>
                          <ExcelUploaderCampaign
                            handleLinksChange={handleWantedLinksChange}
                            sendFileName={(name) => {
                              setWantedLinks(name);
                            }}
                            type="wantedLinks"
                          ></ExcelUploaderCampaign>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-5">
                  <div className="label-title">{t('Historique Backlinks')}</div>
                  <div className="row-start row row-mb border-input-c">
                    <div className={'col-12 col-md-8'}>
                      <input
                        type="text"
                        placeholder={t('Lien drive...')}
                        value={historicBackLink}
                        onChange={(e) => setHistoricBackLink(e.target.value)}
                        className="form-control-b form-control-valid form-ct"
                      />
                    </div>
                    <div className={'col-12 col-md-4'}>
                      <span>
                        <ExcelUploaderCampaign
                          handleLinksChange={handleHistoricBackLinksChange}
                          sendFileName={(name) => {
                            setHistoricBackLink(name);
                          }}
                          type="backlinks"
                        ></ExcelUploaderCampaign>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-button-bt">
              <div className="me-4">*{t('Champs obligatoires')}</div>
              <div>
                {disabledForm ? (
                  <button className="bt-submit-disabled-y">
                    {t('Sauvegarder')}
                  </button>
                ) : (
                  <button className="bt-submit-y" onClick={CreateCampaign}>
                    {t('Sauvegarder')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isSuccess ? history.push('/') : setErrorSuccess(false)
        }
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
        imageUrl={errorSuccessImg}
      />
    </div>
  );
}
