export function splitThemes(inputString) {
  // Split the input string by commas
  if (inputString && inputString?.length > 0) {
    const themesArray = inputString.split(',').map((theme) => theme.trim());

    return themesArray;
  } else {
    return [];
  }
}
