import { createSlice } from '@reduxjs/toolkit';
import { fetchText } from './features';

export const adminAnalyzer = createSlice({
  name: 'adminAnalyzer',
  initialState: {
    orderId: null,
    orderType: null,
    textForm: null,
    isLoading: true,
    isError: true,
  },
  reducers: {
    updateForm: (state, { payload }) => {
      state.textForm = payload;
      state.isLoading = false;
      state.isError = false;
    },
    updateTextForm: (state, { payload }) => {
      let object = { ...state.textForm };
      object[payload.key] = payload.value;
      state.textForm = { ...object };
    },
    setOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setOrderType: (state, { payload }) => {
      state.orderType = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchText.fulfilled, (state, { payload }) => {
        state.textForm = payload;
        state.isLoading = false;
      })
      .addCase(fetchText.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchText.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default adminAnalyzer.reducer;

export const { updateForm, updateTextForm, setOrderId, setOrderType } =
  adminAnalyzer.actions;
export const adminAnalyzerSelector = (state) => state.adminAnalyzer;
