export const UpDownInputArrows = (props) => {
  if (props.disabled === true)
    return (
      <>
        <div
          style={{ backgroundColor: 'rgb(224,224,224)', color: 'grey' }}
          id="inc-button"
          className="spinner-button"
        >
          <i className="bi bi-chevron-up" />
        </div>
        <div
          style={{ backgroundColor: 'rgb(224,224,224)', color: 'grey' }}
          id="dec-button"
          className="spinner-button"
        >
          <i className="bi bi-chevron-down" />
        </div>
      </>
    );

  return (
    <>
      <div id="inc-button" className="spinner-button">
        <i style={{ cursor: 'pointer' }} className="bi bi-chevron-up" />
      </div>
      <div id="dec-button" className="spinner-button">
        <i style={{ cursor: 'pointer' }} className="bi bi-chevron-down" />
      </div>
    </>
  );
};
