import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './IRangeDataPicker.css';

const RangeDatePicker = ({
  initialStartDate,
  initialEndDate,
  onDatesChange,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    setStartDate(initialStartDate);
  }, [initialStartDate]);

  useEffect(() => {
    setEndDate(initialEndDate);
  }, [initialEndDate]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onDatesChange(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onDatesChange(startDate, date);
  };

  return (
    <div className="range-date-picker">
      <div style={{ marginRight: '25px', width: '100%' }}>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="date-picker"
        />
      </div>
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="End Date"
        className="date-picker"
      />
    </div>
  );
};

export default RangeDatePicker;
