import { createSlice } from '@reduxjs/toolkit';
import { fetchUserSession, fetchUser } from './features';

const userData = JSON.parse(localStorage.getItem('user'));
export const UserSession = createSlice({
  name: 'userSession',
  initialState: {
    user: userData ? userData.user : null,
    accessToken: userData ? userData.accessTokenJwt : null,
    limit: null,
    analysis: null,
    showTutorial: null,
    flag: null,
    isLoading: true,
    isError: false,
  },

  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserAnalysisCount: (state, action) => {
      state.analysis = action.payload;
    },
    updateShowTutorial: (state, action) => {
      state.showTutorial = action.payload;
    },
    updateFlag: (state, action) => {
      state.flag = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSession.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.role = payload.role;
        state.accessToken = payload.accessTokenJwt;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchUserSession.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
        state.notFound = false;
      })
      .addCase(fetchUserSession.rejected, (state, { payload }) => {
        state.notFound = true;
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user = payload?.user;
        state.role = payload?.user?.role;
        state.limit = payload?.user?.limit;
        state.analysis = payload?.user?.analysis;
        state.accessToken = payload?.accessTokenJwt;
        state.showTutorial = payload?.user?.showTutorial;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchUser.pending, (state, { payload }) => {
        state.user = null;
        state.accessToken = null;
        state.limit = null;
        state.analysis = null;
        state.showTutorial = null;
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchUser.rejected, (state, { payload }) => {
        state.user = null;
        state.accessToken = null;
        state.limit = null;
        state.analysis = null;
        state.showTutorial = null;
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default UserSession.reducer;

export const {
  updateUser,
  updateUserAnalysisCount,
  updateShowTutorial,
  updateFlag,
} = UserSession.actions;
export const UserSessionSelector = (state) => state.userSession;
