import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18next';
import { UserSessionSelector } from 'store/UserSession';

const useLanguage = () => {
  const { user } = useSelector(UserSessionSelector);
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const languageFromStorage = localStorage.getItem('language');
    const languageFromdb = user.isoLanguageCode;

    if (!languageFromStorage) {
      i18n.changeLanguage(languageFromdb);
      setLanguage(languageFromdb);
    }
    if (languageFromStorage && i18n.language !== languageFromStorage) {
      i18n.changeLanguage(languageFromStorage);
      setLanguage(languageFromStorage);
    }
  }, [user.isoLanguageCode]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return { language, changeLanguage };
};

export default useLanguage;
