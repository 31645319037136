import { PIXLINK_STATUS } from 'utils/Constants';

export const calculateSuperConsultantValidateClient = (netlinkings) => {
  return netlinkings.filter(
    (item) =>
      item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
      item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
  ).length >
    netlinkings.filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
        item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT
    ).length
    ? netlinkings.filter(
        (item) =>
          item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
          item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
      ).length -
        netlinkings.filter(
          (item) =>
            item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
            item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT
        ).length
    : netlinkings.filter(
        (item) =>
          item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
          item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
      ).length;
};
