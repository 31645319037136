import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { AssignClient } from 'components/shared/ClientConsultantsAssignement/AssignClient';
import {
  CustomAutocomplete,
  CustomAutocompleteFreeSolo,
} from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import { useHistory } from 'react-router-dom';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'utils/Constants';
import axiosPixLink from 'config/axiosPixLink';
import { AssignConsultant } from 'components/shared/ClientConsultantsAssignement/AssignConsultant';
import { Loader } from 'components/shared/Loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AssignClientEdit } from 'components/shared/ClientConsultantsAssignement/AssignClientEdit';
import { AssignConsultantEdit } from 'components/shared/ClientConsultantsAssignement/AssignConsultantEdit';
import { LanguageSearchField } from 'components/Netlinking/AutoComplete/languageAutoComplete';
import {
  getLanguageIdByName,
  getLanguageNameById,
} from 'utils/netlinking/languages';
import { LanguageSearchFieldEdit } from 'components/Netlinking/AutoComplete/languageAutoCompleteEdit';

import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { LanguageSearchFieldDisabled } from 'components/Netlinking/AutoComplete/languageAutoCompleteDisabled';
import { AssignClientEditDisabled } from 'components/shared/ClientConsultantsAssignement/AssignClientEditDisabled';
import { AssignConsultantDisabled } from 'components/shared/ClientConsultantsAssignement/AssignConsultantEditDisabled';
const API = {
  pixopClients: '/user/pixOpsClient',
  getAllConsultants: '/user/consultants',
  getAllSuperConsultants: 'user/superconsultants',
  getAllClients: '/user/clients',
  getAvailableThematics: '/netlinking/all-thematics',
  updateWebsite: '/website/create',
};

export default function ConsultantFicheClient() {
  const history = useHistory();
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [pixOpData, setPixOpData] = useState([]);
  const [disabledForm, setDisabledForm] = useState(true);

  const [accountsList, setAccountsList] = useState([]);
  const [account, setAccount] = useState(null);

  const [urlSitesList, setUrlSitesList] = useState([]);

  const [urlSite, setUrlSite] = useState(null);
  const [urlWebsite, setUrlWebsite] = useState(null);

  const [consultant, setConsultant] = useState(null);
  const [consultantsList, setConsultantsList] = useState([]);
  const [superConsultantsList, setSuperConsultantsList] = useState([]);

  const [clientsList, setClientsList] = useState([]);

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [websiteThematics, setWebsiteThematics] = useState([]);

  const [serviceDetails, setServiceDetails] = useState('');
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');

  const [coconsTextNbr, setCoconsTextNbr] = useState(0);
  const [coconsNbr, setCoconsNbr] = useState(0);
  const [faqNbr, setFaqNbr] = useState(0);
  const [gmbNbr, setGmbNbr] = useState(0);
  const [textOptimizerNbr, setTextOptimizerNbr] = useState(0);
  const [loader, setLoader] = useState(false);

  const validationSchema = yup.object().shape({
    coconsTextNbr: yup.number().min(0).required(),
    coconsNbr: yup.number().min(0).required(),
    faqNbr: yup.number().min(0).required(),
    gmbNbr: yup.number().min(0).required(),
    textOptimizerNbr: yup.number().min(0).required(),
  });

  const [assignedClients, setAssignedClients] = useState([
    {
      name: '',
      email: '',
      phoneNumber: '',
      isNew: false,
    },
  ]);

  const [assignedConsultants, setAssignedConsultants] = useState([
    {
      name: '',
      firstName: '',
      lastName: '',
      isNew: false,
    },
  ]);
  const [assignedSuperConsultants, setAssignedSuperConsultants] = useState([
    {
      name: '',
      firstName: '',
      lastName: '',
      isNew: false,
    },
  ]);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const DeleteWebsite = () => {
    axiosPixLink
      .delete(`/website/${id}/delete`)
      .then(() => {
        setConfirmation(false);

        setErrorSuccessMsg(
          `${t('Le contrat de')} ${urlWebsite} ${t(
            'a été supprimé avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
        history.push('/Consultant/MainMenu');
      })
      .catch((e) => {
        setConfirmation(false);
        switch (e.response.status) {
          case 500:
            setErrorSuccessMsg(
              t("il y'a des campagnes liés au site web ") + urlWebsite
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));

    axiosPixLink.get(`/website/${id}/get`).then((res) => {
      setAccount(res.data['account']);
      setUrlWebsite(res.data['urlSite']);
      setNetlinkingLanguage(getLanguageNameById(res.data['languageId']));
      res.data['clients'].forEach((client) => {
        client.name = client.firstName + ' ' + client.lastName;
        client.initialName = client.firstName + ' ' + client.lastName;
      });
      setAssignedClients(res.data['clients']);
      setAssignedConsultants(
        res.data['consultants'].length > 0
          ? res.data['consultants']
          : [
            {
              name: '',
              firstName: '',
              lastName: '',
              isNew: false,
            },
          ]
      );
      setAssignedSuperConsultants(res.data['superConsultants']);
      setWebsiteThematics(res.data['themes']);
      setServiceDetails(res.data.serviceDetails);
    });
    axiosPixLink.get(API.pixopClients).then((res) => {
      setPixOpData(res.data);
      setAccountsList(res.data.map((x) => x.clientName));
    });

    axiosPixLink.get(API.getAllConsultants).then((res) => {
      setConsultantsList(res.data);
    });
    axiosPixLink.get(API.getAllSuperConsultants).then((res) => {
      setSuperConsultantsList(res.data);
    });

    axiosPixLink
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, []);

  useEffect(() => {
    // setUrlSite(undefined);

    if (account) {
      if (pixOpData.length > 0)
        setUrlSitesList(pixOpData.find((x) => x.clientName === account)?.site);

      axiosPixLink
        .get(API.getAllClients, { params: { account: account } })
        .then((res) => {
          setClientsList(res.data);
        });
    }
    setUrlSite('');
    setConsultant(null);
    setCoconsTextNbr('');
    setCoconsNbr('');
    setTextOptimizerNbr('');
    setFaqNbr('');
    setGmbNbr('');
  }, [account, pixOpData]);

  useEffect(() => {
    const checkValidForm = async () => {
      const invalidClients = assignedClients.some((x) => !x.email);
      const invalidSuperConsultants = assignedSuperConsultants.some(
        (x) =>
          !x.firstName ||
          !x.lastName ||
          !x.email ||
          !x.firstName.length ||
          !x.lastName.length ||
          !x.email.length
      );

      if (
        !account ||
        !urlWebsite ||
        !netlinkingLanguage ||
        websiteThematics.length === 0 ||
        invalidClients ||
        invalidSuperConsultants ||
        assignedClients.length === 0 ||
        assignedSuperConsultants.length === 0
      ) {
        setDisabledForm(true);
      } else {
        setDisabledForm(false);
      }
    };
    checkValidForm();
  }, [
    urlSite,
    urlWebsite,
    account,
    consultant,
    websiteThematics,
    serviceDetails,
    assignedClients,
    validationSchema,
    coconsTextNbr,
    coconsNbr,
    faqNbr,
    gmbNbr,
    textOptimizerNbr,
    netlinkingLanguage,
  ]);

  const callbackFromMultiSelect = (newList) => {
    setWebsiteThematics(newList);
  };
  function handleLanguageChange(newLanguage) {
    setNetlinkingLanguage(newLanguage);
  }

  const updateWebsite = () => {
    setLoader(true);
    const filteredAssignedSuperConsultants = assignedSuperConsultants.filter(
      (item) => item.email !== ''
    );
    const filteredAssignedConsultants = assignedConsultants.filter(
      (item) => item.email !== ''
    );

    const body = {
      account: account,
      urlWebsite: urlWebsite,
      thematics: websiteThematics,
      clients: assignedClients,
      superConsultants: filteredAssignedSuperConsultants,
      consultants: filteredAssignedConsultants,
      languageId: getLanguageIdByName(netlinkingLanguage),
      serviceDetails: serviceDetails,
    };
    axiosPixLink
      .put(`/website/${id}/update`, body)
      .then(() => {
        setIsSuccess(true);
        setErrorSuccessMsg(
          `${t('Le contrat pour le client')} ${account}  ${t(
            'a été mis a jour avec succès'
          )}!`
        );
        setLoader(false);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        console.log(e);
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setLoader(false);
        setErrorSuccess(true);
      });
    setLoader(false);
  };

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <div className="box-header-top header-info">
          <span onClick={history.goBack} className="bt-circle me-3" />
          <h1>{urlWebsite}</h1>
        </div>
      </div>
      {loader && <Loader />}
      <div className="row">
        <div className="col-12 col-md-10 box-screen">
          <div className="box-img-bg bg-8" />
          <div className="box-img-bg bg-7" style={{ zIndex: -1 }} />
          <div className="box-img-bg bg-9" />
          <div
            style={{ display: 'flex', alignItems: 'center', maxWidth: '280px' }}
          >
            <div className="header-title" style={{ marginTop: '4px' }}>
              {t('Fiche du site web')}
            </div>
          </div>
          <div className="box-wrapper">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Compte client')}* </div>
                    <CustomAutocomplete
                      disabled={true}
                      list={accountsList}
                      value={account || ''}
                      setValue={(value) => setAccount(value)}
                      getOptionSelected={(option) => option === account}
                      getOptionDisabled={(option) => false}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Url du site')}*</div>
                    <CustomAutocompleteFreeSolo
                      disabled={true}
                      list={urlSitesList}
                      value={urlWebsite}
                      getOptionSelected={(option) => option === urlWebsite}
                      onInputChange={(value) => {
                        if (typeof value?.target?.value === 'string') {
                          setUrlWebsite(value?.target?.value);
                        } else {
                          /*setUrlWebsite(value);*/
                        }
                        /*setUrlSite(value);*/
                      }}
                      setValue={(option) => {
                        setUrlSite(option);
                        setUrlWebsite(option);
                      }}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                    />
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-2">
                    <div className="label-title">{t('Langue')}*</div>
                    <div>
                      <LanguageSearchFieldDisabled
                        onLanguageChange={handleLanguageChange}
                        editvalue={netlinkingLanguage}
                      ></LanguageSearchFieldDisabled>
                    </div>
                  </div>
                </div>
                {assignedClients.map((client, index) => (
                  <AssignClientEditDisabled
                    id={index}
                    list={clientsList.filter(
                      (item) =>
                        !assignedClients.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    new={true}
                    data={client}
                    account={account}
                    key={index}
                    addClient={() =>
                      setAssignedClients([
                        ...assignedClients,
                        {
                          name: '',
                          firstName: '',
                          lastName: '',
                          email: '',
                          phoneNumber: '',
                        },
                      ])
                    }
                    updateClientsList={(type, object) => {
                      const newValue = client;
                      newValue[type] = object;
                      const newList = assignedClients;
                      newList[index] = newValue;
                      setAssignedClients([...newList]);
                    }}
                    isDeleteable={assignedClients.length > 1}
                    deleteClient={(index) => {
                      if (assignedClients.length > 1) {
                        let newList = assignedClients;
                        newList.splice(index, 1);
                        setAssignedClients([...newList]);
                      }
                    }}
                  />
                ))}
                {assignedSuperConsultants.map((consultant, index) => (
                  <AssignConsultantDisabled
                    entityName={'Super Consultant*'}
                    consultantsList={superConsultantsList.filter(
                      (item) =>
                        !assignedSuperConsultants.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    consultant={consultant}
                    id={index}
                    new={true}
                    data={consultant}
                    account={account}
                    key={index}
                    addConsultant={() =>
                      setAssignedSuperConsultants([
                        ...assignedSuperConsultants,
                        {
                          email: '',
                          firstName: '',
                          lastName: '',
                        },
                      ])
                    }
                    updateConsultantList={(type, object) => {
                      const newValue = consultant;
                      newValue[type] = object;
                      const newList = assignedSuperConsultants;
                      newList[index] = newValue;
                      setAssignedSuperConsultants([...newList]);
                    }}
                    isDeleteable={assignedSuperConsultants.length > 1}
                    deleteConsultant={(index) => {
                      if (assignedSuperConsultants.length > 1) {
                        let newList = assignedSuperConsultants;
                        newList.splice(index, 1);
                        setAssignedSuperConsultants([...newList]);
                      }
                    }}
                  />
                ))}

                {assignedConsultants.map((consultant, index) => (
                  <AssignConsultantDisabled
                    entityName={'Consultant'}
                    consultantsList={consultantsList.filter(
                      (item) =>
                        !assignedConsultants.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    consultant={consultant}
                    setValue={(option) => setConsultant(option)}
                    id={index}
                    list={consultantsList.filter(
                      (item) =>
                        !consultantsList.some(
                          (item2) => item2.email === item.email
                        )
                    )}
                    new={true}
                    data={consultant}
                    account={account}
                    key={index}
                    addConsultant={() =>
                      setAssignedConsultants([
                        ...assignedConsultants,
                        {
                          email: '',
                          firstName: '',
                          lastName: '',
                        },
                      ])
                    }
                    updateConsultantList={(type, object) => {
                      const newValue = consultant;
                      newValue[type] = object;
                      const newList = assignedConsultants;
                      newList[index] = newValue;
                      setAssignedConsultants([...newList]);
                    }}
                    isDeleteable={assignedConsultants.length > 1}
                    deleteConsultant={(index) => {
                      if (assignedConsultants.length > 1) {
                        let newList = assignedConsultants;
                        newList.splice(index, 1);
                        setAssignedConsultants([...newList]);
                      }
                    }}
                  />
                ))}

                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="label-title">
                      {t('Thématiques d’expertise')}*
                    </div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromMultiSelect}
                      value={websiteThematics}
                      disabled={true}
                      list={availableThematicsList}
                      setValue={(option) => setWebsiteThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.theme) || '';
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="label-title">
                      {t('Détail de la prestation')}
                    </div>
                    <div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control form-ct"
                        value={serviceDetails}
                        onChange={(e) => setServiceDetails(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button-bt">
            <div>
              <button className="bt-submit-disabled-y">
                {' '}
                {t('Sauvegarder')}{' '}
              </button>
            </div>
          </div>
          <div className="box-button-bt" style={{ marginRight: '1rem' }}>
            <div>
              <button
                className="bt-submit-y"
                onClick={() => {
                  history.push('/Consultant/Website/' + id + '/Campagnes');
                }}
              >
                {' '}
                {t('Voir les campagnes')}{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() =>
          isSuccess ? history.push('/') : setErrorSuccess(false)
        }
        toggle={() => setErrorSuccess(!errorSuccess)}
        success={isSuccess}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={DeleteWebsite}
      />
    </div>
  );
}
