import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';
import { useSelector } from 'react-redux';
import { UserSessionSelector } from 'store/UserSession';
import { UpDownInputArrows } from './UpDownInputArrows';

import { Box, Popover } from '@mui/material';
import { ROLES } from 'utils/Constants';
import { getDaysDifferenceFromToday } from 'utils/DateUtils';
import './shared.css';

const POSTPONE_DEADLINE_URI = '/commands/postponeFinishedAt/';

export const CircularBar = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [daysLeft, setDaysLeft] = useState(15);
  const [numberOfDays, setNumberOfDays] = useState(0);
  // const [addDays, setAddDays] = useState(false);

  let { user } = useSelector(UserSessionSelector);

  const postponeDeadline = () => {
    setLoading(true);
    axiosPrivate
      .put(POSTPONE_DEADLINE_URI + props.orderId, '', {
        params: { numberOfDays: parseInt(numberOfDays) },
      })
      .then((res) => {
        const nbDays = getDaysDifferenceFromToday(new Date(res.data));
        setDaysLeft(nbDays);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.finishedAt) {
      const nbDays = getDaysDifferenceFromToday(new Date(props.finishedAt));
      setDaysLeft(nbDays);
    }
  }, [props]);

  if (user && user.role === ROLES.ADMIN) {
    return (
      <div>
        {props.finishedAt ? (
          daysLeft > 0 ? (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <span
                    style={{ cursor: 'pointer' }}
                    {...bindTrigger(popupState)}
                    className="text-orange bt-clock"
                  >
                    <img src="Images/icon-clock-orange.png" alt="icon-clock" />{' '}
                    {daysLeft}
                  </span>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2}>
                      <div className="col-12">
                        <div className="label-title">
                          {t('Nombre de Jours')}
                        </div>
                        <div className="number-wrapper">
                          <input
                            type="number"
                            className="form-control form-ct"
                            min="0"
                            value={numberOfDays}
                            onChange={(e) => setNumberOfDays(e.target.value)}
                          />
                          <UpDownInputArrows disabled={false} />
                        </div>
                      </div>
                      <div className="col-12">
                        {loading || numberOfDays <= 0 ? (
                          <button className="bt-submit-disabled float-end my-2">
                            {' '}
                            {t('Sauvegarder')}{' '}
                          </button>
                        ) : (
                          <button
                            className="bt-submit float-end my-2"
                            onClick={postponeDeadline}
                          >
                            {' '}
                            {t('Sauvegarder')}{' '}
                          </button>
                        )}
                      </div>
                    </Box>
                  </Popover>
                </div>
              )}
            </PopupState>
          ) : (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <span
                    style={{ cursor: 'pointer' }}
                    {...bindTrigger(popupState)}
                    className="text-darkorange bt-clock"
                  >
                    <img
                      src="Images/icon-clock-darkorange.png"
                      img="icon-black"
                      alt="dark orange"
                    />{' '}
                    {t('délai dépassé')}
                  </span>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2}>
                      <div className="col-12">
                        <div className="label-title">
                          {t('Nombre de Jours')}
                        </div>
                        <div className="number-wrapper">
                          <input
                            type="number"
                            className="form-control form-ct"
                            min="0"
                            value={numberOfDays}
                            onChange={(e) => setNumberOfDays(e.target.value)}
                          />
                          <UpDownInputArrows disabled={false} />
                        </div>
                      </div>
                      <div className="col-12">
                        {loading || numberOfDays <= 0 ? (
                          <button className="bt-submit-disabled float-end my-2">
                            {' '}
                            {t('Sauvegarder')}{' '}
                          </button>
                        ) : (
                          <button
                            className="bt-submit float-end my-2"
                            onClick={postponeDeadline}
                          >
                            {' '}
                            {t('Sauvegarder')}{' '}
                          </button>
                        )}
                      </div>
                    </Box>
                  </Popover>
                </div>
              )}
            </PopupState>
          )
        ) : (
          <span style={{ color: 'green' }} className="text-orange bt-clock">
            <img src="Images/icon-clock-green.png" alt="icon-clock" />{' '}
          </span>
        )}
      </div>
    );
  }

  if (user && user.role !== ROLES.ADMIN) {
    return (
      <div>
        {props.finishedAt ? (
          daysLeft > 0 ? (
            <span className="text-orange bt-clock">
              <img src="Images/icon-clock-orange.png" alt="icon-clock" />{' '}
              {daysLeft}
            </span>
          ) : (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <span className="text-darkorange bt-clock">
                    <img
                      src="Images/icon-clock-darkorange.png"
                      img="icon-black"
                      alt="clock"
                    />{' '}
                    {t('délai dépassé')}
                  </span>
                </div>
              )}
            </PopupState>
          )
        ) : null}
      </div>
    );
  }
};
