export default function CheckBoxDisabled({ name, tick, onCheck }) {
  return (
    <div className="box-checkbox">
      <input
        className="form-check-input form-check-input-disabled"
        type="checkbox"
        name={name}
        checked={tick}
        onChange={onCheck}
        disabled={true}
      />
    </div>
  );
}
