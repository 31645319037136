import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosFacturation from "config/axiosFacturation";

export const getBills = createAsyncThunk(
  "bills/getAllBills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosFacturation.get(`/writer/getAllBills`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
