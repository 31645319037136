import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminWebsiteDetailsSelector } from 'store/Admin/WebsiteDisplay';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import axiosPixLink from 'config/axiosPixLink';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Loader } from 'components/shared/Loader';
import { PlatformAutoComplete } from 'components/Netlinking/AutoComplete/PlatformAutoComplete';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';

export default function AdminNetlinkingDetailShortcut() {
    const { id } = useParams();
    let { formData, isLoading, isError } = useSelector(
        adminWebsiteDetailsSelector
    );
    const { t } = useTranslation();
    const history = useHistory();
    const [urlSite, setUrlSite] = useState(null);
    const [netlinkingUpdateLoading, setNetlinkingUpdateLoading] = useState(false);
    const [errorSuccess, setErrorSuccess] = useState(false);
    const [errorSuccessDeleteForce, setErrorSuccessDeleteForce] = useState(false);
    const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
    const [errorSuccessMsgDeleteForce, setErrorSuccessMsgDeleteForce] =
        useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSuccessForceDelete, setIsSuccessForceDelete] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isDeletedForced, setIsDeletedForced] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [confirmationDeleteForce, setConfirmationDeleteForce] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState('');
    const [confirmationMsgDeleteForce, setConfirmationMsgDeleteForce] =
        useState('');
    const [netlinking, setNetlinking] = useState(null);

    const [netlinkingDomain, setNetlinkingDomain] = useState('');
    const [netlinkingKwPositioned, setNetlinkingKwPositioned] = useState('');
    const [netlinkingTrafic, setNetlinkingTrafic] = useState('');
    const [netlinkingTF, setNetlinkingTF] = useState('');
    const [netlinkingCF, setNetlinkingCF] = useState('');
    const [netlinkingTarifHr, setNetlinkingTarifHr] = useState('');
    const [netlinkingTarifRc, setNetlinkingTarifRc] = useState('');
    const [netlinkingContactedTime, setNetlinkingContactedTime] = useState('');
    const [netlinkingContact, setNetlinkingContact] = useState('');
    const [netlinkingThemesList, setNetlinkingThemesList] = useState('');
    const [netlinkingTopicsList, setNetlinkingTopicsList] = useState('');
    const [netlinkingPlatformHr, setNetlinkingPlatformHr] = useState('');
    const [netlinkingPlatformRc, setNetlinkingPlatformRc] = useState('');
    const [netlinkingPlatformName, setNetlinkingPlatformName] = useState('');
    const [netlinkingType, setNetlinkingType] = useState('');
    function handleTypeCheckboxChange(newStates) {
        if (
            !newStates.checkbox1 &&
            !newStates.checkbox2 &&
            !newStates.checkbox3 &&
            !newStates.checkbox0
        ) {
            setNetlinkingType('');
        }
        if (newStates.checkbox1) {
            setNetlinkingType('standard');
        } else if (newStates.checkbox2) {
            setNetlinkingType('premium');
        } else if (newStates.checkbox3) {
            setNetlinkingType('redType');
        } else if (newStates.checkbox0) {
            setNetlinkingType('ultraPremium');
        }
    }
    function handleNavBackClick() {
        history.goBack();
    }
    function handlePlatformChange(platformName) {
        setNetlinkingPlatformName(platformName);
    }
    const updateNetlinking = async () => {
        try {
            setNetlinkingUpdateLoading(true);
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Smooth scrolling animation
            });
            const response = await axiosPixLink
                .put(`/netlinking/${netlinking.id}`, {
                    id: netlinking.id,
                    domain: netlinking.domain,
                    kwPositioned: netlinkingKwPositioned,
                    trafic: netlinkingTrafic,
                    tF: netlinkingTF,
                    cF: netlinkingCF,
                    tarifHr: netlinkingTarifHr,
                    tarifRc: netlinkingTarifRc,
                    languageId: netlinking.languageId,
                    conditions: netlinking.conditions,
                    contactedTime: netlinking.contactedTime,
                    contact: netlinkingContact,
                    themesList: netlinkingThemesList,
                    topicsList: netlinkingTopicsList,
                    platformName: netlinkingPlatformName,
                    platformRC: netlinkingPlatformRc,
                    platformHR: netlinkingPlatformHr,
                    type: netlinkingType === 'redType' ? 'redList' : netlinkingType,
                })
                .then((res) => {
                    setIsSuccess(true);
                    setErrorSuccessMsg(t('La fiche du spot a été mise à jour'));
                    setErrorSuccess(true);
                    setNetlinkingUpdateLoading(false);
                    return res.data;
                });
        } catch (error) {
            if (error.response) {
                setIsSuccess(false);
                setErrorSuccessMsg('An Error Has Happened In the Server');
                setErrorSuccess(true);

                // Request was made, but the server responded with an error status
                console.error('Error:', error.response.data);
                setNetlinkingUpdateLoading(false);
            } else {
                setIsSuccess(false);
                setErrorSuccessMsg('An Error Has Happened In the Server');
                setErrorSuccess(true);
                // Something else happened while setting up the request
                console.error('Error:', error.message);
                setNetlinkingUpdateLoading(false);
            }
            throw error; // Rethrow the error to be handled by the caller
        }
    };

    const deleteWebsite = () => {
        setNetlinkingUpdateLoading(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling animation
        });
        axiosPixLink
            .delete(
                `/netlinking/delete/${window.location.href.split('/')[
                window.location.href.split('/').length - 1
                ]
                }`
            )
            .then(() => {
                setIsDeleted(true);
                setConfirmation(false);

                setErrorSuccessMsg(
                    `${t(
                        'Le contrat de'
                    )} ${netlinkingDomain} ${'a été supprimé avec succès'} !`
                );
                setIsSuccess(true);
                setErrorSuccess(true);
                setNetlinkingUpdateLoading(false);
            })
            .catch((e) => {
                setIsDeleted(true);
                setIsSuccess(false);
                setConfirmation(false);

                switch (e.response.status) {
                    case 412:
                        setErrorSuccessMsgDeleteForce(
                            `${t("il y'a des spot liés à au site web")} ${netlinkingDomain}`
                        );
                        setErrorSuccessDeleteForce(true);
                        setNetlinkingUpdateLoading(false);
                        break;
                    default:
                        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
                        setErrorSuccess(true);
                        setNetlinkingUpdateLoading(false);
                        break;
                }
            });
        history.goBack();
    };

    const forceDeleteWebsite = () => {
        setNetlinkingUpdateLoading(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling animation
        });
        axiosPixLink
            .delete(
                `/netlinking/force/delete/${window.location.href.split('/')[
                window.location.href.split('/').length - 1
                ]
                }`
            )
            .then(() => {
                setIsDeleted(true);
                setConfirmationDeleteForce(false);

                setErrorSuccessMsg(
                    `${t(
                        'Le contrat de'
                    )} ${netlinkingDomain} ${'a été supprimé avec succès'} !`
                );
                setIsSuccess(true);
                setErrorSuccess(true);
                setNetlinkingUpdateLoading(false);
            })
            .catch((e) => {
                setIsDeleted(true);
                setIsSuccess(false);
                setConfirmationDeleteForce(false);
                switch (e.response.status) {
                    default:
                        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
                        setErrorSuccess(true);
                        setNetlinkingUpdateLoading(false);
                        break;
                }
            });
        history.goBack();
    };

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));

        axiosPixLink
            .get(
                `netlinking/${window.location.href.split('/')[
                window.location.href.split('/').length - 1
                ]
                }`
            )
            .then((res) => {
                setNetlinking(res.data);
                setNetlinkingDomain(res.data.domain);
                setNetlinkingThemesList(res.data.themesList);
                setNetlinkingTopicsList(res.data.topicsList);
                setNetlinkingTrafic(res.data.trafic);
                setNetlinkingTF(res.data.tF);
                setNetlinkingCF(res.data.cF);
                setNetlinkingKwPositioned(res.data.kwPositioned);
                setNetlinkingTarifHr(res.data.tarifHr);
                setNetlinkingTarifRc(res.data.tarifRc);
                setNetlinkingContactedTime(res.data.contactedTime);
                setNetlinkingContact(res.data.contact);
                setNetlinkingPlatformRc(res.data.platformRC);
                setNetlinkingPlatformHr(res.data.platformHR);
                setNetlinkingPlatformName(res.data.platformName);
                setNetlinkingType(
                    res.data.type === 'redList' ||
                        res.data.type === 'liste rouge' ||
                        res.data.type === 'Liste Rouge'
                        ? 'redType'
                        : res.data.type
                );

                //  setNetlinkingList(res.data);
            });
    }, []);

    if (!isError) return <ErrorComponent />;

    return (
        <div className="content-center">
            {netlinkingUpdateLoading && <Loader></Loader>}
            <div className="box-header-top header-info">
                <span onClick={handleNavBackClick} className="bt-circle me-3" />
                <h1>{netlinkingDomain}</h1>
            </div>
            <div className="row">
                <div className="col-12 col-md-10 box-screen">
                    <div className="box-img-bg bg-8" />
                    <div className="box-img-bg bg-7" />
                    <div className="box-img-bg bg-9" />
                    <div className="header-title">
                        {t('Fiche de Spot Netlinking')}{' '}
                        <span
                            onClick={() => {
                                setConfirmationMsg(
                                    `${t(
                                        'Voulez-vous vraiment supprimer le spot de'
                                    )} ${netlinkingDomain} ? `
                                );
                                setConfirmation(true);
                            }}
                            className="bt-icon-trash"
                        >
                            <img src="/Images/icon-trash.png" alt="icon-trash" />{' '}
                        </span>
                    </div>

                    <div className="box-wrapper">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="row row-mb">
                                    <div className="col-12 col-md-4">
                                        <div className="label-title">{t('Domaine Netlinking')}</div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control form-ct"
                                                style={{
                                                    backgroundColor: 'rgb(224,224,224)',
                                                    color: 'grey',
                                                }}
                                                disabled
                                                value={netlinking !== null ? netlinkingDomain : ''}
                                                onChange={(e) => setNetlinkingDomain(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-mb">
                                    <div className="col-12 col-md-12">
                                        <div className="label-title">
                                            {t('Netlinking Thématique')}
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingThemesList : ''}
                                                onChange={(e) =>
                                                    setNetlinkingThemesList(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-mb">
                                    <div className="col-12 col-md-12">
                                        <div className="label-title">
                                            {t('Netlinking Topic Majestic')}
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingTopicsList : ''}
                                                onChange={(e) =>
                                                    setNetlinkingTopicsList(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-mb">
                                    <div className="col-12 col-md-10">
                                        <div className="label-title">{t('Type de spots')} :</div>
                                        <TypeCheckBoxGroup
                                            onCheckboxChange={handleTypeCheckboxChange}
                                            initialStateString={netlinkingType}
                                        />
                                    </div>
                                </div>
                                <div className="row row-mb">
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">{t('Trafic')}</div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingTrafic : ''}
                                                onChange={(e) => setNetlinkingTrafic(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">{t('TF')}</div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingTF : ''}
                                                onChange={(e) => setNetlinkingTF(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">{t('CF')}</div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingCF : ''}
                                                onChange={(e) => setNetlinkingCF(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '20%' }}>
                                        <div className="label-title">
                                            {t('Mots clés positionnés')}
                                        </div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={
                                                    netlinking !== null ? netlinkingKwPositioned : ''
                                                }
                                                onChange={(e) =>
                                                    setNetlinkingKwPositioned(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-mb">
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">{t('Tarif') + ' HR'} </div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingTarifHr : ''}
                                                onChange={(e) => setNetlinkingTarifHr(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">{t('Tarif') + ' RC'}</div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingTarifRc : ''}
                                                onChange={(e) => setNetlinkingTarifRc(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">{t('Nom platforme')}</div>
                                        <PlatformAutoComplete
                                            onPlatformChange={handlePlatformChange}
                                            defaultValue={netlinkingPlatformName}
                                        ></PlatformAutoComplete>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">
                                            {t('Tarif plateforme') + ' HR'}
                                        </div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinkingPlatformHr}
                                                onChange={(e) =>
                                                    setNetlinkingPlatformHr(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                        <div className="label-title">
                                            {t('Tarif plateforme') + ' RC'}
                                        </div>
                                        <div className="number-wrapper">
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control form-ct"
                                                value={netlinkingPlatformRc}
                                                onChange={(e) =>
                                                    setNetlinkingPlatformRc(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-mb">
                                    <div className="col-12 col-md-4">
                                        <div className="label-title">{t('Contacté le')}</div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control form-ct"
                                                value={
                                                    netlinking !== null ? netlinkingContactedTime : ''
                                                }
                                                onChange={(e) =>
                                                    setNetlinkingContactedTime(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="label-title">{t('Contact')}</div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control form-ct"
                                                value={netlinking !== null ? netlinkingContact : ''}
                                                onChange={(e) => setNetlinkingContact(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-button-bt">
                        <span>
                            <button onClick={updateNetlinking} className="bt-submit-y me-2">
                                {t('Sauvegarder')}
                            </button>{' '}
                        </span>
                    </div>
                </div>
            </div>
            <ErrorSuccesModal
                open={errorSuccessDeleteForce}
                message={errorSuccessMsgDeleteForce}
                handleClose={() => {
                    setErrorSuccessDeleteForce(false);
                    setConfirmationDeleteForce(true);
                    setConfirmationMsgDeleteForce(
                        'Do you want to force delete the netlinking'
                    );
                }}
                success={isSuccessForceDelete}
            />

            <ConfirmationModal
                open={confirmation}
                message={confirmationMsg}
                handleClose={() => setConfirmation(false)}
                executeAction={deleteWebsite}
            />

            <ConfirmationModal
                open={confirmationDeleteForce}
                message={confirmationMsgDeleteForce}
                handleClose={() => setConfirmationDeleteForce(false)}
                executeAction={forceDeleteWebsite}
            />
        </div>
    );
}
