import { useState, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  createStyles,
  InputBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import axiosPixLink from 'config/axiosPixLink';
import { customStyle } from './CustomAutocompleteStyle';
import { PIXLINK_PUBLISH_STATUS, PIXLINK_REDAC_STATUS } from 'utils/Constants';
const useStyles = makeStyles(() => createStyles(customStyle));
export const NetlinkingPublicationStatus = ({
  onListChange,
  highlightedOptions,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [statusList, setStatusList] = useState([
    {
      text: t('à commander'),
      status: PIXLINK_PUBLISH_STATUS.TO_BE_COMMANDED,
    },
    {
      text: t('commandé, en attente de retour'),
      status: PIXLINK_PUBLISH_STATUS.COMMAND_IN_PROGRESS,
    },
    {
      text: t('à valider super admin'),
      status: PIXLINK_PUBLISH_STATUS.TO_BE_VALIDTED_SUPER_ADMIN,
    },
    {
      text: t('à modifier'),
      status: PIXLINK_PUBLISH_STATUS.TO_BE_MODIFIED,
    },
    {
      text: t('publié'),
      status: PIXLINK_PUBLISH_STATUS.PUBLISHED,
    },
    { text: t('programmé'), status: PIXLINK_PUBLISH_STATUS.PROGRAMMED },
    {
      text: t('refusé éditeur'),
      status: PIXLINK_PUBLISH_STATUS.REFUSED_EDITOR,
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue([]);
    onListChange(newValue);
  };
  return (
    <Autocomplete
      value={value}
      limitTags={0}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          height: '40px',
        },
      }}
      disabled={statusList.length > 0 ? false : true}
      onChange={(event, newValue) => {
        handleChange(event, newValue);
        // Reset TextField value when an option is selected
        event.target.value = '';
      }}
      options={statusList}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          sx={{ height: '40px' }}
        />
      )}
      renderOption={(props, option) => {
        const isHighlighted = highlightedOptions.includes(option);
        return (
          <li
            {...props}
            style={{
              backgroundColor: isHighlighted ? '#ffe426' : 'white',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {' '}
            <Box>{option.text}</Box>
            {isHighlighted && (
              <img src="Images/icon-check.png" alt="icon-check" />
            )}
          </li>
        );
      }}
    />
  );
};
