import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchAdminOrderDisplay = createAsyncThunk(
  'adminOrderDisplay/fetchAdminOrderDisplay',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/commands/' + id);
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchAdminTextOrderDisplay = createAsyncThunk(
  'adminOrderDisplay/fetchAdminTextOrderDisplay',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get('/commands/' + id + '/texts');
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchAdminOrderLineDisplay = createAsyncThunk(
  'adminOrderDisplay/fetchAdminOrderLineDisplay',
  async (id, thunkAPI) => {
    try {
      if (!id) return thunkAPI.rejectWithValue(404);
      const response = await axiosPrivate.get(
        '/commands/' + id + '/commandLines'
      );
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
