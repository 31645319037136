import React, { useState } from 'react';
import Modal from 'react-modal'; // for the popup/modal
import { splitThemes } from 'utils/netlinking/StringTools';
import { getLanguageIdByRef } from 'utils/netlinking/languages';
import * as XLSX from 'xlsx'; // for Excel parsing
import { Loader } from '../Loader';
import ConfirmationModal from '../modal/ConfirmationModal';
import ErrorSuccesModal from '../modal/ErrorSuccesModal';
import { browserList, detectBrowser } from 'utils/BrowserDetection';

// Style for the modal content
const customModalStyle = {
  overlay: {
    zIndex: 9999, // Highest z-index value
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
  },
  content: {
    width: '80rem', // Width of the modal
    margin: '0 auto', // Center the modal horizontally
    padding: '20px', // Padding for content inside the modal
    height: '30rem',
  },
};

function ExcelUploaderCampaign({ handleLinksChange, sendFileName, type }) {
  const [excelData, setExcelData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [creationLoading, setCreationLoading] = useState(false);
  const fileInputRef = React.useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    sendFileName(file.name);

    if (file) {
      // Parse the Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Process the workbook and store the data
        const formattedData = processWorkbookData(workbook);
        setExcelData(formattedData);

        // Open the modal
        setIsModalOpen(true);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const closeModal = () => {
    // Close the modal and reset the Excel data
    setIsModalOpen(false);
    setExcelData(null);
    fileInputRef.current.value = '';
    sendFileName('');
  };

  // Placeholder function to process workbook data
  const processWorkbookData = (workbook) => {
    const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
    const sheet = workbook.Sheets[sheetName];

    // Convert the sheet data to a 2D array (array of arrays)
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    // Iterate through the rows and cells to detect and handle empty cells
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        // Check if the cell is empty (undefined)
        if (typeof data[i][j] === 'undefined') {
          data[i][j] = null; // Replace with null or any other placeholder value
        }
      }
    }

    const keys = data[0];

    // Create JSON objects from the remaining arrays
    const jsonData = data.slice(1).map((itemArray) => {
      const itemObject = {};
      keys.forEach((key, index) => {
        itemObject[key] = itemArray[index];
      });
      itemObject['domain'] = itemArray[0];
      itemObject['languageId'] = getLanguageIdByRef(itemArray[3]);
      itemObject['themes'] = splitThemes(itemArray[4]);
      itemObject['topics'] = splitThemes(itemArray[5]);
      itemObject['kwPositioned'] = itemArray[6];
      itemObject['trafic'] = itemArray[7];
      itemObject['tF'] = itemArray[8];
      itemObject['cF'] = itemArray[9];
      itemObject['tarifHr'] = itemArray[20];
      itemObject['tarifRc'] = itemArray[21];
      itemObject['contactedTime'] = itemArray[23];
      itemObject['contact'] = itemArray[24];

      return itemObject;
    });

    setJsonData(jsonData);

    return data;
  };

  const createMultipleNetlinking = async (jsonData) => {
    handleLinksChange(jsonData);
    setIsModalOpen(false);
    setExcelData(null);
  };

  return (
    <div
      className={
        detectBrowser() === browserList.FIREFOX
          ? 'excel-component-campaign'
          : 'excel-component-campaign-zoomed-out'
      }
    >
      <div className="flex-start bt-submit-v-c-d ">
        {' '}
        <img
          src="/Images/icon-excel.png"
          onClick={() => {
            document.querySelector('#' + type).click();
          }}
          className="App-logo-excel"
          alt="logo"
        />
        <input
          type="file"
          ref={fileInputRef}
          accept=".xlsx"
          onChange={handleFileUpload}
          className="bt-submit-v-c-d input- width-null"
          id={type}
        />
        <span
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
          style={{ marginTop: '3px' }}
        >
          X
        </span>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Excel Data"
        style={customModalStyle} // Apply custom styles to the modal
      >
        <div className="modal-header">
          <button
            className="close-modal-btn-upload-within"
            onClick={closeModal}
          >
            X
          </button>
        </div>
        {creationLoading && <Loader />}
        {excelData && (
          <div className="table-container-campaing-modal">
            <div className="table-responsive">
              <div className="box-table-netlinking">
                <table className="table-lists">
                  <thead>
                    <tr>
                      {excelData[0].map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="modal-footer" style={{ marginTop: '10px' }}>
          <button
            onClick={() => {
              createMultipleNetlinking(jsonData);
            }}
            className="bt-submit-y me-2 close-modal-btn-upload"
          >
            Save
          </button>
          <button
            onClick={closeModal}
            className="bt-submit-y me-2 close-modal-btn-upload"
          >
            Cancel
          </button>
        </div>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={() => { }}
      />
    </div>
  );
}

export default ExcelUploaderCampaign;
