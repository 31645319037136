import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosNotifications from 'config/axiosNotifications';

export const fetchClientNotification = createAsyncThunk(
  'clientNotification/fetchClientNotification',
  async (arg, thunkAPI) => {
    try {
      const response = await axiosNotifications.get(
        '/notification/MyNotifications'
      );
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
