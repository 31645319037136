import { createSlice } from '@reduxjs/toolkit';
import { fetchClientNotification } from './features';

export const clientNotification = createSlice({
  name: 'clientNotification',
  initialState: {
    notifications: [],
    newNotifications: [],

    isLoading: true,
    isError: true,
  },
  reducers: {
    setNotificationsToSeen: (state, action) => {
      state.newNotifications = [];
    },
    setNotificationsToAccessed: (state, { payload }) => {
      const arr = [...state.notifications];
      const index = arr.findIndex(
        (notification) => notification.id === payload.notificationId
      );
      arr[index].accessed = true;
      state.notifications = arr;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientNotification.fulfilled, (state, { payload }) => {
        state.notifications = payload;
        state.newNotifications = state.notifications.filter((x) => !x.seen);

        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchClientNotification.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchClientNotification.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default clientNotification.reducer;
export const { setNotificationsToSeen, setNotificationsToAccessed } =
  clientNotification.actions;

export const clientNotificationSelector = (state) => state.clientNotification;
