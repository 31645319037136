import { useEffect, useState } from 'react';

import axiosPixLink from 'config/axiosPixLink';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SuperConsultantHistoricalBacklinkingsTableControl from 'components/Netlinking/Table/SuperConsultant/SuperConsultantHistoricalBacklinkingsTableControl';
import SuperConsultantSelectedSpotTableControl from 'components/Netlinking/Table/SuperConsultant/SuperConsultantSelectedSpotTableControl';
import { MyCustomHighchartsSmall } from 'components/shared/Charts/MyCustomHighChartsSmall';
import { MyCustomHighcharts } from 'components/shared/Charts/MyCustomHighcharts';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  COLOR_JAUGE,
  PIXLINK_CAMPAIGN_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import {
  calculateClientMinimumLinks,
  calculateMinimumLinks,
} from 'utils/netlinking/linksNumber';
import './order.css';
import { MyCustomReverseHighChats } from 'components/shared/Charts/MyCustomReverseHighChats';
import {
  calculateRefusedClient,
  calculateRefusedPixalione,
  calculateToBeCommanded,
  calculateToBeFilteredConsultant,
  calculateValidateClient,
  calculateValidateSuperAdmin,
  calculateValidateSuperConsultant,
} from 'utils/Jauge/Calculate';
import { calculateSuperConsultantValidateClient } from 'utils/Jauge/CalculateSuperConsultant';
import { Campaign } from '@mui/icons-material';
const API = {
  getAvailableThematics: '/netlinking/all-thematics',
  getAvailableLanguages: '/language/all',
  getExistingAccounts: 'website/account/all',
  getAllWriters: '/user/all',
  getAllWebsiteOfAccount: '/website',
  getAllDevisOfAccount: '/user/getDevise',
  updateCampaign: '/campaign/update',
  createSmartOrder: '/commands/addSmartCommand',
  getAllConsultants: '/user/consultants',
  getAllSuperConsultants: 'user/superconsultants',
  getCampaignDetail: '/campaign/campaignDetail/',
};

export default function SuperConsultantCampaignSourcing({ campaignType, campaignStatus, clientNotified, changeNotifyClient }) {
  const dispatch = useDispatch();
  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [wantedNetlinkings, setWantedNetlinkings] = useState([]);
  const [historicBackLinkings, setHistoricBackLinkings] = useState([]);
  const [netlinkings, setNetlinkings] = useState([]);
  const [netlinkingsTmp, setNetlinkingsTmp] = useState([]);
  const [nbrOfLinksDemanded, setNbrOfLinksDemanded] = useState(0);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [campaignFilter, setCampaignFilter] = useState(null);

  const [isSearchForNetlinkinngOpen, setIsSearchForNetlinkinngOpen] =
    useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [confirmationSaveForce, setConfirmationSaveForce] = useState(false);
  const [confirmationMsgSaveForce, setConfirmationMsgSaveForce] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [active, setActive] = useState(1);
  const [campaignStatusTmp, setCampaignStatusTmp] = useState(campaignStatus);

  const navItems = [
    { text: t('Spots sélectionnés'), active: 1 },
    { text: t('Historique Backlinks'), active: 2 },
  ];
  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('PixLink - Detail campaign');
  }, [t]);

  useEffect(() => {
    axiosPixLink
      .get(
        API.getCampaignDetail +
        `${window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ]
        }`
      )
      .then((res) => {
        setCampaignFilter(res.data);
        setWantedNetlinkings(res.data.wantedNetlinkings);
        setHistoricBackLinkings(res.data.historicBackLinkings);
        setNetlinkingsTmp(res.data.netlinkingSelecteds);
        setNetlinkings(res.data.netlinkingSelecteds);
        setNbrOfLinksDemanded(res.data.linksNumbers);
      });
  }, []);

  function sendNetlinkingChoose(data) {
    const newData = data.map((item) => {
      if (!item.hasOwnProperty('netlinking')) {
        const newSchemaItem = {
          id: null,
          netlinking: {
            id: item?.id,
            domain: item?.domain,
            kwPositioned: item?.kwPositioned,
            trafic: item?.trafic,
            tF: item?.tF,
            cF: item?.cF,
            tarifHr: item?.tarifHr,
            conditions: item?.conditions,
            themesList: item?.themesList,
            topicsList: item?.topicsList,
            platformName: item?.platformName,
            platformPrice: item?.platformPrice,
            platformRC: item?.platformRC,
            platformHR: item?.platformHR,
            contactPhoneNumber: item?.contactPhoneNumber,
            languageId: item?.languageId,
            contact: item?.contact,
            tarifRc: item?.tarifRc,
            contactedTime: item?.contactedTime,
            type: item?.type
          },
          responsibleRedactio: null,

          responsiblePublication: null,
          programmationDate: null,
          publicationDate: null,
          contactDate: null,
          articleLink: null,
          spotLink: null,
          ancreLink: null,
          insertionLink: null,
          publicationStatus: null,
          redactionStatus: null,
          spotStatus: null,
        };
        return newSchemaItem;
      }

      return item;
    });

    setNetlinkingsTmp(newData);
  }

  function removeNetlinkingId(items) {
    const updatedNetlinkings = netlinkings.filter(
      (netlinking) =>
        !items.some((item) => item.netlinking.id === netlinking.netlinking.id)
    );
    setNetlinkingsTmp(updatedNetlinkings);
    setNetlinkings(updatedNetlinkings);
    RemoveNetlinkingToCampaigns(updatedNetlinkings);
  }

  const RemoveNetlinkingToCampaigns = (netlinkingswithoutSpot) => {
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingswithoutSpot.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        setNetlinkings(netlinkingswithoutSpot);
        setErrorSuccessMsg(
          `${t('Les spots')} ${t('ont été supprimés avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const sendUpdateSaveSelectedNetlinking = (updatedData) => {
    setNetlinkingsTmp(updatedData);
    setNetlinkings(updatedData);
  };

  const SaveNetlinkingToCampaigns = () => {
    setNetlinkings(netlinkingsTmp);
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingsTmp.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        setNetlinkings(netlinkingsTmp);
        setErrorSuccessMsg(
          `${t('Les spots')} ${t('ont été ajoutés avec succes')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  function haveSharedDomain(array1, array2) {
    // Extract domains from array1
    const domains1 = array1.map((obj) => obj?.netlinking?.domain);

    // Iterate over array2 and check if any domain matches
    for (let i = 0; i < array2.length; i++) {
      if (domains1.includes(array2[i]?.domain)) {
        return true; // If match found, return true
      }
    }

    return false; // If no match found, return false
  }

  const GlobalSaveNetlinkingToCampaigns = () => {
    if (haveSharedDomain(netlinkings, historicBackLinkings)) {
      setConfirmationMsgSaveForce(
        'are you sure you want to save while a Backlinking is present'
      );
      setConfirmationSaveForce(true);
    } else {
      SaveNetlinkingToCampaigns();
    }
  };

  const NotifierClientNetlinkingToCampaigns = () => {
    axiosPixLink
      .post(
        `/campaign/NotifyClientSelectedNetlinking/${id}`,
        netlinkings.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        if (!campaignType) {
          setCampaignStatusTmp(
            PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED
          );
          setNetlinkings(
            netlinkings.map((item) => {
              if (item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.REFUSED_PIXALIONE,
                };
              } else if (item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
                };
              } else {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
                };
              }
            })
          );
        } else {
          setCampaignStatusTmp(
            PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED
          );
          setNetlinkings(
            netlinkings.map((item) => {
              if (item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.REFUSED_PIXALIONE,
                };
              } else if (item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT) {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
                };
              } else {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
                };
              }
            })
          );
        }
        changeNotifyClient()


        setErrorSuccessMsg(
          `${t('Client')} ${t('a été notifié avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  return (
    <div>
      <div className="content-center">
        <div className="row-homePage">
          {netlinkings.filter(
            (item) =>
              item.spotStatus !== 'TO_DEFINE' &&
              item.spotStatus !== null &&
              item.spotStatus !== undefined &&
              item.spotStatus !== PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN
          ).length > 0 && (
              <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
                <div className="box-wrapper" style={{ height: '289px' }}>
                  <div className="header-title mb-0">
                    {campaignStatus ===
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED
                      ? t('Nombre de liens demandés')
                      : t('Nombre de liens proposé')}
                  </div>
                  <div className="box-graph">
                    <div className="d-flex justify-content-center">
                      {nbrOfLinksDemanded > 0 ? (
                        <MyCustomHighcharts
                          value={
                            netlinkingsTmp.length > 0
                              ? netlinkingsTmp.filter(
                                (item) =>
                                  item.spotStatus !== 'TO_DEFINE' &&
                                  item.spotStatus !==
                                  'TO_BE_VALIDATED_SUPER_ADMIN' &&
                                  item.spotStatus !== null &&
                                  item.spotStatus !== undefined &&
                                  item.spotStatus !==
                                  PIXLINK_STATUS.REFUSED_PIXALIONE
                              )?.length
                              : netlinkings.filter(
                                (item) =>
                                  item.spotStatus !== 'TO_DEFINE' &&
                                  item.spotStatus !==
                                  'TO_BE_VALIDATED_SUPER_ADMIN' &&
                                  item.spotStatus !== null &&
                                  item.spotStatus !== undefined &&
                                  item.spotStatus !==
                                  PIXLINK_STATUS.REFUSED_PIXALIONE
                              )?.length
                          }
                          maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                        ></MyCustomHighcharts>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
            <div className="box-wrapper" style={{ height: '289px' }}>
              <div className="header-title mb-0">
                {t('Nombre de liens par Status')}
              </div>
              <div className="box-graph">
                <div className="d-flex justify-content-center">
                  {nbrOfLinksDemanded > 0 ? (
                    <span>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}
                      >
                        {calculateValidateSuperConsultant(netlinkings) > 0 && (
                          <MyCustomHighchartsSmall
                            defaultColor={COLOR_JAUGE.BLACK}
                            label={t('A  valider super admin')}
                            value={calculateValidateSuperConsultant(
                              netlinkings
                            )}
                            maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                          ></MyCustomHighchartsSmall>
                        )}
                        {calculateSuperConsultantValidateClient(netlinkings) >
                          0 && (
                            <MyCustomHighchartsSmall
                              defaultColor={COLOR_JAUGE.ORANGE}
                              label={t('A  valider client')}
                              value={calculateSuperConsultantValidateClient(
                                netlinkings
                              )}
                              maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                            ></MyCustomHighchartsSmall>
                          )}
                        {calculateRefusedClient(netlinkings) > 0 && (
                          <MyCustomHighchartsSmall
                            label={t('Refusé client')}
                            defaultColor={COLOR_JAUGE.RED}
                            value={calculateRefusedClient(netlinkings)}
                            maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                          ></MyCustomHighchartsSmall>
                        )}
                        {calculateToBeFilteredConsultant(netlinkings) > 0 && (
                          <MyCustomHighchartsSmall
                            defaultColor={COLOR_JAUGE.BLUE}
                            label={t('Validé client')}
                            value={calculateToBeFilteredConsultant(netlinkings)}
                            maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                          ></MyCustomHighchartsSmall>
                        )}
                        {calculateToBeCommanded(netlinkings) > 0 && (
                          <MyCustomHighchartsSmall
                            label={t('A commander')}
                            defaultColor={COLOR_JAUGE.GREEN}
                            value={calculateToBeCommanded(netlinkings)}
                            maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                          ></MyCustomHighchartsSmall>
                        )}
                      </div>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
            <div className="box-wrapper" style={{ height: '289px' }}>
              <div className="header-title mb-0">
                {t('Quota de refus disponible')}
              </div>
              <div className="box-graph">
                <div className="d-flex justify-content-center">
                  {nbrOfLinksDemanded > 0 ? (
                    <span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MyCustomReverseHighChats
                          defaultColor="#FF8D4B"
                          value={
                            netlinkings.filter(
                              (item) =>
                                item.spotStatus ===
                                PIXLINK_STATUS.REFUSED_CLIENT ||
                                item.spotTmpStatus ===
                                PIXLINK_STATUS.REFUSED_CLIENT
                            ).length
                          }
                          maxValue={Math.floor(
                            calculateMinimumLinks(nbrOfLinksDemanded) -
                            calculateClientMinimumLinks(nbrOfLinksDemanded)
                          )}
                        ></MyCustomReverseHighChats>
                      </div>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 mb-2">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => {
                    setActive(item.active);
                  }}
                  className={`nav-link ${item.active === active ? 'active' : ''
                    }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="row-homePage">
          {active === 1 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                <div className="box-list-header"></div>
                <SuperConsultantSelectedSpotTableControl
                  netlinkings={netlinkings.filter(
                    (item) =>
                      item.spotStatus !== 'TO_DEFINE' &&
                      item.spotStatus !== 'TO_BE_VALIDATED_SUPER_ADMIN' &&
                      item.spotStatus !== null &&
                      item.spotStatus !== undefined &&
                      item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE
                  )}
                  campaignFilter={null}
                  wantedNetlinkings={wantedNetlinkings}
                  removeNetlinkingId={removeNetlinkingId}
                  SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
                  sendUpdateSaveSelectedNetlinking={
                    sendUpdateSaveSelectedNetlinking
                  }
                  campaignStatus={campaignStatus}
                  historicBackLinkings={historicBackLinkings}
                  campaignType={campaignType}
                ></SuperConsultantSelectedSpotTableControl>

                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
          {active === 2 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                <div className="box-list-header"></div>
                <SuperConsultantHistoricalBacklinkingsTableControl
                  historicBackLinkings={historicBackLinkings}
                  campaignFilter={null}
                  wantedNetlinkings={wantedNetlinkings}
                  removeNetlinkingId={removeNetlinkingId}
                  SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
                ></SuperConsultantHistoricalBacklinkingsTableControl>
                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
        </div>
        {active === 1 && (
          <span>
            {campaignStatusTmp ===
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED ||
              (campaignStatus !==
                PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_SUPER_CONSULTANT_TO_BE_APPROVED &&
                campaignStatus !==
                PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED && (campaignStatus ===
                  PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED && clientNotified)) ? (
              <button
                className="bt-submit-disabled-y"
                style={{
                  margin: '0 auto',
                  marginTop: '5px',
                  marginLeft: '5px',
                  alignSelf: 'right',
                  float: 'right',
                }}
              >
                {t('Informer Client')}
              </button>
            ) : (
              <button
                className="bt-submit-y"
                style={{
                  margin: '0 auto',
                  marginTop: '5px',
                  marginLeft: '5px',
                  alignSelf: 'right',
                  float: 'right',
                }}
                onClick={NotifierClientNetlinkingToCampaigns}
              >
                {t('Informer Client')}
              </button>
            )}
          </span>
        )}
      </div>

      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
          //       history.goBack();
        }}
        success={isSuccess}
      />
      <ConfirmationModal
        open={confirmationSaveForce}
        message={confirmationMsgSaveForce}
        handleClose={() => setConfirmationSaveForce(false)}
        executeAction={SaveNetlinkingToCampaigns}
      />
    </div>
  );
}
