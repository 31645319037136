import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ErrorComponent from 'components/shared/ErrorComponent';
import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';
import { ProgressBar } from 'components/shared/ProgressBar';
import { CircularBar } from 'components/shared/CircularBar';
import { CustomDropdownReadOnly } from 'components/shared/DropDown/CustomDropdown';

import OrderTypeFilter from 'components/shared/Filter/OrderTypeFilter';
import OrderToBeVerifiedFilter from 'components/shared/Filter/OrderTypeVerified';

import {
  FILTER_CAMPAIGN_TO_VERIFY_LIST,
  FILTER_CAMPAIGN_TYPE_LIST,
  PIXLINK_PUBLISH_STATUS,
  PIXLINK_REDAC_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import { calculateMinimumLinks } from 'utils/netlinking/linksNumber';

import { handleSorting } from 'store/Admin/OrderList';
import { lower } from 'utils/StringUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { formatDate } from 'utils/DateUtils';
import { adminOrderListSelector } from 'store/Admin/OrderList';
import {
  fetchAdminOrderDisplay,
  fetchAdminTextOrderDisplay,
  fetchAdminOrderLineDisplay,
} from 'store/Admin/OrderDisplay/features';

import { setStatus } from 'store/Admin/OrderDisplay';
import { setOrderId, setOrderType } from 'store/Admin/Analyser';
import { filterOrders } from 'helpers/FilterOrders';

import './order.css';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import axiosPixLink from 'config/axiosPixLink';

const pageNbOptions = [10, 15, 20];

export default function AdminUnassignedOrders() {
  /*let { inProgressOrders, isLoading, isError } = useSelector(
    adminOrderListSelector
  );*/
  const { t } = useTranslation();

  const columns = [
    { label: t('Titre de la campagne'), field: 'title', sortable: true },
    { label: t('Type'), field: 'type', sortable: false },
    { label: t('To Be Verified'), field: 'toBeVerified', sortable: false },
    { label: t('Deadline'), field: 'deadline', sortable: true, sort: 'asc' },
    { label: t('Consultants'), field: 'writers', sortable: false },
    {
      label: t('Liens en cours'),
      field: 'proposedLinksAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: t('Liens en ligne'),
      field: 'commandedLinksAmount',
      sortable: true,
      sort: 'asc',
    },
    {
      label: t('Temps restant'),
      field: 'finishedAt',
      sortable: true,
      sort: 'asc',
    },
  ];

  const fieldWidths = {
    title: '26%',
    deadline: '10%',
    type: '10%',
    toBeVerified: '10%',
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedVerified, setSelectedVerified] = useState(
    FILTER_CAMPAIGN_TO_VERIFY_LIST[0]
  );
  const [selectedType, setSelectedType] = useState(
    FILTER_CAMPAIGN_TYPE_LIST[0]
  );

  const [pageNumbers, setPageNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElToBeVerified, setAnchorElToBeVerified] = useState(null);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    document.title = t('PixLink - campagnes');
    window.dispatchEvent(new Event('locationchange'));
    setIsLoading(true);
    /*dispatch(fetchAllOrders());*/
    axiosPixLink.get('/campaign/all/unassigned').then((res) => {
      setInProgressOrders(res.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!inProgressOrders) return;

    const filtered = filterOrders(
      inProgressOrders,
      selectedVerified,
      selectedType,
      titleSearch
    );
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
    }
    setFilteredOrders(filtered);
  }, [selectedVerified, selectedType, titleSearch, inProgressOrders]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    setCurrentOrders(filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(filteredOrders.length / ordersPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, filteredOrders, ordersPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [pageNumbers]);

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickToBeVerified = (index, event) => {
    setAnchorElToBeVerified({ [index]: event.currentTarget });
  };

  const handleCloseToBeVerified = () => {
    setAnchorElToBeVerified(null);
  };

  const handleSortingChange = (field) => {
    const direction =
      field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';

    const sortedOrders = [...filteredOrders].sort((a, b) => {
      if (field === 'deadline' || field === 'finishedAt') {
        const dateA = new Date(a['deadLine']).getTime();
        const dateB = new Date(b['deadLine']).getTime();

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      } else if (field === 'proposedLinksAmount') {
        return direction === 'asc'
          ? a['netlinkingSelecteds'].filter(
            (item) =>
              item.redactionStatus === PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_MODIFIED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS
          )?.length /
          a['linksNumbers'] -
          b['netlinkingSelecteds'].filter(
            (item) =>
              item.redactionStatus === PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_MODIFIED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS
          )?.length /
          b['linksNumbers']
          : b['netlinkingSelecteds'].filter(
            (item) =>
              item.redactionStatus === PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_MODIFIED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS
          )?.length /
          b['linksNumbers'] -
          a['netlinkingSelecteds'].filter(
            (item) =>
              item.redactionStatus === PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_MODIFIED ||
              item.redactionStatus === PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS
          )?.length /
          a['linksNumbers'];
      } else if (field === 'commandedLinksAmount') {
        return direction === 'asc'
          ? a['netlinkingSelecteds'].filter(
            (item) => item.publicationStatus === PIXLINK_PUBLISH_STATUS.PUBLISHED
          )?.length /
          a['linksNumbers'] -
          b['netlinkingSelecteds'].filter(
            (item) => item.publicationStatus === PIXLINK_PUBLISH_STATUS.PUBLISHED
          )?.length /
          b['linksNumbers']
          : b['netlinkingSelecteds'].filter(
            (item) => item.publicationStatus === PIXLINK_PUBLISH_STATUS.PUBLISHED
          )?.length /
          b['linksNumbers'] -
          a['netlinkingSelecteds'].filter(
            (item) => item.publicationStatus === PIXLINK_PUBLISH_STATUS.PUBLISHED
          )?.length /
          a['linksNumbers'];
      } else if (field === 'title') {
        const aTitle = `${a.idDevis} - ${a.account} - ${a.taskName} - ${a.linksNumbers}`;
        const bTitle = `${b.idDevis} - ${b.account} - ${b.taskName} - ${b.linksNumbers}`;
        return direction === 'asc'
          ? aTitle.localeCompare(bTitle)
          : bTitle.localeCompare(aTitle);
      }

      // For other types, maintain the original order
      return 0;
    });
    setSortField(field);
    setSortOrder(direction);
    setFilteredOrders(sortedOrders);
  };

  return (
    <div className="content-center">
      <div className="box-info-header">
        <div className="b-child-1">
          <h1>{t('Campagnes non attribuées')}</h1>
        </div>
        <div className="b-child-2">
          <div className="d-flex justify-content-end">
            <div className="box-input-text pe-0">
              <div className="input-group input-cs w-100 pe-0">
                <div className="input-group-text">
                  <i className="bi bi-search" />
                </div>
                <input
                  type="text"
                  className="form-control ps-0"
                  value={titleSearch}
                  onChange={(event) => setTitleSearch(event.target.value)}
                  placeholder={t('Rechercher')}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="me-2">{t('Afficher les résultats')}</span>

              <div className="dropdown" style={{ width: '67px' }}>
                <button
                  className="btn dropdown-toggle dropdown-custom w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {ordersPerPage}
                  <span>
                    <i className="bi bi-chevron-down" />
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {pageNbOptions
                    .filter((nb) => nb !== ordersPerPage)
                    .map((item, index) => (
                      <li key={index} onClick={() => setOrdersPerPage(item)}>
                        <a className="dropdown-item">{item}</a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <div className="box-wrapper">
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width: fieldWidths[field] || '',
                        }}
                        onClick={
                          sortable ? () => handleSortingChange(field) : null
                        }
                        key={field}
                      >
                        {label}
                        {!sortable ? null : field === sortField &&
                          sortOrder === 'asc' ? (
                          <span>
                            <i
                              style={{ marginLeft: '3px' }}
                              className="bi bi-chevron-up"
                            />
                          </span>
                        ) : (
                          <span>
                            <i
                              style={{ marginLeft: '3px' }}
                              className="bi bi-chevron-down"
                            />{' '}
                          </span>
                        )}{' '}
                        {field === 'type' ? (
                          <OrderTypeFilter
                            orderTypeList={FILTER_CAMPAIGN_TYPE_LIST}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            handleClick={handleClick}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                          />
                        ) : null}
                        {field === 'toBeVerified' ? (
                          <OrderToBeVerifiedFilter
                            orderVerifiedList={FILTER_CAMPAIGN_TO_VERIFY_LIST}
                            selectedVerified={selectedVerified}
                            setSelectedVerified={setSelectedVerified}
                            handleClick={handleClickToBeVerified}
                            handleClose={handleCloseToBeVerified}
                            anchorEl={anchorElToBeVerified}
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.push('/Admin/DetailsCampagne/' + order.id);
                        }}
                      >
                        <span>{`${order.idDevis} - ${order.account} - ${order.taskName} - ${order.linksNumbers}`}</span>
                      </td>
                      <td>{t(order?.type ? order?.type === "ultraPremium" ? "ultra premium" : order?.type : 'standard')}</td>
                      <td>{t(order.toBeVerified ? 'à verifier' : '-')}</td>
                      <td>
                        <span>
                          {order?.deadLine ? formatDate(order?.deadLine) : '-'}
                        </span>
                      </td>
                      <td>
                        <div>
                          {order?.consultants.length === 0 ? (
                            <Button disabled>{t('0 Consultants')} </Button>
                          ) : (
                            <>
                              <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(index, e)}
                              >
                                <img
                                  src="/Images/icon-users-down.png"
                                  alt="icon-users-down"
                                />
                              </Button>
                              <CustomDropdownReadOnly
                                handleClose={handleClose}
                                anchorEl={anchorEl && anchorEl[index]}
                                list={order?.consultants}
                                getOptionLabel={(option) => {
                                  return option.email;
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>

                      <td>
                        <ProgressBar
                          amount={
                            order?.netlinkingSelecteds.filter(
                              (item) =>
                                item.redactionStatus === PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS ||
                                item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN ||
                                item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT ||
                                item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED ||
                                item.redactionStatus === PIXLINK_REDAC_STATUS.TO_BE_MODIFIED ||
                                item.redactionStatus === PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS

                            )?.length
                          }
                          total={order?.linksNumbers}
                        />
                      </td>
                      <td>
                        <ProgressBar
                          amount={
                            order?.netlinkingSelecteds.filter(
                              (item) =>
                                item.publicationStatus ===
                                PIXLINK_PUBLISH_STATUS.PUBLISHED
                            )?.length

                          }
                          total={order?.linksNumbers}
                        />
                      </td>
                      <td>
                        <CircularBar
                          finishedAt={order?.deadLine}
                          orderId={order?.id}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Paginator
        isLoading={isLoading}
        listLength={filteredOrders.length}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        nbElementsPerPage={ordersPerPage}
        goToPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
