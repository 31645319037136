import { configureStore } from '@reduxjs/toolkit';
import userSessionReducer from './UserSession';

import adminClientList from './Admin/ClientList';
import adminOrderList from './Admin/OrderList';
import adminOrderDisplay from './Admin/OrderDisplay';
import adminAnalyzer from './Admin/Analyser';
import adminWritersList from './Admin/WritersList';
import adminWriterDisplay from './Admin/WriterDisplay';
import adminUserManagement from './Admin/UserManagement';
import adminWebsiteDetails from './Admin/WebsiteDisplay';

import writerOrderList from './Writer/OrderList';
import writerProjectList from './Writer/ProjectList';
import writerOrderDisplayByClient from './Writer/OrderDisplayByClient';
import writerOrderDisplay from './Writer/OrderDisplay';
import writerAnalyzer from './Writer/Analyser';

import clientOrderList from './Client/ClientOrderList';
import clientWebsiteDetails from './Client/ClientWebsiteDisplay';
import clientOrderDisplay from './Client/OrderDisplay';
import clientAnalyzer from './Client/ClientAnalyser';

import consultantClientList from './Consultant/ClientList';
import consultantOrderList from './Consultant/ConsultantOrderList';
import consultantWebsiteDetails from './Consultant/WebsiteDisplay';
import consultantOrderDisplay from './Consultant/ConsultantOrderDisplay';
import consultantAnalyzer from './Consultant/ConsultantAnalyser';
import clientNotification from './Client/ClientNotification';
import billsDisplay from './Writer/BillDisplay/reducers';
import allBills from './Admin/BillDisplay/reducers';

import languageStore from './Languages';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';

const store = configureStore({
  reducer: {
    userSession: persistReducer(
      {
        key: 'userSessionReducer',
        storage,
      },
      userSessionReducer
    ),

    //Admin
    adminClientList: adminClientList,
    adminOrderList: adminOrderList,
    adminOrderDisplay: adminOrderDisplay,
    adminAnalyzer: adminAnalyzer,
    adminWritersList: adminWritersList,
    adminWriterDisplay: adminWriterDisplay,
    adminUserManagement: adminUserManagement,
    adminWebsiteDetails: adminWebsiteDetails,

    //Writer
    writerOrderList: writerOrderList,
    writerProjectList: writerProjectList,
    writerOrderDisplayByClient: writerOrderDisplayByClient,
    writerOrderDisplay: writerOrderDisplay,
    writerAnalyzer: writerAnalyzer,
    billsDisplay: billsDisplay,
    allBills: allBills,

    //Client
    clientOrderList: clientOrderList,
    clientWebsiteDetails: clientWebsiteDetails,
    clientOrderDisplay: clientOrderDisplay,
    clientAnalyzer: clientAnalyzer,
    clientNotification: clientNotification,

    //Consultant
    consultantClientList: consultantClientList,
    consultantOrderList: consultantOrderList,
    consultantWebsiteDetails: consultantWebsiteDetails,
    consultantOrderDisplay: consultantOrderDisplay,
    consultantAnalyzer: consultantAnalyzer,

    languageStore: languageStore,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
