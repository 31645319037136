const { PIXLINK_CAMPAIGN_STATUS } = require('utils/Constants');

export const checkDisabledCell = (campaignStatus) => {
  return (
    campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED ||
    campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_TO_BE_APPROVED ||
    campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_SUPER_CONSULTANT_TO_BE_APPROVED ||
    campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED ||
    campaignStatus.length === 0
  );
};
