import { createSlice } from '@reduxjs/toolkit';

export const LanguageStore = createSlice({
  name: 'languageStore',
  initialState: {
    selectedLanguage: localStorage.getItem('language') || 'en', // get the language preference from local storage, default to 'en' if not found
  },
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
      localStorage.setItem('language', action.payload); // save the language preference to local storage
    },
  },
});

export default LanguageStore.reducer;

export const { setSelectedLanguage } = LanguageStore.actions;
