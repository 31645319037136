import { createSlice } from '@reduxjs/toolkit';
import { fetchWebsiteDisplay } from './features';
import { fetchWebsiteOrdersList } from './features';

export const adminWebsiteDetails = createSlice({
  name: 'adminWebsiteDetails',
  initialState: {
    formData: null,
    urlSite: '',
    websiteId: null,
    account: null,
    ordersList: [],
    isLoading: true,
    isError: true,
    isListLoading: true,
    isListError: true,
  },
  reducers: {
    setUrlSiteForDisplay: (state, action) => {
      state.urlSite = action.payload;
    },
    setWebsiteId: (state, action) => {
      state.websiteId = action.payload;
    },
    setWebsiteAccount: (state, action) => {
      state.account = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteDisplay.fulfilled, (state, { payload }) => {
        state.formData = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchWebsiteDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchWebsiteDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchWebsiteOrdersList.fulfilled, (state, { payload }) => {
        state.ordersList = payload;
        state.isListLoading = false;
        state.isListError = false;
      })
      .addCase(fetchWebsiteOrdersList.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
      })
      .addCase(fetchWebsiteOrdersList.rejected, (state) => {
        state.isListLoading = false;
        state.isListError = true;
      });
  },
});

export default adminWebsiteDetails.reducer;

export const { setUrlSiteForDisplay, setWebsiteId, setWebsiteAccount } =
  adminWebsiteDetails.actions;

export const adminWebsiteDetailsSelector = (state) => state.adminWebsiteDetails;
