import { useState, useEffect } from 'react';
import CheckBox from 'components/shared/CheckBox';
import { useTranslation } from 'react-i18next';
export default function TypeCheckBoxGroup({
  onCheckboxChange,
  initialStateString,
}) {
  const { t } = useTranslation();
  //set order type
  const [isChecked0, setIsChecked0] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  function handleCheckbox0Change() {
    setIsChecked0(!isChecked0);
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(false);

    onCheckboxChange({
      checkbox0: !isChecked0,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
    });
  }

  function handleCheckbox1Change() {
    setIsChecked0(false);
    setIsChecked1(!isChecked1);
    setIsChecked2(false);
    setIsChecked3(false);

    onCheckboxChange({
      checkbox0: false,
      checkbox1: !isChecked1,
      checkbox2: false,
      checkbox3: false,
    });
  }

  function handleCheckbox2Change() {
    setIsChecked0(false);
    setIsChecked1(false);
    setIsChecked2(!isChecked2);
    setIsChecked3(false);

    onCheckboxChange({
      checkbox0: false,
      checkbox1: false,
      checkbox2: !isChecked2,
      checkbox3: false,
    });
  }
  function handleCheckbox3Change() {
    setIsChecked0(false);
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(!isChecked3);

    onCheckboxChange({
      checkbox0: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: !isChecked3,
    });
  }

  useEffect(() => {
    setIsChecked0(initialStateString === 'ultraPremium');
    setIsChecked1(initialStateString === 'standard');
    setIsChecked2(initialStateString === 'premium');
    setIsChecked3(initialStateString === 'redType');
  }, [isChecked1, isChecked2, isChecked3, isChecked0]);

  return (
    <div>
      <div className="col-2 col-md-9 d-flex justify-content-between justify-content-md-between align-items-center flex-column flex-md-row">
        <div className="col-12 col-md-2 d-flex align-items-center">
          <div className="label-title me-3 label-choice"> {t('Standard')}</div>
          <CheckBox
            name="Checkbox 1"
            tick={initialStateString === 'standard'}
            onCheck={handleCheckbox1Change}
          />
        </div>
        <div className="col-12 col-md-2 d-flex align-items-center">
          <div className="label-title me-3 label-choice"> {t('Premium')} </div>
          <CheckBox
            name="Checkbox 2"
            tick={initialStateString === 'premium'}
            onCheck={handleCheckbox2Change}
          />
        </div>
        <div className="col-12 col-md-2 d-flex align-items-center">
          <div className="label-title me-3 label-choice">
            {' '}
            {t('Ultra Premium')}{' '}
          </div>
          <CheckBox
            name="Checkbox 0"
            tick={initialStateString === 'ultraPremium'}
            onCheck={handleCheckbox0Change}
          />
        </div>
        <div className="col-12 col-md-2 d-flex align-items-center">
          <div className="label-title me-3 label-choice"> {t('Rouge')} </div>
          <CheckBox
            name="Checkbox 3"
            tick={initialStateString === 'redType'}
            onCheck={handleCheckbox3Change}
          />
        </div>
      </div>
    </div>
  );
}
