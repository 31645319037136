import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from 'config/axiosPrivate';

export const fetchClientList = createAsyncThunk(
  'consultantClientList/fetchClientList',
  async (arg, thunkAPI) => {
    try {
      const response = await axiosPrivate.get('/user/myAccounts');
      if (response.status === 200) {
        return response.data;
      }
      return thunkAPI.rejectWithValue(response.status);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
