import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { userActivation } from 'store/Admin/UserManagement';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import axiosPixLink from 'config/axiosPixLink';
import { useTranslation } from 'react-i18next';
import { PIXLINK_ROLES } from 'utils/Constants';

export const DisableUser = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  const [role, setRole] = useState('');

  useEffect(() => {
    switch (props.selected?.role) {
      case PIXLINK_ROLES.CLIENT:
        setRole(t('le client'));
        break;
      case PIXLINK_ROLES.CONSULTANT:
        setRole(t('le consultant'));
        break;
      case PIXLINK_ROLES.ADMIN:
        setRole(t("l'admin"));
        break;
      case PIXLINK_ROLES.EDITOR:
        setRole(t("l'éditeur"));
        break;
      case PIXLINK_ROLES.SUPER_ADMIN:
        setRole(t('le super admin'));
        break;
      case PIXLINK_ROLES.SUPER_CONSULTANT:
        setRole(t('le super consultant'));
        break;
      default:
        setRole(t(`L'utilisateur`));
        break;
    }

    setConfirmation(true);
  }, [props.selected, t]);

  useEffect(() => {
    if (props.selected.user.banned) {
      setConfirmationMsg(
        `${t('Voulez-vous vraiment activer')} ${t(role)} ${
          props.selected?.user.email
        } ?`
      );
    } else {
      setConfirmationMsg(
        `${t('Voulez-vous vraiment désactiver')} ${t(role)} ${
          props.selected?.user.email
        } ?`
      );
    }
  }, [role, props.selected, t]);

  const disableUser = () =>
    axiosPixLink
      .put('/user/activation', null, {
        params: { mail: props.selected.user.email },
      })
      .then(() => {
        setConfirmation(false);
        dispatch(
          userActivation({
            mail: props.selected.user.email,
            key: 'banned',
            value: props.selected.user.banned,
          })
        );
        setIsSuccess(true);
        if (props.selected.user.banned) {
          setErrorSuccessMsg(
            `${t(role)} ${props.selected.user.email} ${t(
              'a été activé avec succès'
            )} ?`
          );
        } else {
          setErrorSuccessMsg(
            `${t(role)} ${props.selected.user.email} ${t(
              'a été désactivé avec succès'
            )} ?`
          );
        }

        setErrorSuccess(true);
      })
      .catch((e) => {
        console.log('err', e);
        setConfirmation(false);
        setIsSuccess(false);
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
        setErrorSuccess(true);
      });

  return (
    <div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => props.Close()}
        executeAction={() => {
          disableUser();
        }}
      />
    </div>
  );
};
