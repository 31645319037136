import { createSlice } from '@reduxjs/toolkit';
import {
  fetchConsultantOrderDisplay,
  fetchConsultantTextOrderDisplay,
} from './features';

export const consultantOrderDisplay = createSlice({
  name: 'consultantOrderDisplay',
  initialState: {
    order: null,
    status: '',
    textList: [],
    remainingTextAmount: 0,
    minWords: null,
    maxWords: null,
    isLoading: true,
    isError: true,
    isTextLoading: false,
    isTextError: false,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    updateTextOfTextList: (state, { payload }) => {
      let array = [...state.textList];
      let index = array.findIndex((text) => text.id === payload.id);
      if (index > -1) {
        array[index][payload.key] = payload.value;
        state.textList = [...array];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultantOrderDisplay.fulfilled, (state, { payload }) => {
        state.order = payload;
        state.minWords = payload.minWords;
        state.maxWords = payload.maxWords;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchConsultantOrderDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchConsultantOrderDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(
        fetchConsultantTextOrderDisplay.fulfilled,
        (state, { payload }) => {
          state.textList = payload.map((text, index) => ({
            index,
            ...text,
          }));
          state.isTextLoading = false;
          state.isTextError = false;
        }
      )
      .addCase(fetchConsultantTextOrderDisplay.pending, (state) => {
        state.isTextLoading = true;
        state.isTextError = false;
      })
      .addCase(fetchConsultantTextOrderDisplay.rejected, (state) => {
        state.isTextLoading = false;
        state.isTextError = true;
      });
  },
});

export default consultantOrderDisplay.reducer;

export const { setStatus, updateTextOfTextList } =
  consultantOrderDisplay.actions;

export const consultantOrderDisplaySelector = (state) =>
  state.consultantOrderDisplay;
