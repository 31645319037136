import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminWritersList } from './features';

export const adminWritersList = createSlice({
  name: 'adminWritersList',
  initialState: {
    writersList: [],
    writersListIsLoading: true,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminWritersList.fulfilled, (state, { payload }) => {
        state.writersList = payload;
        state.writersListIsLoading = false;
        state.isError = false;
      })
      .addCase(fetchAdminWritersList.pending, (state, { payload }) => {
        state.writersListIsLoading = true;
        state.isError = false;
      })
      .addCase(fetchAdminWritersList.rejected, (state, { payload }) => {
        state.writersListIsLoading = false;
        state.isError = true;
      });
  },
});

export default adminWritersList.reducer;

export const adminWritersListSelector = (state) => state.adminWritersList;
