import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const PopoverSpot = ({
  content,
  children,
  isSelected,
  onCheckboxToggle,
  onClick,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the popover
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    // Attach the event listener when the popover is visible
    if (isVisible) {
      document.addEventListener('click', handleClickOutside);
    }

    // Detach the event listener when the popover is hidden
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isVisible]);

  const handleClick = () => {
    setIsVisible((prev) => !prev);
    onClick && onClick(); // Call onClick function if provided
  };

  const handleCheckboxChange = (index) => {
    // Call the callback function to update the state in the parent component
    onCheckboxToggle(index);
  };

  return (
    <div style={{ position: 'relative' }} ref={popoverRef}>
      <div onClick={handleClick}>{children}</div>
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            top: '-400%',
            left: '50%',
            width: '555px',
            transform: 'translateX(-80%)',
            backgroundColor: '#fff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            padding: '8px',
            borderRadius: '15px',
            zIndex: 999,
          }}
        >
          <ul
            style={{
              listStyleType: 'none',
              padding: 0,
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '8px',
            }}
          >
            {content.map((item, index) => (
              <li key={index}>
                <span className="box-checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={item.name}
                    name={item.name}
                    checked={item.visible}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </span>
                <label
                  style={{ marginTop: '6px', marginLeft: '5px' }}
                  htmlFor={item.label}
                >
                  {t(item.label)}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PopoverSpot;
