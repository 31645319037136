import { createSlice } from '@reduxjs/toolkit';
import { fetchProjectList } from './features';

export const writerProjectList = createSlice({
  name: 'writerProjectList',
  initialState: {
    projects: null,
    isLoadingProjects: true,
    isError: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectList.fulfilled, (state, { payload }) => {
        state.projects = payload;
        state.isLoadingProjects = false;
        state.isError = false;
      })
      .addCase(fetchProjectList.pending, (state) => {
        state.isLoadingProjects = true;
        state.isError = false;
      })
      .addCase(fetchProjectList.rejected, (state) => {
        state.isLoadingProjects = false;
        state.isError = true;
      });
  },
});

export default writerProjectList.reducer;

export const writerProjectListSelector = (state) => state.writerProjectList;
