import { createSlice } from '@reduxjs/toolkit';
import { fetchWebsiteDisplay } from './features';

export const clientWebsiteDetails = createSlice({
  name: 'clientWebsiteDetails',
  initialState: {
    webSitesList: null,
    isLoading: true,
    isError: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteDisplay.fulfilled, (state, { payload }) => {
        state.webSitesList = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchWebsiteDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchWebsiteDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default clientWebsiteDetails.reducer;

export const { setUrlSiteForDisplay } = clientWebsiteDetails.actions;

export const clientWebsiteDetailsSelector = (state) =>
  state.clientWebsiteDetails;
