import { Box, LinearProgress } from '@mui/material';

export const Loader = () => {
  return (
    <Box
      sx={{
        '& .MuiLinearProgress-colorPrimary': {
          backgroundColor: '#ffe426',
        },
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#3c3c3c',
        },
      }}
    >
      <LinearProgress />
    </Box>
  );
};
