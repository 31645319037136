export const uploadNetlinkingExcelHeader = {
  domain: 'Domaine',
  cleanDomain: 'Domaine Propre',
  language: 'Langue',
  themesList: 'Thématique',
  topicsList: 'Topic Majestic',
  kwPositioned: 'Kw positionés	',
  trafic: 'Trafic ',
  tF: 'TF',
  cF: 'CF',
  tarifHr: 'Tarif HR',
  tarifRc: 'Tarif RC',
  conditions: 'Conditions',
  contact: 'Contact',
};
