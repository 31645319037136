import { createSlice } from '@reduxjs/toolkit';
import {
  fetchClientOrderDisplay,
  fetchClientTextOrderDisplay,
} from './features';

export const clientOrderDisplay = createSlice({
  name: 'clientOrderDisplay',
  initialState: {
    order: null,
    status: '',
    textList: [],
    remainingTextAmount: 0,
    minWords: null,
    maxWords: null,
    isLoading: true,
    isError: true,
    isTextLoading: false,
    isTextError: false,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    updateTextOfTextList: (state, { payload }) => {
      let array = [...state.textList];
      let index = array.findIndex((text) => text.id === payload.id);
      if (index > -1) {
        array[index][payload.key] = payload.value;
        state.textList = [...array];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientOrderDisplay.fulfilled, (state, { payload }) => {
        state.order = payload;
        state.minWords = payload.minWords;
        state.maxWords = payload.maxWords;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchClientOrderDisplay.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchClientOrderDisplay.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchClientTextOrderDisplay.fulfilled, (state, { payload }) => {
        state.textList = payload.map((text, index) => ({
          index,
          ...text,
        }));
        state.isTextLoading = false;
        state.isTextError = false;
      })
      .addCase(fetchClientTextOrderDisplay.pending, (state) => {
        state.isTextLoading = true;
        state.isTextError = false;
      })
      .addCase(fetchClientTextOrderDisplay.rejected, (state) => {
        state.isTextLoading = false;
        state.isTextError = true;
      });
  },
});

export default clientOrderDisplay.reducer;

export const { setStatus, updateTextOfTextList } = clientOrderDisplay.actions;

export const clientOrderDisplaySelector = (state) => state.clientOrderDisplay;
