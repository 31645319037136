export default function DisabledSmallCalendarIcon() {
  return (
    <div>
      <img
        src="Images/icon-calendar.png"
        alt="icon-calendar"
        style={{ backgroundColor: 'white' }}
      />
    </div>
  );
}
