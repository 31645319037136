import { useState, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  createStyles,
  InputBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import axiosPixLink from 'config/axiosPixLink';
import { customStyle } from './CustomAutocompleteStyle';
const useStyles = makeStyles(() => createStyles(customStyle));
export const ThemeSearchField = ({ onThemeChange, highlightedOptions }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [themesList, setThemesList] = useState([]);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    axiosPixLink.get('/netlinking/all-thematics').then((res) => {
      setThemesList(res.data.map((theme) => theme.theme));
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue([]);
    onThemeChange(newValue);
  };
  return (
    <Autocomplete
      value={value}
      limitTags={0}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          height: '40px',
        },
      }}
      disabled={themesList.length > 0 ? false : true}
      onChange={(event, newValue) => {
        handleChange(event, newValue);
        // Reset TextField value when an option is selected
        event.target.value = '';
      }}
      options={themesList}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          sx={{ height: '40px' }}
        />
      )}
      renderOption={(props, option) => {
        const isHighlighted = highlightedOptions.includes(option);
        return (
          <li
            {...props}
            style={{
              backgroundColor: isHighlighted ? '#ffe426' : 'white',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {' '}
            <Box>{option}</Box>
            {isHighlighted && (
              <img src="Images/icon-check.png" alt="icon-check" />
            )}
          </li>
        );
      }}
    />
  );
};
