const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.accessTokenJwt;
};

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user');
  localStorage.clear();
};

const TokenService = {
  getLocalAccessToken,
  setUser,
  removeUser,
};
export default TokenService;
