import { PIXLINK_STATUS } from 'utils/Constants';

export const calculateConsultantValidateSuperAdmin = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN ||
        item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};
export const calculateConsultantValidateSuperConsultant = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT ||
        item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};

export const calculateConsultantValidateClient = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
        item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};

export const calculateConsultantToBeFilteredConsultant = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ||
        item.spotTmpStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};

export const calculateConsultantToBeCommanded = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED ||
        item.spotTmpStatus === PIXLINK_STATUS.TO_BE_COMMANDED
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};

export const calculateConsultantRefusedClient = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
        item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};

export const calculateConsultantRefusedPixalione = (netlinkings) => {
  return netlinkings
    .filter(
      (item) =>
        item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE ||
        item.spotTmpStatus === PIXLINK_STATUS.REFUSED_PIXALIONE
    )
    .filter((item) => item.spotTmpStatus !== 'NOT_FILTERED_CONSULTANT').length;
};
