import React, { useRef, useEffect, useState } from 'react';

const LinkIconWithInput = (props) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [link, setLink] = useState(props.value);

  const [idCell, setIdCell] = useState(props.id + '-' + props.column);

  const ref = useRef(null);
  const toggleInput = () => {
    setInputVisible(!inputVisible);
  };

  useEffect(() => {
    if (!inputVisible && props.value != link) {
      props.updateParentString(props.id, props.column, link);
    }
  }, [inputVisible]);
  const handleInputChange = (event) => {
    setLink(event.target.value);
  };

  const openLinkInNewTab = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.id !== ref.current.id) {
        setInputVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);

  return (
    <div>
      {inputVisible && (
        <div
          style={{
            position: 'absolute',
            transform: 'translateY(-110%) translateX(-80%)',
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            padding: '8px',
            borderRadius: '15px',
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
          }}
          id={idCell}
        >
          {/* Input field with anchor tag */}
          <input
            className="form-control ps-0 length-link"
            type="text"
            placeholder="Veuillez entrer le lien"
            value={link}
            onChange={handleInputChange}
            id={idCell}
          />
          <span style={{ marginLeft: '5px' }}>
            <img
              id={idCell}
              style={{ width: '20px', cursor: 'pointer' }}
              src="Images/icon-navigate.png"
              alt="icon-navigate"
              onClick={openLinkInNewTab}
            />
          </span>
        </div>
      )}
      <span onClick={toggleInput} style={{ cursor: 'pointer' }}>
        {link && link.length >0  ? ( <img
          ref={ref}
          id={idCell}
          style={{ width: '20px', cursor: 'pointer' }}
          src="Images/icon-lien-green.png"
          alt="icon-lien"
        />) :  (<img
        ref={ref}
        id={idCell}
        style={{ width: '20px', cursor: 'pointer' }}
        src="Images/icon-lien.png"
        alt="icon-lien"
      />)}
       
      </span>
    </div>
  );
};

export default LinkIconWithInput;
