import TokenService from 'config/token.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateFlag } from 'store/UserSession';
import { fetchUser } from 'store/UserSession/features';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import ForgotPasswordModal from 'components/shared/modal/ForgotPasswordModal';
import axiosPixLink from 'config/axiosPixLink';
import { PIXLINK_ROLES } from 'utils/Constants';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(null);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    axiosPixLink
      .post('/api/auth/signin', { email: email, password: password })
      .then((response) => {
        TokenService.setUser(response.data);
        setLoading(false);
        dispatch(fetchUser(response.data));
        dispatch(updateFlag(true));
        if (response.data.user.role === PIXLINK_ROLES.SUPER_ADMIN) {
          history.push('/SuperAdmin');
        } else if (response.data.user.role === PIXLINK_ROLES.ADMIN) {
          history.push('/Admin');
        } else if (response.data.user.role === PIXLINK_ROLES.SUPER_CONSULTANT) {
          history.push('/SuperConsultant');
        } else if (response.data.user.role === PIXLINK_ROLES.CONSULTANT) {
          history.push('/Consultant');
        } else if (response.data.user.role === PIXLINK_ROLES.EDITOR) {
          history.push('/Editor');
        } else if (response.data.user.role === PIXLINK_ROLES.CLIENT) {
          history.push('/Client');
        }
      })
      .catch((e) => {
        switch (e.response.status) {
          case 400:
            setShowError(t('Veuillez saisir des champs valides'));
            break;
          case 404:
            setShowError(`${t("L'émail")} ${email} ${t("n'existe pas")}`);

            break;
          case 401:
            setShowError(t('Mot de passe incorrect'));
            break;
          default:
            setShowError(t("Une erreur s'est produite, veuillez réessayer"));
            break;
        }
        setLoading(false);
      });
  };
  return (
    <div
      style={{
        display: 'flex',
        width: window.innerWidth,
        height: window.innerHeight,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '30%',
          height: '70%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(240, 245, 245,0.6)',
          flexDirection: 'row',
          borderRadius: 25,
          borderColor: 'rgba(195, 213, 213,0.9)',
          borderWidth: 1,
        }}
      >
        <Box
          component={'form'}
          gap={3}
          padding={2}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <img
            src={'/Images/PixlinkBanner.png'}
            height={'100%'}
            width={'100%'}
            style={{ marginBottom: 30 }}
            alt="typix logo"
          />

          <TextField
            type="text"
            variant="standard"
            label={t('Email')}
            inputProps={{
              style: {
                borderWidth: '1px',
                borderColor: 'yellow !important',
                backgroundColor: 'red !important',
              },
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            label={t('Mot de passe')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setLoading(true);
                handleSubmit();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          {showError ? (
            <Alert severity="error">
              <AlertTitle>{t('Erreur')}</AlertTitle>
              {showError}
            </Alert>
          ) : null}
          <div
            style={{
              color: '#333',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => setForgotPassword(true)}
          >
            {t('mot de passe oublié')} ?
          </div>

          <Box
            display="flex"
            justifyContent="end"
            style={{ margin: 2, position: 'relative' }}
          >
            <Button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                handleSubmit();
              }}
              sx={{
                backgroundColor: 'rgb(0, 102, 102)',
                color: '#ffffff',
                width: '40%',
                marginY: 1.5,
              }}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
              ) : (
                'Login'
              )}
            </Button>
          </Box>
        </Box>
      </div>

      <ForgotPasswordModal
        open={forgotPassword}
        handleClose={() => setForgotPassword(false)}
      />
    </div>
  );
}

export default Login;
